/*eslint-disable */
import axios from 'axios';
import React from 'react';
import {authFail} from "../../redux/actions/auth";
import * as pbiEnv from '../../containers/Dashboard/pbi_env';


const ReportService = {

    // GET METHODS
    reportPages: async function () {
        return await axios.get('/api/report_pages/')
    },

    allPages: async function () {
        return await axios.get('/api/report_pages/allpages')
    },

    userPages: async function (id) {
        return await axios.get('/api/pages/userPages/'+ id)
    }
};

export default ReportService;