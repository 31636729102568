/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Table } from 'reactstrap';
import { Col, Table, Tag, DatePicker } from 'antd';
const { Column } = Table
const {RangePicker} = DatePicker;
import { EmailsProps } from '../../../shared/prop-types/EmailProps';
import moment from 'moment';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import NotificationsService from '../../../services/notifications/NotificationsService';
import SearchInput, {createFilter} from 'react-search-input'
import {spinnerService} from "@chevtek/react-spinners";
import InfoLicitacion from './InfoLicitacion';
import InfoCompraAgil from './InfoCompraAgil';
import InfoOrdenCompra from './InfoOrdenCompra';
import InfoAdjudicacion from './InfoAdjudicacion';


function UnsafeComponent({ html }) {
  return <span className="inbox__email-table-preview" dangerouslySetInnerHTML={{ __html: html }} />;
}

const labels = [
  { color: 'green', title: 'Adjudicación', type: 3 },
  { color: 'blue', title: 'Licitación', type: 1 },
  { color: 'yellow', title: 'Orden de Compra', type: 2 },
  { color: '#00c1cf', title: 'Compra Ágil', type: 4 },
];
const KEYS_TO_FILTERS = ['notification__msg']


export default class InboxTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      searchTerm: '',
      fechas: [],
      codeLic: '',
      codeCA: '',
      codeOC: '',
      codeAdj: '',
      licDetail: [],
      CADetail: [],
      OCDetail: [],
      adjDetail: [],
      numsNotifs: this.props.numNotif
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  // static propTypes = {
  //   emails: EmailsProps.isRequired,
  // };

  handleInfoLicModal = (value) => {
    this.setState({
      infoModalLicVisible: value,
    })
  };

  handleInfoCAModal = (value) => {
    this.setState({
      infoModalCAVisible: value,
    })
  };

  handleInfoOCModal = (value) => {
    this.setState({
      infoModalOCVisible: value,
    })
  };

  handleInfoAdjModal = (value) => {
    this.setState({
      infoModalAdjVisible: value,
    })
  };

  componentDidMount(){
    NotificationsService.notificationService()
      .then(response => {
        if (this.state.fechas.length > 0) {
          NotificationsService.notifBetween(this.state.fechas.toString())
            .then(respone => {
              spinnerService.hide('mySpinner');
              this.state.numsNotifs();
              this.setState({
                emails: respone.data
              });
            })
            .catch(error => {
              spinnerService.hide('mySpinner');
              this.setState({
                emails: [],
                fechas: []
              });
              console.error(error);
            });
        } else {
          this.state.numsNotifs();
          this.setState({
            emails: response.data
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          emails: []
        });
      });
  }

  filterNotifications = (type) => {
    if (type === 1) {
      return <span>
        <Tag color='blue'>Licitación</Tag>
      </span>
    }
    else if (type === 2) {
      return <span>
        <Tag color={'yellow'} style={{ color: 'gray' }}>
          Orden de Compra
        </Tag>
      </span>
    }
    else if (type === 3) {
      return <span>
        <Tag color={'green'}>
          Adjudicación
        </Tag>
      </span>
    } else if (type === 4) {
      return <span>
        <Tag color={'#00c1cf'}>
          Compra Ágil
        </Tag>
      </span>
    }
  };

  handleListReload = () => {
    this.componentDidMount();
  };  

  onChange = (date, dateString) => {
    spinnerService.show('mySpinner');
    NotificationsService.notifBetween(dateString.toString())
      .then(respone => {
        spinnerService.hide('mySpinner');
        this.setState({
          emails: respone.data
        });
      })
      .catch(error => {
        spinnerService.hide('mySpinner');
        this.setState({
          emails: []
        });
        console.error(error);
      });

    this.setState({fechas: dateString});
  }

  onClickforView = (code, type, id) => {
    if (type == 1){
      this.handleInfoLicModal(true);
      let infoLic = code.split(">");
      let splitInfoLic = infoLic[1].split("<");
      this.setState({codeLic: splitInfoLic[0]});
      NotificationsService.detailLicitaciones(splitInfoLic[0])
        .then(response => {
          this.setState({licDetail: response.data});
          NotificationsService.notifViewed(id)
            .then(resp => {
              this.componentDidMount();
            })
            .catch(err => {
              this.componentDidMount();
              console.error(err);
            });
        })
        .catch(error => {
          console.log(error);
          this.setState({licDetail: []});
        });
      
      return infoLic;

    } else if (type == 2) {
      this.handleInfoOCModal(true);
      let infoOC = code.split(">");
      let splitInfoOC = infoOC[1].split("<");
      this.setState({codeOC: splitInfoOC[0]});
      NotificationsService.detailOrdenCompra(splitInfoOC[0])
        .then(response => {
          this.setState({OCDetail: response.data});
          NotificationsService.notifViewed(id)
            .then(resp => {
              this.componentDidMount();
            })
            .catch(err => {
              this.componentDidMount();
              console.error(err);
            });
        })
        .catch(error => {
          console.log(error);
          this.setState({OCDetail: []});
        });
      
      return infoOC;

    } else if (type == 3) {
      this.handleInfoAdjModal(true);
      let infoAdj = code.split(">");
      let splitInfoAdj = infoAdj[1].split("<");
      this.setState({codeAdj: splitInfoAdj[0]});
      NotificationsService.detailAdjudicacion(splitInfoAdj[0])
        .then(response => {
          this.setState({adjDetail: response.data});
          NotificationsService.notifViewed(id)
            .then(resp => {
              this.componentDidMount();
            })
            .catch(err => {
              this.componentDidMount();
              console.error(err);
            });
        })
        .catch(error => {
          console.log(error);
          this.setState({adjDetail: []});
        });
      
      return infoAdj;

    } else if (type == 4) {
      this.handleInfoCAModal(true);
      let infoCA = code.split(">");
      let splitInfoCA = infoCA[1].split("<");
      this.setState({codeCA: splitInfoCA[0]});
      NotificationsService.detailCompraAgil(splitInfoCA[0])
        .then(response => {
          this.setState({CADetail: response.data});
          NotificationsService.notifViewed(id)
            .then(resp => {
              this.componentDidMount();
            })
            .catch(err => {
              this.componentDidMount();
              console.error(err);
            });
        })
        .catch(error => {
          console.log(error);
          this.setState({CADetail: []});
        });
      
      return infoCA;
    }
  };

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} de ${total}`
  }


  render() {
    const { emails, searchTerm } = this.state;
    // const { emails } = this.props;
    const searchTypeNotif = labels.map(function (data, i) {
      return { text: data.title, value: data.type };
    });
    const todayRegs = moment().subtract(1, 'week').format('YYYY-MM-DD');
    const filteredEmails = emails.filter(createFilter(searchTerm, KEYS_TO_FILTERS));

    return (
      <div className="inbox__content">
        {/* <EmailsControls emails={emails} onChangePage={this.onChangePage} /> */}
        <div className="inbox__emails-controls-wrap">
          <div className="inbox__emails-controls">
            <RangePicker
              separator={"|"}
              style={{ textTransform: 'capitalize' }}
              placeholder={['Desde', "Hasta"]}
              format="DD-MM-YYYY"
              disabledDate={(current) => {
                return current && current < moment(todayRegs)
              }}
              onChange={this.onChange}
              size="small"
            />
          </div>
          <div className="inbox__emails-controls-right">
            <div className="inbox__emails-control-search">
              <SearchInput onChange={this.searchUpdated} placeholder="Buscar"/>
              <div className="inbox__emails-control-search-icon"><MagnifyIcon /></div>
            </div>
          </div>
        </div>
        {
          this.state.infoModalLicVisible ?
            <InfoLicitacion visible={true} reloadList={this.handleListReload} handleInfoLicModal={this.handleInfoLicModal} code={this.state.codeLic} detail={this.state.licDetail}/>
              :
            <InfoLicitacion visible={false} reloadList={this.handleListReload}  handleInfoLicModal={this.handleInfoLicModal} code={this.state.codeLic} detail={this.state.licDetail}/>
        }
        {
          this.state.infoModalCAVisible ?
            <InfoCompraAgil visible={true} reloadList={this.handleListReload} handleInfoCAModal={this.handleInfoCAModal} code={this.state.codeCA} detail={this.state.CADetail}/>
              :
            <InfoCompraAgil visible={false} reloadList={this.handleListReload} handleInfoCAModal={this.handleInfoCAModal} code={this.state.codeCA} detail={this.state.CADetail}/>
        }
        {
          this.state.infoModalOCVisible ?
            <InfoOrdenCompra visible={true} reloadList={this.handleListReload} handleInfoOCModal={this.handleInfoOCModal} code={this.state.codeOC} detail={this.state.OCDetail}/>
              :
            <InfoOrdenCompra visible={false} reloadList={this.handleListReload} handleInfoOCModal={this.handleInfoOCModal} code={this.state.codeOC} detail={this.state.OCDetail}/>
        }
        {
          this.state.infoModalAdjVisible ?
            <InfoAdjudicacion visible={true} reloadList={this.handleListReload} handleInfoAdjModal={this.handleInfoAdjModal} code={this.state.codeAdj} detail={this.state.adjDetail}/>
              :
            <InfoAdjudicacion visible={false} reloadList={this.handleListReload} handleInfoAdjModal={this.handleInfoAdjModal} code={this.state.codeAdj} detail={this.state.adjDetail}/>
        }
        <Table size="middle" dataSource={emails, filteredEmails} rowClassName={(record) => (record.viewed == true ? 'inbox__email-list-item' : 'inbox__email-list-item inbox__email-list-item--unread')}
          onRowClick={record => this.onClickforView(record.notification__msg, record.notification__type, record.id)} scroll={{y: window.innerHeight-200 }}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, showTotal: this.showTotal, pageSizeOptions: ['10', '30', '50', '100']}}>
            <Column className="inbox__email-table-name" onFilter={(value, record) => record.notification__type === value} filters={searchTypeNotif} title="Etiqueta" dataIndex="notification__type" key="notification__type"
              render={(text, record) => (
                <span>
                  {this.filterNotifications(record.notification__type)}
                </span>
              )} width="10%" />
            <Column dataIndex="notification__msg" width="50%" className="inbox__email-table-name" render={(text, record) => (<UnsafeComponent onClick={record => this.onClickforView(record.notification__msg, record.notification__type, record.id)} html={record.notification__msg} />)} title="Mensaje" responsive="lg" />
            <Column dataIndex="notification__noti_date" className="inbox__email-table-name" render={(text, record) => (moment(record.notification__noti_date).format("DD-MM-YYYY"))} title="Recibido" width="10%" />
            <Column dataIndex="notification__close_date" className="inbox__email-table-name" render={(text, record) => (record.notification__close_date ? moment(record.notification__close_date).format("DD-MM-YYYY") : '')} title="Fecha de Cierre" width="10%" />
        </Table>
      </div>
    );
  }

  searchUpdated (term) {
    this.setState({searchTerm: term})
  }
}
