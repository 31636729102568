/*eslint-disable */
import axios from 'axios';

const OpportunityManagerService = {

    // GET METHODS
    opportunityDetail: async (code) => {
        return await axios.get('/api/oppManager/', {
            params:{
                code
            }
        });
    },

    ins_updOpportunity: function (data, code) {
        return axios.post('/api/oppManager/ins_upd?code='+code , {
            cod_oportunidad: data.code_oportunidad,
            vendedor_id: data.vendedor_id,
            anexos_admin: data.anexos_admin,
            anexos_tecn: data.anexos_tecn,
            anexos_econom: data.anexos_econom,
            garantia_oferta: data.garantia_oferta,
            visita_tecnica: data.visita_tecnica,
            plazo_entrega: data.plazo_entrega,
            estado_situacion: data.estado_situacion,
            valor_calificacion: data.valor_calificacion
        });
    },
    anexos_admin: function (data, code) {
        return axios.put('/api/oppManager/anx_admin?code='+code, {
            situacion_admin: data.situacion_admin,
            nota_admin: data.nota_admin
        });
    },
    anexos_econom: function (data, code) {
        return axios.put('/api/oppManager/anx_econom?code='+code, {
            situacion_econom: data.situacion_econom,
            nota_econom: data.nota_econom
        });
    },
    anexos_tecn: function (data, code) {
        return axios.put('/api/oppManager/anx_tecn?code='+code, {
            situacion_tecn: data.situacion_tecn,
            nota_tecn: data.nota_tecn
        });
    },
    visit_tecn: function (data, code) {
        return axios.put('/api/oppManager/visit_tecn?code='+code, {
            nota_visita: data.nota_visita
        });
    },

    getOpportunity: async (cod_op) => {
        return axios.get('/api/oppManager/val', {
            params: {
                cod_op,
            }
        });
    },

    insertOpportunity: async (cod_op, sit_op, rate, seller, off_g, del_time, r_by, r_date, a_by, a_date) => {
        return axios.get('/api/oppManager/op_insert', {
            params: {
                cod_op: cod_op,
                sit_op: sit_op,
                rate: rate,
                seller: seller,
                off_g: off_g,
                del_time: del_time,
                r_by: r_by,
                r_date: r_date,
                a_by: a_by,
                a_date: a_date,
            }
        });
    },

    updateOpportunity: async (cod_op, sit_op, rate, seller, off_g, del_time, r_by, r_date, a_by, a_date, m_valid) => {
        return axios.get('/api/oppManager/op_update', {
            params: {
                cod_op: cod_op,
                sit_op: sit_op,
                rate: rate,
                seller: seller,
                off_g: off_g,
                del_time: del_time,
                r_by: r_by,
                r_date: r_date,
                a_by: a_by,
                a_date: a_date,
                m_valid: m_valid,
            }
        });
    }
};

export default OpportunityManagerService;