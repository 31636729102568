/*eslint-disable */
import axios from 'axios';
import React from 'react';

const ProspectsService = {
    getProspects: function(){
        return axios.get('/api/prospects/')
    },

    allProspects: function(){
        return axios.get('/api/prospects/allprospects/')
    },

    getProspectHist: function(){
        return axios.get('/api/prospects/prospectsHistory/')
    },

    deleteProspect: function (id) {
        return axios.delete('/api/prospects/'+id)
    },

    deleteProspectHist: function (id) {
        return axios.delete('/api/prospects/prospectsHistory/'+id)
    },

    addProspect: function (data) {
        return axios.post('/api/prospects/', {
            name: data.name,
            lastname: data.lastname,
            business_name: data.business_name,
            ruc: data.ruc,
            address: data.address,
            phone: data.phone,
            mobile: data.mobile,
            email: data.email,
            service_type: data.service_type,
            master_licenses: data.master_licenses,
            standard_licenses: data.standard_licenses,
            payment_freq: data.payment_freq,
            description: data.description,
            users: data.users
        });
    },

    addProspectHist: function (data) {
        return axios.post('/api/prospects/prospectsHistory/', {
            status: data.status,
            description: data.description,
            users: data.users,
            prospect: data.prospect
        });
    },

    editProspect: function (data, id) {
        return axios.put('/api/prospects/'+id+'/' , {
            name: data.name,
            lastname: data.lastname,
            business_name: data.business_name,
            ruc: data.ruc,
            address: data.address,
            phone: data.phone,
            mobile: data.mobile,
            email: data.email,
            service_type: data.service_type,
            master_licenses: data.master_licenses,
            standard_licenses: data.standard_licenses,
            payment_freq: data.payment_freq,
            description: data.description,
            users: data.users
        });
    },

    editProspectHist: function (data, id) {
        return axios.put('/api/prospects/prospectsHistory/'+id+'/' , {
            status: data.status,
            description: data.description,
            users: data.users,
            prospect: data.prospect
        });
    },

    formalizeProspect: function(data, id) {
        return axios.post('/api/prospects/prospectsFormalize/'+data+'/')
    }
};

export default ProspectsService;