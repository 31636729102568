/*eslint-disable */
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import { Button, Divider, Form, Input, Modal } from "antd";
import { Icon, Select } from 'antd';
const { TextArea } = Input;
import UserContext from '../../../store/userContext';


class AddClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      handleAddModal: this.props.handleAddModal,
      reloadList: this.props.reloadList,
      load: false,
      serviceTypes: [
        {
          value: 'N',
          type: 'Ninguno'
        },
        {
          value: 'L',
          type: 'Licencias'
        },
        {
          value: 'R',
          type: 'Reportería Mensual'
        },
      ],
      payFreqTypes: [
        {
          value: 'NN',
          type: 'Ninguno'
        },
        {
          value: 'MY',
          type: 'Mensual 0%'
        },
        {
          value: 'QY',
          type: 'Trimestral 10%'
        },
        {
          value: 'BA',
          type: 'Semestral 20%'
        },
        {
          value: 'AN',
          type: 'Anual 30%'
        }
      ]
    };
  }

  handleSelectServiceType = () => {
    let renderOptions;

    renderOptions = this.state.serviceTypes.map(function (data, idx) {
      return <Option value={data.value}>{data.type}</Option>;
    });
    return renderOptions
  };

  handleSelectFrequencyPayType = () => {
    let renderOptions;

    renderOptions = this.state.payFreqTypes.map((data, idy) => {
      return <Option value={data.value}>{data.type}</Option>;
    });

    return renderOptions;
  };

  handleChangeType = e => {
    this.setState({ selectValue: e });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          load: true
        });
        CustomerService.addCustomer(values)
          .then(response => {
            if (response.status === 201) {
              notificationWithIcon('success', 'Creación de Cliente', 'Se creó el cliente correctamente');
              this.props.form.resetFields();
              this.state.handleAddModal(false);
              this.state.reloadList();
            }
          })
          .catch(error => {
            // if (error.response.status === 400) {
            //   this.setState({
            //     load: false,
            //   });
            //   notificationWithIcon('error', 'Error', 'Este cliente ya existe, verifique el RUT ingresado');
            // } else {
              this.setState({
                load: false,
              });
              notificationWithIcon('error', 'Error', 'Hubo un error al crear el cliente, revise la informacion');
            // }
          })
      }
    });
    setTimeout(() => this.setState({ load: false }), 2000);
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { load } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <UserContext.Consumer>
        {(user) => (
          <Modal
            footer={null}
            width='650px'
            title={<div><Icon type="user-add" style={{ fontSize: '35px', marginRight: '20px', color: '#3879b1' }} />Agregar Cliente</div>}
            visible={this.props.visible}
            onOk={() => this.handleOk(this.props.user)}
            onCancel={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}
            okText="Guardar"
            cancelText="Cancelar"
          >
            <Form {...formItemLayout} layout="inline" onSubmit={this.handleSubmit}>
              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Nombre">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, ingrese el nombre',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Apellido">
                {getFieldDecorator('lastname', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, ingrese el apellido',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} style={{ width: '280px' }} label="Empresa">
                {getFieldDecorator('customer', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, ingrese la empresa',
                    },
                  ],
                })(<Input style={{ width: '185px' }} />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} style={{ width: ' 275px' }} label="RUT">
                {getFieldDecorator('ruc', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, ingrese el RUT',
                    },
                  ],
                })(<Input style={{ width: '185px' }} />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 4 } }} wrapperCol={{ lg: { span: 20 } }} label="Correo" style={{ width: '573px' }}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Ingrese un correo valido',
                    },
                    {
                      required: true,
                      message: 'Por favor, ingrese un correo',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Teléfono">
                {getFieldDecorator('phone', {
                  rules: [{
                    required: true,
                    message: 'Por favor, ingrese un teléfono'
                  }],
                })(<Input type='number' pattern="[0-9].{8,}" style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Móvil" style={{ width: ' 275px' }}>
                {getFieldDecorator('mobile', {
                })(<Input type='number' pattern="[0-9].{15,}" style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 4 } }} wrapperCol={{ lg: { span: 20 } }} label="Dirección" style={{ width: '573px' }}>
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, ingrese una Dirección',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Tipo de Servicio" style={{ width: '445px' }}>
                <div className="form__form-group-field">
                  {getFieldDecorator('service_type', {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, seleccione un tipo de servicio',
                      }],
                  })(<Select onChange={this.handleChangeType}
                    placeholder={"Seleccione el tipo de servicio"}>
                    {this.handleSelectServiceType()}
                  </Select>
                  )}
                </div>
              </Form.Item>

              <Form.Item labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 16 } }} label="Frecuencia de Pago" style={{ width: '445px' }}>
                <div className="form__form-group-field">
                  {getFieldDecorator('payment_freq', {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, seleccione una frecuencia de pago',
                      }],
                  })(<Select onChange={this.handleChangeType}
                    placeholder={"Seleccione la frecuencia de pago"}>
                    {this.handleSelectFrequencyPayType()}
                  </Select>
                  )}
                </div>
              </Form.Item>

              <Form.Item label="Licencias Master" labelCol={{ lg: { span: 11 } }} wrapperCol={{ lg: { span: 13 } }}>
                {getFieldDecorator('master_licenses', {
                })(<Input type='number' style={{ width: '60%' }} />)}
              </Form.Item>

              <Form.Item label="Licencias Estándar" labelCol={{ lg: { span: 13 } }} wrapperCol={{ lg: { span: 11 } }} style={{ width: '245px' }}>
                {getFieldDecorator('standard_licenses', {
                })(<Input type='number' style={{ width: '91%' }} />)}
              </Form.Item>

              {getFieldDecorator('allies', {
                initialValue: user.id
              })(<Input hidden={true} value={user.id} />)}

              <Form.Item wrapperCol={{ lg: { span: 24 } }} style={{ marginLeft: '32%', paddingTop: '15px' }}>
                <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}>
                  Cancelar
                </Button>
                <Divider type="vertical" />
                <Button icon={"true"} loading={load} htmlType="submit"
                  style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                  Agregar
                </Button>
              </Form.Item>

            </Form>

          </Modal>
        )}
      </UserContext.Consumer>
    )
  }
}

const WrappedRegistrationForm = Form.create()(AddClient);

export default WrappedRegistrationForm;
