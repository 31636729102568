/*eslint-disable */
import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import * as actions from '../../../redux/actions/auth';
import { Form, Icon, Input, Button, Card, Mention } from 'antd';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
//F
import LoadingIcon from "mdi-react/LoadingIcon";
import classNames from 'classnames';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LogInForm extends PureComponent {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      load:false,
      showPassword: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //F
        this.setState({
          load: true,
        });
        this.props.onAuth(values.userName, values.password);
      }
    });
    //F
    setTimeout(() => this.setState({ load: false }), 2000);
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState( prevState => ({ showPassword: !prevState.showPassword }));
  };


  render() {
    const { showPassword, load} = this.state;

    const expandClass = classNames({
        icon: true,
        expand: true,
        'expand--load': load,
    });

    if(this.props.isAuthenticated){

    }
    const {
        getFieldDecorator, getFieldError, isFieldTouched,
    } = this.props.form;

    const userNameError = isFieldTouched('userName') && getFieldError('userName');
    const passwordError = isFieldTouched('password') && getFieldError('password');

    const passTrim = (e) => {
      let str= e.target.value;
      e.target.value = str.replace(/ /g, "");
    }


    return (

      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Correo</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>

              <div validateStatus={userNameError ? 'error' : ''} help={userNameError || ''} style={{width:'100%'}} >
                  {getFieldDecorator('userName', {
                                rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <input name="userName" type="email" placeholder="ejemplo@dominio.com" required />
                  )}
              </div>

          </div>
        </div>
        <div className="form__form-group" style={{marginBottom: '40px'}}>
          <span className="form__form-group-label">Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
              <div validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} style={{width:'100%'}}>
                {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                              })(
                    <input
                      onChange={passTrim}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Contraseña"
                      required
                    />
                )}
              </div>
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/password/reset">¿Olvidó su contraseña?</a>
          </div>
        </div>
        <button
            className={"btn btn-primary account__btn account__btn--small " + expandClass}
            type="primary"
            htmltype="submit"
          ><LoadingIcon />
            Iniciar Sesión
        </button>
        <Link
            className={"btn btn-outline-primary account__btn account__btn--small"}
            type="primary"
            to="/register"
          >
            Registrarse
        </Link>
        <Link style={{fontSize: '13px', lineHeight: '0',  marginLeft: '90px'}} to={{ pathname: "https://mercadobi.cl" }} target="_blank">Conoce más de MercadoBI aquí</Link>
      </form>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(LogInForm);
const mapStateToProps = (state) =>{
    return {
        loading: state.loading,
        error: state.error
    }
};

const mapDispatchToProps = dispatch =>{
  return{
      onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm));
