/*eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';
import logo from "../../shared/img/logo/logoShort.png";
import {withRouter} from "react-router-dom";

const Register = () => (
  <div className="account">
    <img src={logo} alt="Mercado BI" style={{ width: '12%', margin: '35px', position: 'absolute', right: '3%' }} />
    <div className="account__wrapper">
      <div className="account__card-register">
        <h4 style={{ textAlign: 'center', marginBottom: '30px' }}>Registrarse</h4>
        <RegisterForm onSubmit />
        <Link
            className={"btn btn-outline-primary account__btn account__btn--small"}
            type="primary"
            to="/"
          >
            Iniciar Sesión
        </Link>

        <Link style={{fontSize: '13px', lineHeight: '0',  marginLeft: '180px'}} to={{ pathname: "https://mercadobi.cl" }} target="_blank">Conoce más de MercadoBI aquí</Link>
      </div>
    </div>
  </div>
);



export default withRouter(Register);
