/*eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/auth';


const myContext = React.createContext();

// class MyProvider extends Component {
//     state = {
//     }
//     render() {
//         return(
//             <MyProvider.Provider value="value">
//                 {this.props.children}
//             </MyProvider.Provider>
//         )
//     }
// }

class Layout extends Component {

  componentDidMount(){
        this.props.onTryAutoSignup();
    }

  state = {
    sidebarShow: false,
    sidebarCollapse: true,
  };

  changeSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarCollapse: !prevState.sidebarCollapse }));
  };

  changeMobileSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarShow: !prevState.sidebarShow }));
  };

  render() {
    const { sidebarShow, sidebarCollapse } = this.state;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebarCollapse,
    });


    return (

      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          {...this.props}
        />
        <Sidebar
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          user = {this.props.user}
        />

      </div>
    );
  }
}

const mapStateToProps = state => {
    return{
        isAuthenticated: state.token !== null,
        user: state.user,
    }
};

const mapDispatchToProps = dispatch => {
    return{
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(Layout);
