/*eslint-disable*/
import React from 'react';
import { Container } from 'reactstrap';
import LastDatesService from "../../services/dates/LastDatesService";
import moment from 'moment';
import 'moment/locale/es';
import logo from "../../shared/img/logo/logo_gbitcorp_ar.png";


class Footer extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            selDate: []
        };
  }

  componentDidMount() {
    LastDatesService.getLastDate()
        .then(response => {
          this.setState({
              selDate: response.data,
          });
    });
  }


  render () {
    // const date = moment().subtract(1, "days").format('LL');
    const date = moment(this.state.selDate.fecha).format('LL');

    // const background = `${process.env.PUBLIC_URL}/img/landing/bottom_bg.png`;

    return (
      <footer className="landing__footer">
        {/* <img className="landing__footer-background" src={background} alt="" /> */}
        <Container>
          <div>
            <hr/>
              <div className="row">
                <div className="landing__footer-text text-left col-lg-3 col-md-3">Data actualizada hasta <b>{date}</b></div>
                <div className="landing__footer-text text-right col-lg-4 col-md-4">
                  Powered by&nbsp;
                  <a href="https://www.gbitcorp.com/" target="_blank">
                      <img src={logo} alt="logo" style={{ width: '125px', height:'30px' }} />
                  </a>
                </div>
              </div>
          </div>
        </Container>
      </footer>
    )
  }
}

export default Footer;
