import React from 'react';
import { Container, Row } from 'reactstrap';
import ListProspects from './components/ListProspects';

const ProspectsPage = () => (
  <Container className="dashboard">
    <Row>
      <ListProspects />
    </Row>
  </Container>
);

export default ProspectsPage;
