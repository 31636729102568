/*eslint-disable */
import React, { PureComponent } from 'react';
// import { Field } from 'redux-form';
// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import {Form} from "antd";
import * as actions from "../../../redux/actions/auth";
import {connect} from "react-redux";
import LoadingIcon from "mdi-react/LoadingIcon";
import classNames from 'classnames';


class ResetPasswordForm extends PureComponent {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {load:false};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
         //F
        this.setState({
          load: true,
        });
        this.props.onReset(values.email);
      }
    });
    //F
    setTimeout(() => this.setState({ load: false }), 2000);
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const buttonStyle = {
      marginLeft: '2%',
      marginRight: '2%',
    };

    const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;

    const emailError = isFieldTouched('email') && getFieldError('email');
    const { load } = this.state;
    const expandClass = classNames({
        icon: true,
        expand: true,
        'expand--load': load,
    });

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Correo</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <div validateStatus={emailError ? 'error' : ''} help={emailError || ''} style={{width:'100%'}} >
                  {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <input name="email" type="email" placeholder="ejemplo@dominio.com" required />
                  )}
              </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field" style={{ visibility: 'hidden' }}>


          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Link style={buttonStyle} className="btn btn-secondary account__btn account__btn--small" to="/">Cancelar</Link>
            <button
              className={"btn btn-primary account__btn account__btn--small" + expandClass}
              type="primary"
              htmltype="submit"
            ><LoadingIcon />
              Enviar
          </button>
          </div>
        </div>
      </form>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordForm);

const mapStateToProps = (state) =>{
    return {
        loading: state.loading,
        error: state.error
    }
};

const mapDispatchToProps = dispatch =>{
  return{
      onReset: (email) => dispatch(actions.resetPassword(email))
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedResetPasswordForm));
