/*eslint-disable */
import axios from 'axios';
import React from 'react';
import {authFail} from "../../redux/actions/auth";
import Change_Password from '../../containers/UserProfile/components/Change_Password';
import * as pbiEnv from '../../containers/Dashboard/pbi_env';
import { func } from 'prop-types';

const AuthServices = {

    // GET METHODS
    getUserFromSession: function () {
        return axios.get('/api/users/user/', )
    },

    getLoggedInfo: function () {
        return axios.get('/api/loggedininfo/', )
    },

    getPbiCredentials: async function () {
        return await axios.get('/api/users/pbiCredentials')
    },

    // POST METHODS
    userLogin: function (email, password) {
         return axios.post('/rest-auth/login/', {
              email:email,
              password:password
            });
    },

    resetPassword: function (email) {
        return axios.post('/rest-auth/password/reset/', {
            email:email
        });
    },

    confirmPassword: function (pass1, pass2, uid, token) {
        return axios.post('/rest-auth/password/reset/confirm/'+ uid +'/' + token + '/',{
            new_password1: pass1,
            new_password2: pass2,
            uid: uid,
            token: token
        });
    },

    changePassword: function (data, uid) {
        //return axios.put('/api/customers/'+id+'/' ,{
        return axios.put('/user/password/' ,{
            old_password: data.pass0,
            new_password: data.pass1,


        });
    },

    deleteSession: function (id) {
        return axios.delete('api/loggedininfo/'+id)
    },

    registerUser: function (data) {
        return axios.post('api/users/registerUser', {
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            customer: data.customer,
            ruc: data.ruc,
            phone: data.phone,
            pass1: data.pass1,
            pass2: data.pass2,
            type: 'B',
            address: "Online",
            allies: 0,
            payment_freq: "NN",
            service_type: "N",
            master_licenses: 1,
            standard_licenses: 1
        });
        
    }
};

export default AuthServices;