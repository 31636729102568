/*eslint-disable */
import React from 'react';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { Icon } from 'antd';
import ProspectsService from '../../../services/prospects/ProspectsService';
import UserService from '../../../services/user/UserService';
const { TextArea } = Input;
import UserContext from '../../../store/userContext';


class AddProspects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      handleAddModal: this.props.handleAddModal,
      reloadList: this.props.reloadList,
      load: false,
      serviceTypes: [
        {
          value: 'N',
          type: 'Ninguno'
        },
        {
          value: 'L',
          type: 'Licencias'
        },
        {
          value: 'R',
          type: 'Reportería Mensual'
        },
      ],
      payFreqTypes: [
        {
          value: 'NN',
          type: 'Ninguno'
        },
        {
          value: 'MY',
          type: 'Mensual 0%'
        },
        {
          value: 'QY',
          type: 'Trimestral 10%'
        },
        {
          value: 'BA',
          type: 'Semestral 20%'
        },
        {
          value: 'AN',
          type: 'Anual 30%'
        }
      ],
      userName: []
    };
  }

  componentDidMount() {
    UserService.getUser()
      .then(response => {
        this.setState({
          userName: response.data,
        })
      })
      .catch(error => {
        this.setState({
          userName: [],
        })
      });
  }

  handleSelectServiceType = () => {
    let renderOptions;

    renderOptions = this.state.serviceTypes.map(function (data, idx) {
      return <Option value={data.value}>{data.type}</Option>;
    });
    return renderOptions
  };

  handleSelectAliado = () => {
    let renderOptions;

    renderOptions = this.state.userName.filter(user => user.type === 'D' || user.type === 'E' || user.type === 'A').map(function (data, idx) {
      return <Option value={data.id}>{data.name + " " + data.lastname}</Option>;
    });
    return renderOptions;
  };

  handleSelectFrequencyPayType = () => {
    let renderOptions;

    renderOptions = this.state.payFreqTypes.map((data, idy) => {
      return <Option value={data.value}>{data.type}</Option>;
    });

    return renderOptions;
  };

  handleChangeType = e => {
    this.setState({ selectValue: e });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          load: true
        });
        ProspectsService.addProspect(values)
          .then(response => {
            if (response.status === 201) {
              notificationWithIcon('success', 'Creación de Prospecto', 'Se creó al prospecto correctamente.');
              this.props.form.resetFields();
              this.state.handleAddModal(false);
              this.state.reloadList();
            }
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.setState({
                load: false,
              });
              notificationWithIcon('error', 'Error', 'Este prospecto ya existe, verifique el RUT o email');
            } else {
              this.setState({
                load: false,
              });
              notificationWithIcon('error', 'Error', 'Hubo un error al crear el prospecto');
            }
          });
      }
    });
    setTimeout(() => this.setState({ load: false }), 2000);
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { load } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <UserContext.Consumer>
        {(user) => (
          user.type === 'A' || user.type === 'D' || user.type === 'F' ?
            <Modal
              footer={null}
              width='950px'
              title={<div><Icon type="user-add" style={{ fontSize: '35px', marginRight: '20px', color: '#3879b1' }} />Agregar Prospecto</div>}
              visible={this.props.visible}
              onOk={() => this.handleOk(this.props.user)}
              onCancel={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}
              okText="Guardar"
              cancelText="Cancelar"
            >

              <Form {...formItemLayout} layout="inline" onSubmit={this.handleSubmit}>

                <Form.Item labelCol={{lg: { span: 3 }}} wrapperCol={{lg: { span: 21 }}} label={user.type === 'A' ? "Aliado" : "Ejec. de Ventas"}>
                  <div className="form__form-group-field" style={{ width: "670px" }}>
                    {getFieldDecorator('users', {
                      validateTrigger: ["onChange", "onBlur"]
                    })(<Select onChange={this.handleChangeType}
                      placeholder={"Seleccione el nombre del aliado"}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.handleSelectAliado()}
                    </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Nombre">
                  {getFieldDecorator('name', {})(<Input />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Apellido">
                  {getFieldDecorator('lastname', {})(<Input />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 7 }}} wrapperCol={{lg: { span: 17 }}} label="Correo" style={{width: '290px'}}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Ingrese un correo válido',
                      },
                      {
                        required: true,
                        message: 'Por favor, ingrese un correo',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Empresa">
                  {getFieldDecorator('business_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, ingrese la empresa',
                      },
                    ],
                  })(<Input style={{width: '97%'}}/>)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="RUT">
                  {getFieldDecorator('ruc', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, ingrese el RUT',
                      },
                    ],
                  })(<Input style={{width: '110%'}} />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Móvil" style={{width: '304px'}}>
                  {getFieldDecorator('mobile', {
                  })(<Input type='number' pattern="[0-9].{15,}" style={{ width: '100%' }} />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Teléfono" style={{width: '280px'}}>
                  {getFieldDecorator('phone', {
                    rules: [],
                  })(<Input type='number' pattern="[0-9].{15,}" />)}
                </Form.Item>

                <Form.Item label="Dirección" labelCol={{lg: { span: 4 }}} wrapperCol={{lg: { span: 20 }}} style={{width: '550px'}}>
                  {getFieldDecorator('address', {
                    rules: [],
                  })(<Input style={{width: '490px'}} />)}
                </Form.Item>

                <Form.Item label="Tipo de Servicio" labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} style={{width: '400px'}}>
                  <div className="form__form-group-field">
                    {getFieldDecorator('service_type', {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [],
                    })(<Select onChange={this.handleChangeType}
                      placeholder={"Seleccione el tipo de servicio"}>
                      {this.handleSelectServiceType()}
                    </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="Frecuencia de Pago" labelCol={{lg: { span: 10 }}} wrapperCol={{lg: { span: 14 }}} style={{width: '450px'}}>
                  <div className="form__form-group-field" style={{width: '274px'}}>
                    {getFieldDecorator('payment_freq', {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [],
                    })(<Select onChange={this.handleChangeType}
                      placeholder={"Seleccione la frecuencia de pago"}>
                      {this.handleSelectFrequencyPayType()}
                    </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="Licencias Master" labelCol={{lg: { span: 8 }}} style={{width: '400px'}}>
                  {getFieldDecorator('master_licenses', {
                  })(<Input type='number' style={{ width: '268px' }} />)}
                </Form.Item>

                <Form.Item label="Licencias Estándar" labelCol={{lg: { span: 10 }}} wrapperCol={{lg: { span: 14 }}} style={{width: '450px'}}>
                  {getFieldDecorator('standard_licenses', {
                  })(<Input type='number' style={{ width: '275px' }} />)}
                </Form.Item>

                <Form.Item label="Descripción" labelCol={{lg: { span: 4 }}} wrapperCol={{lg: { span: 20 }}} style={{width: '800px'}}>
                  {getFieldDecorator('description', {
                  })(<Input style={{width: '745px'}} />)}
                </Form.Item>

                <Form.Item wrapperCol={{lg: { span: 24 }}} style={{ marginLeft: '40%', paddingTop: '15px' }}>
                  <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}>
                    Cancelar
                  </Button>
                  <Divider type="vertical" />
                  <Button icon={"true"} loading={load} htmlType="submit"
                    style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                    Agregar
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

            :

            <Modal
              footer={null}
              width='950px'
              title={<div><Icon type="user-add" style={{ fontSize: '35px', marginRight: '20px', color: '#3879b1' }} />Agregar Prospecto</div>}
              visible={this.props.visible}
              onOk={() => this.handleOk(this.props.user)}
              onCancel={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}
              okText="Guardar"
              cancelText="Cancelar"
            >

              <Form {...formItemLayout} layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="Nombre" labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}}>
                  {getFieldDecorator('name', {})(<Input />)}
                </Form.Item>

                <Form.Item label="Apellido" labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}}>
                  {getFieldDecorator('lastname', {})(<Input />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 7 }}} wrapperCol={{lg: { span: 17 }}} label="Correo" style={{width: '290px'}}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Ingrese un correo válido',
                      },
                      {
                        required: true,
                        message: 'Por favor, ingrese un correo',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Empresa">
                  {getFieldDecorator('business_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, ingrese la empresa',
                      },
                    ],
                  })(<Input style={{width: '97%'}} />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="RUT">
                  {getFieldDecorator('ruc', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, ingrese el RUT',
                      },
                    ],
                  })(<Input style={{width: '110%'}} />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Móvil" style={{width: '304px'}}>
                  {getFieldDecorator('mobile', {
                  })(<Input type='number' pattern="[0-9].{15,}" style={{ width: '100%' }} />)}
                </Form.Item>

                <Form.Item labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} label="Teléfono" style={{width: '280px'}}>
                  {getFieldDecorator('phone', {
                    rules: [],
                  })(<Input type='number' pattern="[0-9].{15,}" />)}
                </Form.Item>

                <Form.Item label="Dirección" labelCol={{lg: { span: 4 }}} wrapperCol={{lg: { span: 20 }}} style={{width: '550px'}}>
                  {getFieldDecorator('address', {
                    rules: [],
                  })(<Input style={{width: '490px'}}/>)}
                </Form.Item>

                <Form.Item label="Tipo de Servicio" labelCol={{lg: { span: 8 }}} wrapperCol={{lg: { span: 16 }}} style={{width: '400px'}}>
                  <div className="form__form-group-field">
                    {getFieldDecorator('service_type', {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [],
                    })(<Select onChange={this.handleChangeType}
                      placeholder={"Seleccione el tipo de servicio"}>
                      {this.handleSelectServiceType()}
                    </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="Frecuencia de Pago" labelCol={{lg: { span: 10 }}} wrapperCol={{lg: { span: 14 }}} style={{width: '450px'}}>
                  <div className="form__form-group-field" style={{width: '274px'}}>
                    {getFieldDecorator('payment_freq', {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [],
                    })(<Select onChange={this.handleChangeType}
                      placeholder={"Seleccione la frecuencia de pago"}>
                      {this.handleSelectFrequencyPayType()}
                    </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="Licencias Master" labelCol={{lg: { span: 8 }}} style={{width: '400px'}}>
                  {getFieldDecorator('master_licenses', {
                  })(<Input type='number' style={{ width: '268px' }} />)}
                </Form.Item>

                <Form.Item label="Licencias Estándar" labelCol={{lg: { span: 10 }}} wrapperCol={{lg: { span: 14 }}} style={{width: '450px'}}>
                  {getFieldDecorator('standard_licenses', {
                  })(<Input type='number' style={{ width: '275px' }} />)}
                </Form.Item>

                <Form.Item label="Descripción" labelCol={{lg: { span: 4 }}} wrapperCol={{lg: { span: 20 }}} style={{width: '800px'}}>
                  {getFieldDecorator('description', {
                  })(<Input style={{width: '745px'}}/>)}
                </Form.Item>

                {getFieldDecorator('users', {
                  initialValue: user.id
                })(<Input hidden={true} value={user.id} />)}

                <Form.Item wrapperCol={{lg: { span: 24 }}} style={{ marginLeft: '40%', paddingTop: '15px' }}>
                  <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.state.handleAddModal(false) }}>
                    Cancelar
                  </Button>
                  <Divider type="vertical" />
                  <Button icon={"true"} loading={load} htmlType="submit"
                    style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                    Agregar
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
        )}
      </UserContext.Consumer>
    )
  }
}

const WrappedRegistrationForm = Form.create()(AddProspects);

export default WrappedRegistrationForm;
