/*eslint-disable */
import React from 'react';
import {Tabs, Modal, Table, Empty, Button, Select, InputNumber, Rate, Row as Rantd, Col as Cantd} from "antd";
const {Column} = Table;
const {TabPane} = Tabs
import moment from 'moment';
import {Col, Row} from 'reactstrap';
import '../../../scss/detailNotifications.css';
import UserService from "../../../services/user/UserService";
import OpportunityManagerService from '../../../services/opportunity/OpportunityManagerService';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import Countdown from './Countdown';


class OportunidadCa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list_users: [],
            visible: true,
            handleModal: this.props.handleModal,
            //Reload list
            reloadList: this.props.reloadList,
            //button state
            edit: 0,
            //formValues
            rate: 0,
            r_date: '',
            situation_op: 'A',
            vendor_id: 0,
            off_g: '',
            del_time: null,
            rate_by: 0,
            assigned_by: 0,
            assigned_date: '1990-01-01 12:59:59.123000',
            situationOp: [
                {
                    value: 'A',
                    type: 'Por iniciar'
                },
                {
                    value: 'B',
                    type: 'En proceso'
                },
                {
                    value: 'C',
                    type: 'Presentada'
                },
                {
                    value: 'D',
                    type: 'Cancelada'
                },
                {
                    value: 'E',
                    type: 'Descartada'
                },
            ],
            off_guarantee: [
                {
                    value: 'A',
                    type: 'Solicitada'
                },
                {
                    value: 'B',
                    type: 'No Solicitada'
                },
                {
                    value: 'C',
                    type: 'Entregada'
                },
                {
                    value: 'D',
                    type: 'No Aplica'
                },
            ],
            loading: false,
        };
    }

    componentDidMount() {
        UserService.getListUserOppMng()
            .then(response => {
                this.setState({
                    list_users: response.data,
                })
            })
            .catch(error => {
                this.setState({
                    list_users: [],
                })
            });
    }

    cleanValues = () => {
        this.setState({
            edit: 0,
            //formValues
            rate: 0,
            r_date: '',
            situation_op: 'A',
            vendor_id: 0,
            off_g: '',
            del_time: null,
            rate_by: 0,
            assigned_by: 0,
            assigned_date: '1990-01-01 12:59:59.123000',
            loading: false,
        });
    }

    buttonCancel = () => {
        this.cleanValues();
        this.state.reloadList();
    }

    buttonInsert = (status) => {
        if (status === "A") {
            if (this.props.code !== null && this.state.rate !== 0) {
                this.setState({loading: true});
                OpportunityManagerService.insertOpportunity(this.props.code, this.state.situation_op,
                    this.state.rate, this.state.vendor_id, this.state.off_g, this.state.del_time,
                    this.state.rate_by, this.state.r_date, this.state.assigned_by, this.state.assigned_date)
                    .then(response => {
                        this.cleanValues();
                        this.state.reloadList();
                        notificationWithIcon('success', 'Gestión editada', 'Se han guardado los cambios.');
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                notificationWithIcon('error', 'Error', 'Debe asignar calificación.');
                console.log("No se obtuvo la licitación o el rut");
            }
        } else if (status === "B") {
            this.props.op_data.map(data1 => {
                data1.data.map(data2 => {
                    this.setState({loading: true});
                    OpportunityManagerService.updateOpportunity(this.props.code,
                        this.state.situation_op !== "A" ? this.state.situation_op : data2.estado_situacion,
                        this.state.rate !== 0 ? this.state.rate : data2.valor_calificacion,
                        this.state.vendor_id !== 0 ? this.state.vendor_id : data2.vendedor_id,
                        this.state.off_g !== '' ? this.state.off_g : data2.garantia_oferta,
                        this.state.del_time !== null ? this.state.del_time : data2.plazo_entrega,
                        this.state.rate !== 0 ? this.state.rate_by : data2.calificado_por,
                        this.state.rate !== 0 ? this.state.r_date : data2.fecha_calificacion,
                        this.state.vendor_id !== 0 ? this.state.assigned_by : data2.asignado_por,
                        this.state.vendor_id !== 0 ? this.state.assigned_date : data2.fecha_asignacion,
                        this.state.vendor_id !== 0 ? 1 : 0
                    )
                        .then(response => {
                            this.cleanValues();
                            this.state.reloadList();
                            notificationWithIcon('success', 'Gestión editada', 'Se han guardado los cambios.');
                        })
                        .catch(error => {
                            console.log(error)
                        });
                })
            })
        }
    }

    getUserName = (id) => {
        let userN, userL, userC = "";
        for (let key in this.state.list_users) {
            if (this.state.list_users[key].id === id) {
                userN = this.state.list_users[key].name;
                userL = this.state.list_users[key].lastname;
                userC = userN + ' ' + userL;
                break;
            }
        }
        return userC;
    };

    getSituationOp = (value) => {
        let valSitOp = "";
        for (let key in this.state.situationOp) {
            if (this.state.situationOp[key].value === value) {
                valSitOp = this.state.situationOp[key].type;
                break;
            }
        }
        return valSitOp;
    };

    getOffGuarantee = (value) => {
        let valSitOp = "";
        for (let key in this.state.off_guarantee) {
            if (this.state.off_guarantee[key].value === value) {
                valSitOp = this.state.off_guarantee[key].type;
                break;
            }
        }
        return valSitOp;
    };

    handleRateChange = (e) => {
        this.setState({
            rate: e,
            r_date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            rate_by: this.props.user_id,
        })
    };

    changeForm = (value) => {
        this.setState({
            edit: value,
        })
    }

    handleBtnReportInd = (report) => {
        if (this.props.report) {
            return this.props.report.setPage(report)
                .then(function (n) {
                    // notificationWithIcon('info', 'Cargando...', 'Espere un momento');
                })
                .catch(error => {
                    console.error("Error de carga: ", error);
                })
        }
    }

    handleChange = (e, type) => {
        switch (type) {
            case "S":
                this.setState({
                    situation_op: e,
                });
                break;
            case "V":
                this.setState({
                    vendor_id: e,
                    assigned_by: this.props.user_id,
                    assigned_date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
                });
                break;
            case "G":
                this.setState({
                    off_g: e,
                });
                break;
            case "P":
                this.setState({
                    del_time: e,
                });
                break;
            default:
                return type;
        }
    }

    handleSelectVendorType = () => {
        let renderOptions;

        renderOptions = this.state.list_users.map(function (option) {
            return <Option value={option.id}>{option.name + " " + option.lastname}</Option>;
        });
        return renderOptions;
    };

    editInformation = (situacion_op, rate, vendedor_id, garantia_oferta, plazoentrega, status) => {
        return (
            <div>
                {this.props.user_type === "B"
                    ?
                    <p>
                        <b>Calificación: </b>
                        <Rate name="rate" count={3}
                              defaultValue={rate}
                              style={{fontSize: 12, paddingBottom: "0.3rem"}}
                              onChange={this.handleRateChange}/>
                    </p>
                    :
                    <p>
                        <b>Calificación: </b>
                        <Rate name="rate" count={3}
                              disabled
                              defaultValue={rate}
                              style={{fontSize: 12, paddingBottom: "0.3rem"}}
                              onChange={this.handleRateChange}/>
                    </p>
                }
                {status === "B" && this.props.user_type === "B"
                    ?
                    <p>
                        <b>Ejecutivo: </b>
                        <Select size="small" style={{width: 200, paddingBottom: "0.3rem"}} defaultValue={vendedor_id}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(e) => this.handleChange(e, "V")}>
                            <Option value={0} disabled>
                                {""}
                            </Option>
                            {this.handleSelectVendorType()}
                        </Select>
                    </p>
                    :
                    (this.props.user_type === "C" || this.state.rate === 0
                            ?
                            <p>
                                <b>Ejecutivo: </b>
                                <Select disabled size="small" style={{width: 200, paddingBottom: "0.3rem"}}
                                        defaultValue={vendedor_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) => this.handleChange(e, "V")}>
                                    <Option value={0} disabled>
                                        {""}
                                    </Option>
                                    {this.handleSelectVendorType()}
                                </Select>
                            </p>
                            :
                            <p>
                                <b>Ejecutivo: </b>
                                <Select size="small" style={{width: 200, paddingBottom: "0.3rem"}}
                                        defaultValue={vendedor_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) => this.handleChange(e, "V")}>
                                    <Option value={0} disabled>
                                        {""}
                                    </Option>
                                    {this.handleSelectVendorType()}
                                </Select>
                            </p>
                    )
                }
                {status === "B" && this.props.user_type === "B" || status === "B" && this.props.user_type === "C"
                    ?
                    <p>
                        <b>Situación: </b>
                        <Select size="small" style={{width: 125, paddingBottom: "0.3rem"}} defaultValue={situacion_op}
                                onChange={(e) => this.handleChange(e, "S")}>
                            <Option value="disabled" disabled>
                                Disabled
                            </Option>
                            {this.state.situationOp.map((option) => (
                                <Option value={option.value}>{option.type}</Option>
                            ))}
                        </Select>
                    </p>
                    :
                    (this.state.vendor_id === 0
                            ?
                            <p>
                                <b>Situación: </b>
                                <Select disabled size="small" style={{width: 125, paddingBottom: "0.3rem"}}
                                        defaultValue={situacion_op}
                                        onChange={(e) => this.handleChange(e, "S")}>
                                    <Option value="disabled" disabled>
                                        Disabled
                                    </Option>
                                    {this.state.situationOp.map((option) => (
                                        <Option value={option.value}>{option.type}</Option>
                                    ))}
                                </Select>
                            </p>
                            :
                            <p>
                                <b>Situación: </b>
                                <Select size="small" style={{width: 125, paddingBottom: "0.3rem"}}
                                        defaultValue={situacion_op}
                                        onChange={(e) => this.handleChange(e, "S")}>
                                    <Option value="disabled" disabled>
                                        Disabled
                                    </Option>
                                    {this.state.situationOp.map((option) => (
                                        <Option value={option.value}>{option.type}</Option>
                                    ))}
                                </Select>
                            </p>
                    )
                }
                {this.props.user_id === vendedor_id && rate !== 0 || this.props.user_type === "B" && vendedor_id !== 0 || this.state.vendor_id !== 0
                    ?
                    <div>
                        <p>
                            <b>Garantia Ofertada: </b>
                            <Select size="small" style={{width: 122, paddingBottom: "0.3rem"}}
                                    defaultValue={garantia_oferta}
                                    onChange={(e) => this.handleChange(e, "G")}>
                                <Option value="disabled" disabled>
                                    Disabled
                                </Option>
                                {this.state.off_guarantee.map((option) => (
                                    <Option value={option.value}>{option.type}</Option>
                                ))}
                            </Select>
                        </p>
                        <p><b>Plazo de Entrega: </b>
                            <InputNumber
                                size="small"
                                style={{width: 55}}
                                min={0}
                                max={200}
                                defaultValue={plazoentrega}
                                onChange={(e) => this.handleChange(e, "P")}/>{' días'}<span style={{fontWeight: "bold"}}>*</span><br/>
                            <p>*El límite de días a entregar es de 200</p>
                        </p>
                    </div>
                    :
                    <div>
                        <p>
                            <b>Garantia Ofertada: </b>
                            <Select disabled size="small" style={{width: 122, paddingBottom: "0.3rem"}}
                                    defaultValue={garantia_oferta}
                                    onChange={(e) => this.handleChange(e, "G")}>
                                <Option value="disabled" disabled>
                                    Disabled
                                </Option>
                                {this.state.off_guarantee.map((option) => (
                                    <Option value={option.value}>{option.type}</Option>
                                ))}
                            </Select>
                        </p>
                        <p><b>Plazo de Entrega: </b>
                            <InputNumber
                                disabled
                                size="small"
                                style={{width: 55}}
                                min={0}
                                max={200}
                                defaultValue={plazoentrega}
                                onChange={(e) => this.handleChange(e, "P")}/>{' días'}<span style={{fontWeight: "bold"}}>*</span><br/>
                            <p>*El límite de días a entregar es de 200</p>
                        </p>
                    </div>
                }
                {this.state.rate !== 0 || rate !== 0
                    ?
                    <div>
                        <br/>
                        <Button onClick={() => this.buttonCancel()}
                                style={{marginLeft: "7.5rem"}}>Cancelar</Button>
                        {this.state.loading === true && this.state.loading.valueOf()
                            ?
                            <Button type="primary"
                                    disabled
                                    style={{
                                        marginLeft: "0.5rem",
                                        background: "#e5a328",
                                        borderColor: "#e5a328",
                                        color: "#fff"
                                    }}
                                    loading>
                                Guardando
                            </Button>
                            :
                            <Button onClick={() => this.buttonInsert(status)}
                                    style={{
                                        marginLeft: "0.5rem",
                                        background: "#e5a328",
                                        borderColor: "#e5a328",
                                        color: "#fff"
                                    }}
                                    type="primary">Guardar
                            </Button>
                        }
                    </div>
                    :
                    <div>
                        <br/>
                        <Button onClick={() => this.buttonCancel()} style={{marginLeft: "7.5rem"}}>Cancelar</Button>
                        <Button disabled style={{marginLeft: "0.5rem"}}>Guardar</Button>
                    </div>
                }
            </div>
        )
    }

    informationCa = (comprador, fecha_pub, fecha_cierre, nombrecontacto, telcontacto, emailcontacto, monto_total) => {
        let ca;
        ca = this.props.op_data.map(data1 => {
            if (data1.data.length === 0) {
                let status = "A";
                return (<div style={{padding: "0 12px", fontSize: "12px"}}>
                    <Row>
                        <Col lg={4}>
                            <p><b>Comprador:</b> {comprador}</p>
                            <p><b>Fecha de Publicación:</b> {moment(fecha_pub).format("DD/MM/YYYY h:mm:ss a")}</p>
                        </Col>
                        <Col lg={4}>
                            <p><b>Contacto:</b> {nombrecontacto}</p>
                            <p><b>Teléfono Contacto:</b> {telcontacto}</p>
                            <p><b>Correo Contacto:</b> {emailcontacto}</p>
                            <p><b>Monto Estimado (CLP):</b> {monto_total.toLocaleString("es-CL")}</p>
                            <p><b>Calificado por:</b> {''}</p>
                            <p><b>Fecha de Calificación:</b> {''}</p>
                            <p><b>Asignado Por:</b> {''}</p>
                            <p><b>Fecha de Asignación:</b> {''}</p>
                        </Col>
                        <Col lg={4}>
                            {this.state.edit === 0 ?
                                <Row>
                                    <Col lg={9}>
                                        <div>
                                            <p>
                                                <b>Calificación: </b>
                                                <Rate name="rate" count={3}
                                                      disabled
                                                      value={0}
                                                      style={{fontSize: 12}}
                                                      onChange={this.handleRateChange}/>
                                            </p>
                                            <p><b>Ejecutivo: </b> {''}</p>
                                            <p><b>Situación: </b>{''}</p>
                                            <p><b>Garantia Ofertada: </b> {''}</p>
                                            <p><b>Plazo de Entrega: </b> {''}</p>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        {this.props.user_type === "B"
                                            ?
                                            <div style={{textAlign: "left"}}>
                                                <Button
                                                    onClick={() => this.changeForm(1)}
                                                    style={{
                                                        fontSize: 18,
                                                        backgroundColor: 'white',
                                                        color: '#70bbfd',
                                                        borderColor: 'white',
                                                    }}
                                                    size="default"
                                                    icon="edit"
                                                />
                                                <Button
                                                    onClick={() => {
                                                        this.handleBtnReportInd('ReportSection5ed9ce5a216edd476a56');
                                                        this.state.handleModal(false);
                                                    }}
                                                    style={{
                                                        fontSize: 18,
                                                        backgroundColor: 'white',
                                                        color: '#70bbfd',
                                                        borderColor: 'white',
                                                    }}
                                                    size="default"
                                                    icon="line-chart"
                                                />
                                            </div>
                                            : ''
                                            // <div style={{textAlign: "left"}}>
                                            //     <Button
                                            //         onClick={() => {
                                            //             this.handleBtnReportInd('ReportSectionfb5e20cea0cf682e757f');
                                            //             this.state.handleModal(false);
                                            //         }}
                                            //         style={{
                                            //             fontSize: 18,
                                            //             backgroundColor: 'white',
                                            //             color: '#70bbfd',
                                            //             borderColor: 'white',
                                            //         }}
                                            //         size="default"
                                            //         icon="line-chart"
                                            //     />
                                            // </div>
                                         }
                                    </Col>
                                </Row>
                                :
                                (this.editInformation('A', 0, 0, '',
                                    null, status))
                            }
                        </Col>
                    </Row>
                </div>)
            } else {
                let status = "B";
                return data1.data.map(data2 => {
                    return (
                        <div style={{padding: "0 12px", fontSize: "12px"}}>
                            <Row>
                                <Col lg={4}>
                                    <p><b>Comprador:</b> {comprador}</p>
                                    <p><b>Fecha de
                                        Publicación:</b> {moment(fecha_pub).format("DD/MM/YYYY h:mm:ss a")}
                                    </p>
                                </Col>
                                {/*Export Data*/}
                                <Col lg={4}>
                                    <p><b>Contacto:</b> {nombrecontacto}</p>
                                    <p><b>Teléfono Contacto:</b> {telcontacto}</p>
                                    <p><b>Correo Contacto:</b> {emailcontacto}</p>
                                    <p><b>Monto Estimado (CLP):</b> {monto_total.toLocaleString("es-CL")}</p>
                                    <p><b>Calificado por:</b> {this.getUserName(data2.calificado_por)}</p>
                                    <p><b>Fecha de
                                        Calificación:</b> {moment.utc(data2.fecha_calificacion).local().format("DD/MM/YYYY")}
                                    </p>
                                    <p><b>Asignado Por:</b> {this.getUserName(data2.asignado_por)}</p>
                                    <p>
                                        <b>Fecha de Asignación:</b>
                                        {moment.utc(data2.fecha_asignacion).local().format("DD/MM/YYYY") !== "01/01/1990" ?
                                            moment.utc(data2.fecha_asignacion).local().format("DD/MM/YYYY")
                                            :
                                            ""
                                        }
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {this.state.edit === 0 ?
                                        <Row>
                                            <Col lg={9}>
                                                <div>
                                                    <p>
                                                        <b>Calificación: </b>
                                                        <Rate name="rate" count={3}
                                                              disabled
                                                              value={data2.valor_calificacion}
                                                              style={{fontSize: 12}}
                                                              onChange={this.handleRateChange}/>
                                                    </p>
                                                    <p><b>Ejecutivo: </b> {this.getUserName(data2.vendedor_id)}</p>
                                                    <p><b>Situación: </b>{this.getSituationOp(data2.estado_situacion)}
                                                    </p>
                                                    <p><b>Garantia
                                                        Ofertada: </b> {this.getOffGuarantee(data2.garantia_oferta)}</p>
                                                    <p>
                                                        <b>Plazo de Entrega: </b>
                                                        {data2.plazo_entrega != null ?
                                                            data2.plazo_entrega + ' días' : ''}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                    {this.props.user_type === 'B' ?
                                                    <div style={{textAlign: "left"}}>
                                                        <Button
                                                            onClick={() => this.changeForm(1)}
                                                            style={{
                                                                fontSize: 18,
                                                                backgroundColor: 'white',
                                                                color: '#70bbfd',
                                                                borderColor: 'white',
                                                            }}
                                                            size="default"
                                                            icon="edit"/>
                                                        <Button
                                                            onClick={() => {
                                                                this.handleBtnReportInd('ReportSection5ed9ce5a216edd476a56');
                                                                this.state.handleModal(false);
                                                            }}
                                                            style={{
                                                                fontSize: 18,
                                                                backgroundColor: 'white',
                                                                color: '#70bbfd',
                                                                borderColor: 'white',
                                                            }}
                                                            size="default"
                                                            icon="line-chart"
                                                        />
                                                       {/* {user.type === "C" && user.id === data2.vendedor_id ?
                                                            <Button
                                                                onClick={() => {
                                                                    this.handleBtnReportInd('ReportSectionfb5e20cea0cf682e757f');
                                                                    this.state.handleModal(false);
                                                                }}
                                                                style={{
                                                                    fontSize: 18,
                                                                    backgroundColor: 'white',
                                                                    color: '#70bbfd',
                                                                    borderColor: 'white',
                                                                }}
                                                                size="default"
                                                                icon="line-chart"
                                                            />
                                                            :
                                                            
                                                        } */}
                                                    </div>
                                                    :
                                                    this.props.user_id === data2.vendedor_id ? 
                                                        <Button
                                                            onClick={() => this.changeForm(1)}
                                                            style={{
                                                                fontSize: 18,
                                                                backgroundColor: 'white',
                                                                color: '#70bbfd',
                                                                borderColor: 'white',
                                                            }}
                                                            size="default"
                                                            icon="edit"/>
                                                    : ''
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        (this.editInformation(data2.estado_situacion, data2.valor_calificacion, data2.vendedor_id, data2.garantia_oferta,
                                            data2.plazo_entrega, status))
                                    }
                                </Col>
                            </Row>
                        </div>
                    )
                });
            }
        });

        return ca;
    }

    detailComprador = () => {
        let modalCa;
        modalCa = this.props.detail.map(data1 => {
            return data1.detail.map(data2 => {
                return (
                    <Modal
                        footer={null}
                        title=
                            {
                                <div>
                                    <Rantd>
                                        <Cantd span={12}>
                                            <div style={{textAlign: "left"}}>
                                                Detalle de Cotización N°: {data2.numerocotizacion.toUpperCase()}
                                            </div>
                                        </Cantd>
                                        <Cantd span={12}>
                                            <div style={{textAlign: "right", marginRight: "2rem",}}>
                                                Fecha de Cierre: {moment(data2.fechacierre).format("DD/MM/YYYY h:mm:ss a")}
                                            </div>
                                            <div style={{textAlign: "right", marginRight: "2rem",}}>
                                                Plazo para el Cierre: <Countdown futureDate={data2.fechacierre}/>
                                            </div>
                                        </Cantd>
                                    </Rantd>
                                </div>
                            }
                        visible={this.props.visible}
                        width={'1200px'}
                        onCancel={() => {
                            this.state.handleModal(false)
                            this.cleanValues();
                            this.state.reloadList();
                        }}
                    >
                        {this.props.detail.length === 0 ?
                            <Empty style={{margin: "10px 8px", fontSize: "18px"}}
                                   description="Por favor, escoger una compra ágil de su interés en la tabla. Cierre el modal"/>
                            :
                            <div>
                                {this.informationCa(data2.nombreorganismo, data2.fechapublicacion, data2.fechacierre,
                                    data2.nombrecontacto, data2.telcontacto, data2.emailcontacto, data2.monto_total)}
                                <br/>
                                <Tabs type="card">
                                    <TabPane tab="Items Solicitados" key="1">
                                        {this.detailItems()}
                                    </TabPane>
                                </Tabs>
                            </div>
                        }
                    </Modal>
                )
            });
        });

        return modalCa;

    }

    detailItems = () => {
        let items;

        items = this.props.detail.map(data1 => {
            return (<Table dataSource={data1.items} scroll={{y: 200}} rowClassName="fontSize__table" size="small"
                           pagination={false} footer={() => `${data1.items.length} items en total`}>
                <Column title="Producto" dataIndex="producto" key="producto" width="20%"/>
                <Column title="Descripcion" dataIndex="producto_descripcion" key="producto_descripcion" width="40%"/>
                <Column title="Cantidad" dataIndex="cantidad" key="cantidad" width="10%"/>
                <Column title="Medida" dataIndex="medida" key="medida" width="10%"/>
            </Table>)
        });

        return items;

    }

    render() {

        return (
            <div>
                {this.detailComprador()}
            </div>
        )
    }

}

export default OportunidadCa;