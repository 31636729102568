/*eslint-disable */
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import { Icon } from 'antd';
const { TextArea } = Input;


class EditClient extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            visible: true,
            handleEditModal: this.props.handleEditModal,
            reloadList: this.props.reloadList,
            load: false,
        };
    }

    componentDidMount() {
        CustomerService.getCustomers()
            .then(response => {
                this.setState({
                    customers: response.data,
                })
            })
            .catch(error => {
                this.setState({
                    customers: [],
                })
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                CustomerService.editCustomer(data, this.props.customer.id)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', 'Edición de Cliente', 'Se editó el cliente correctamente');
                            this.props.form.resetFields();
                            this.state.handleEditModal(false);
                            this.state.reloadList();
                        }
                    })
                    .catch(error => {
                      if(error.response.status === 400){
                          this.setState({
                            load: false,
                          });
                          notificationWithIcon('error', 'Error', 'Este cliente ya existe, verifique el RUT ingresado');
                        } else {
                          this.setState({
                            load: false,
                          });
                          notificationWithIcon('error', 'Error', 'Hubo un error al editar el cliente');
                        }
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    render() {
        const {load} = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        return (
            <Modal
                footer={null}
                title={<div><Icon type="usergroup-add" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} />Editar Cliente</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.user)}
                onCancel={() => {this.props.form.resetFields(); this.state.handleEditModal(false)}}
                okText="Guardar"
                cancelText="Cancelar"
            >

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                    <Form.Item label="Nombre">
                      {getFieldDecorator('customer', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, ingrese un nombre',
                          },
                        ],
                        initialValue:this.props.customer.customer,
                      })(<Input />)}
                    </Form.Item>

                    <Form.Item label="RUT">
                      {getFieldDecorator('ruc', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, ingrese el RUT',
                          },
                        ],
                          initialValue:this.props.customer.ruc,
                      })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Correo">
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            type: 'email',
                            message: 'Ingrese un correo valido',
                          },
                            {
                            required: true,
                            message: 'Por favor, ingrese un correo',
                          },
                        ],
                          initialValue:this.props.customer.email,
                      })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Teléfono">
                      {getFieldDecorator('phone', {
                        rules: [{ required: true, message: 'Por favor, ingrese un teléfono' }],
                          initialValue:this.props.customer.phone
                      })(<Input type='number' pattern="[0-9].{8,}" style={{ width: '100%' }} />)}
                    </Form.Item>


                    <Form.Item label="Dirección">
                      {getFieldDecorator('address', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, ingrese una dirección',
                          },
                        ],
                          initialValue:this.props.customer.address,
                      })(<TextArea rows={4} />)}
                    </Form.Item>


                    <Form.Item style={{marginLeft: '30%'}}>
                        <Button type="secondary" onClick={() => {this.props.form.resetFields(); this.state.handleEditModal(false)}}>
                            Cancelar
                        </Button>
                        <Divider type="vertical"/>
                        <Button icon={"true"} loading={load} htmlType="submit"
                                style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                            Modificar
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        )
    }
}

const WrappedEditForm = Form.create()(EditClient);

export default WrappedEditForm;
