/* eslint-disable */
import React, { PureComponent, useSelector, usePrevious, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { Menu, Empty, Icon, Tag } from 'antd';
const { SubMenu } = Menu;
import DownIcon from 'mdi-react/ChevronDownIcon';
import NotificationsService from '../../../services/notifications/NotificationsService';
import moment from 'moment';


function UnsafeComponent({ html }) {
  return <p className="topbar__collapse-message topbar__collapse-message--mail" dangerouslySetInnerHTML={{ __html: html }} />;
}

export default class TopbarNotification extends PureComponent {
  state = {
    collapse: false,
    notifData: []
  };

  componentDidMount() {
    NotificationsService.notificationService()
      .then(response => {
        this.setState({
          notifData: response.data
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          notifData: []
        });
      });
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  filterNotifications = (type) => {
    if (type === 1) {
      return <span>
        <Tag color='blue'>LIC</Tag>
      </span>
    }
    else if (type === 2) {
      return <span>
        <Tag color={'yellow'} style={{ color: 'gray' }}>
          OC
        </Tag>
      </span>
    }
    else if (type === 3) {
      return <span>
        <Tag color={'orange'}>
          ADJ
        </Tag>
      </span>
    }
    else if (type === 4) {
      return <span>
        <Tag color={'#00c1cf'} style={{ color: 'gray' }}>
          CA
        </Tag>
      </span>
    }
  };


  render() {
    const { collapse, notifData } = this.state;
    const membersToRender = notifData.map(model => model.viewed);
    const numRows = membersToRender.filter(function(s) { return s == false; }).length;
    const IconFont = Icon.createFromIconfontCN({
      scriptUrl: '//at.alicdn.com/t/font_2617681_rbjijzhlhg.js',
    });

    return (
      <div className="topbar__collapse">
        <button className="topbar__btn topbar__btn--new" type="button" style={{fontSize: "23px"}} onClick={this.toggle}>
          {/* <NotificationsIcon /> */}
          <IconFont theme="twoTone" type="icon-Notificaciones" />
            {numRows > 0 ?
              <div className="topbar__btn-new-label">
                <div />
              </div>
              :
              <div> </div>
            }
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Notificaciones</p>
            {/* <button className="topbar__collapse-button" type="button">Mark all as read</button> */}
          </div>
          {numRows === 0 ?
            <Empty description="Sin notificaciones" />
            :
            notifData.filter((s) => { return s.viewed == false }).slice(0, 5).map((notification, index) => (
              <div className="topbar__collapse-item" key={index}>
                {/* <div className="topbar__collapse-img-wrap">
                  <img className="topbar__collapse-img" src={notification.ava} alt="" />
                </div> */}
                {/* <p className="topbar__collapse-message">
                  <span className="topbar__collapse-name">{notification.length}</span>
                </p> */}
                <UnsafeComponent html={notification.notification__msg} />
                {/* <p className="topbar__collapse-date">{moment(notification.notification__noti_date).format("DD-MM-YYYY")}</p> */}
                <p className="topbar__collapse-date">{this.filterNotifications(notification.notification__type)}</p>
              </div>
            ))
          }
          <Link className="topbar__collapse-link" to="/dashboard/pages/notification" onClick={this.toggle}>
            Ver Todas las Notificaciones
          </Link>
        </Collapse>
      </div>
    );
  }
}
