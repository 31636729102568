/*eslint-disable */
import UserContext from "../userContext";
import React, { Component } from 'react';

class MyProvider extends Component{
    state = {
        user:{
            customer:0,
            email: "email@email.com",
            id: 0,
            is_blocked: true,
            lastname: 'lastname',
            name: 'name',
            password: '',
            type: 'C'
        }

    };

    render() {
        if (this.props.user){
            return(
            <UserContext.Provider value={this.props.user}>
                {this.props.children}
            </UserContext.Provider>
        )

        }
        else{
            return(
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        )
        }

    }
}

export default MyProvider;