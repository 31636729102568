/*eslint-disable */
import React, { PureComponent } from 'react';
import { notification } from 'antd';
import '../../../../scss/customNotification.css';
import 'antd/dist/antd.css';
import {withTranslation} from "react-i18next";


const notificationWithIcon = (type, message, description) => {
  notification[type]({
    className: type,
    message,
    description,
  });


};

export default notificationWithIcon;
