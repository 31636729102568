/*eslint-disable */
import React from 'react';
import {Tabs, Modal, Table, Icon, Empty, Rate, Select, Input, InputNumber, Button, Form, Divider, Row as Rantd, Col as Cantd} from "antd";
const { Column } = Table;
const {TabPane} = Tabs;
const {TextArea} = Input;
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import '../../../scss/detailNotifications.css';
import Countdown from './Countdown';
import UserService from "../../../services/user/UserService";
import OpportunityManagerService from '../../../services/opportunity/OpportunityManagerService';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import UserContext from '../../../store/userContext';


class OportunidadLic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licDetail: [],
            codeDetail: null,
            visible: true,
            handleModal: this.props.handleModal,
            load: false,
            editFormAnexo: false,
            editFormAdmin: false,
            editFormEconom: false,
            editFormTecn: false,
            editFormVisita: false,
            formEnd1: true,
            formEnd2: true,
            saleUsers: [],
            opportDetail: [],
            starValue: 0,
            activeTab: "1",
            selectValue: null,
            statusSit: [
                {
                    value: 'A',
                    type: 'Por Iniciar'
                },
                {
                    value: 'B',
                    type: 'En Proceso'
                },
                {
                    value: 'C',
                    type: 'Presentada'
                },
                {
                    value: 'D',
                    type: 'Cancelada'
                },
                {
                    value: 'E',
                    type: 'Descartada'
                }
            ],
            ofertWarranty: [
                {
                    value: 'A',
                    type: 'Solicitada'
                },
                {
                    value: 'B',
                    type: 'No Solicitada'
                },
                {
                    value: 'C',
                    type: 'Adjunta'
                },
                {
                    value: 'D',
                    type: 'Enviada'
                },
                {
                    value: 'E',
                    type: 'No Aplica'
                }
            ],
            anexos: [
                {
                    value: 1,
                    anex: 'Aplica'
                },
                {
                    value: 0,
                    anex: 'No Aplica'
                }
            ],
            situacion: [
                {
                    value: 'A',
                    type: 'Por Iniciar'
                },
                {
                    value: 'B',
                    type: 'En Proceso'
                },
                {
                    value: 'C',
                    type: 'Culminado'
                }
            ]
        };
        this.report = null;
    }

    componentDidMount() {
        UserService.getListUserOppMng()
            .then(response => {
                this.setState({
                    saleUsers: response.data
                });
            })
            .catch(error => {
                this.setState({
                    saleUsers: []
                });
                console.error("No aliado", error);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.oppDetail !== this.props.oppDetail) {
            this.changeTab("1");
            this.handleEditFormAnexo(false);
            this.handleEditFormAdmin(false);
            this.handleEditFormEconom(false);
            this.handleEditFormTecn(false);
            this.handleEditFormVisita(false);
            this.setState({
                opportDetail: this.props.oppDetail,
                codeDetail: this.props.code,
                formEnd1: true,
                formEnd2: true,
                selectValue: null
            });
        }
    };

    handleEditFormAnexo = e => {
        this.setState({editFormAnexo: e})
    };

    handleEditFormAdmin = e => {
        this.setState({editFormAdmin: e})
    };

    handleEditFormEconom = e => {
        this.setState({editFormEconom: e})
    };

    handleEditFormTecn = e => {
        this.setState({editFormTecn: e})
    };

    handleEditFormVisita = e => {
        this.setState({editFormVisita: e})
    };

    handleChangeStar = value => {
        this.setState({ starValue: value });
        if(value > 0){this.setState({formEnd1: false})}else{this.setState({formEnd1: true})};
    };

    handleNameSales = (idUser) => {
        let renderNames = "";
        for(let key in this.state.saleUsers){
            if(this.state.saleUsers[key].id === idUser){
                renderNames = this.state.saleUsers[key].name + " " + this.state.saleUsers[key].lastname;
                break;
            }
        }

        return renderNames;
    };

    handleChangeSale = e => {
        this.setState({ selectValue: e });
        if(e !== null){this.setState({formEnd2: false})}else{this.setState({formEnd2: true})};
    };

    handleSelectStatusSit = () => {
        let renderOptions;

        renderOptions = this.state.statusSit.map(function (data, idx) {
            return <Option value={data.value}>{data.type}</Option>;

        });
        return renderOptions;
    };

    handleRenderStatusSit = val => {
        let renderSit = "";
        for(let key in this.state.statusSit){
            if(this.state.statusSit[key].value === val){
                renderSit = this.state.statusSit[key].type;
                break;
            }
        }
        return renderSit;
    };

    handleSelectSaleUser = () => {
        let renderusers;

        renderusers = this.state.saleUsers.map((data) => {
            return <Option value={data.id}>{data.name + " " + data.lastname}</Option>
        });

        return renderusers;
    };

    handleSelectOfertWarranty = () => {
        let renderOptions;

        renderOptions = this.state.ofertWarranty.map(function (data, idx) {
            return <Option value={data.value}>{data.type}</Option>;

        });
        return renderOptions;
    };

    handleRenderOfertWarranty = (ofert) => {
        let renderOfert = "";
        for(let key in this.state.ofertWarranty){
            if(this.state.ofertWarranty[key].value === ofert){
                renderOfert = this.state.ofertWarranty[key].type;
                break;
            }
        }
        return renderOfert;
    };

    handleSelectSituacionAnexos = () => {
        let renderSituacion;
        renderSituacion = this.state.situacion.map(data => {
            return <Option value={data.value}>{data.type}</Option>
        });
        return renderSituacion;
    };

    handleRenderSituacionAnexos = (sit) => {
        let renderSituacion = "";
        for(let key in this.state.situacion){
            if(this.state.situacion[key].value === sit){
                renderSituacion = this.state.situacion[key].type;
                break;
            }
        }
        return renderSituacion;
    };

    handleSelectAnexos = () => {
        let renderAnexos;
        renderAnexos = this.state.anexos.map(data => {
            return <Option value={data.value}>{data.anex}</Option>
        });
        return renderAnexos;
    };

    handleRenderAnexos = (an) => {
        let renderAnexoDet;
        for(let key in this.state.anexos){
            if(this.state.anexos[key].value === an){
                renderAnexoDet = this.state.anexos[key].anex;
                break;
            }
        }
        return renderAnexoDet;
    }

    handleCancelAnexo = () => {
        const { opportDetail } = this.state;
        this.props.form.resetFields();
        this.handleEditFormAnexo(false);
        this.handleChangeStar(0);
        if(opportDetail.length > 0){
            if(opportDetail[0].vendedor_id == null){
                this.setState({
                    formEnd1: false,
                    formEnd2: true
                });
            } else {
                this.setState({
                    formEnd2: false
                });
            }
        } else {
            this.setState({
                formEnd1: true,
                formEnd2: true,
                selectValue: null
            });
        }
    }

    handleBtnReportInd = (report) => {
        if (this.props.report) {
            return this.props.report.setPage(report)
                .then(function (n) {
                    // notificationWithIcon('info', 'Cargando...', 'Espere un momento');
                })
                .catch(error => {
                    console.error("Error de carga: ", error);
                })
        }
    }

    informationLic = (comprador, region, fechapublicacion, fechacierre, fechaadjudicacion, tipolic, fuentefinanciamiento, duracioncontrato, nombreresponsablecontrato, fonoresponsablecontrato, emailresponsablecontrato, nombreresponsablepago, emailresponsablepago, montoestimado, moneda) => {
        const { getFieldDecorator } = this.props.form;
        const { opportDetail, starValue, load, formEnd1, formEnd2, selectValue } = this.state;
        const validator = (rule, value, callback) => {
            console.log(value.toString().length);
            if (value && value.toString().length > 3) {
                callback('El número no puede tener más de 3 dígitos.');
            } else {
                callback();
            }
        }

        return(
            <div style={{padding: "0 12px", fontSize: "12px"}}>
                <Row>
                    <Col lg={4}>
                        <p><b>Comprador:</b> {comprador}</p>
                        <p><b>Region:</b> {region}</p>
                        <p><b>Fecha de Publicación:</b> {moment(fechapublicacion).format("DD-MM-YYYY H:mm")}</p>
                        {/*<p><b>Fecha de cierre:</b> {moment(fechacierre).format("DD-MM-YYYY H:mm")}</p>*/}
                        {/*<p><b>Plazo para el cierre:</b> <Countdown futureDate={fechacierre} /></p>*/}
                        <p><b>Fecha de Adjudicación (estimada):</b> {moment(fechaadjudicacion).format("DD-MM-YYYY")}</p>
                        <p><b>Tipo Licitación:</b> {tipolic}</p>
                        <p><b>Fuente de Financiamiento:</b> {fuentefinanciamiento}</p>
                        <p><b>Duración del Contrato:</b> {duracioncontrato ? duracioncontrato : "No hay información"}</p>
                    </Col>
                    <Col lg={4}>
                        <p><b>Responsable del Contrato:</b> {nombreresponsablecontrato ? nombreresponsablecontrato.toUpperCase() : "No hay información"}</p>
                        <p><b>Teléfono:</b> {fonoresponsablecontrato ? fonoresponsablecontrato : "No hay información"}</p>
                        <p><b>Correo:</b> {emailresponsablecontrato ? emailresponsablecontrato : "No hay información"}</p>
                        <p><b>Responsable de Pago:</b> {nombreresponsablepago ? nombreresponsablepago.toUpperCase() : "No hay información"}</p>
                        <p><b>Correo:</b> {emailresponsablepago ? emailresponsablepago : "No hay información"}</p>
                        <p><b>Monto Estimado:</b> {montoestimado ? montoestimado.toLocaleString("es-CL") : '0.00'} {moneda}</p>
                        {opportDetail.length > 0 ?
                            <span>
                                    <p><b>Calificado por:</b> {this.handleNameSales(opportDetail[0].calificado_por)}</p>
                                    <p><b>Fecha de Calificacion:</b> {opportDetail[0].fecha_calificacion ? moment.utc(opportDetail[0].fecha_calificacion).local().format("DD-MM-YYYY H:mm") : ""}</p>
                                    <p><b>Asignado por:</b> {this.handleNameSales(opportDetail[0].asignado_por)}</p>
                                    <p><b>Fecha de Asignacion:</b> {opportDetail[0].fecha_asignacion ? moment.utc(opportDetail[0].fecha_asignacion).local().format("DD-MM-YYYY H:mm") : ""}</p>
                                </span>
                            :
                            <span>
                                    <p><b>Calificado por:</b> </p>
                                    <p><b>Fecha de Calificacion:</b> </p>
                                    <p><b>Asignado por:</b> </p>
                                    <p><b>Fecha de Asignacion:</b> </p>
                                </span>
                        }
                    </Col>
                    {this.state.editFormAnexo ?
                        <UserContext.Consumer>
                            {(user) => (
                                user.type === 'B' ?
                                    <Col lg={4}>
                                        <Form onSubmit={this.handleSubmitAnexos} style={{fontSize: 12}}>
                                            <p style={{marginBottom: 5}}><b>Calificación:</b>&nbsp;
                                                {getFieldDecorator('valor_calificacion', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].valor_calificacion : 0
                                                })(<Rate count={3} style={{fontSize: 12}} defaultValue={opportDetail.length ? opportDetail[0].valor_calificacion : 0} onChange={this.handleChangeStar}/>)}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Ejecutivo:</b>&nbsp;
                                                {getFieldDecorator('vendedor_id', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].vendedor_id : null
                                                })(<Select size="small" style={{ width: 200 }} onChange={this.handleChangeSale} 
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null || opportDetail[0].valor_calificacion > 0 ? false : formEnd1 : formEnd1}>
                                                        {this.handleSelectSaleUser()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Situación:</b>&nbsp;
                                                {getFieldDecorator('estado_situacion', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length > 0 ? opportDetail[0].estado_situacion : "A"
                                                })(<Select size="small" defaultValue="A" style={{ width: 200 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectStatusSit()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Garantía Ofertada:</b>&nbsp;
                                                {getFieldDecorator('garantia_oferta', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].garantia_oferta : null
                                                })(<Select size="small" style={{ width: 200 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectOfertWarranty()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Administrativos:</b>&nbsp;
                                                {getFieldDecorator('anexos_admin', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_admin : null
                                                })(<Select size="small" style={{ width: 180 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Económicos:</b>&nbsp;
                                                {getFieldDecorator('anexos_econom', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_econom : null
                                                })(<Select size="small" style={{ width: 200 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Técnicos:</b>&nbsp;
                                                {getFieldDecorator('anexos_tecn', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_tecn : null
                                                })(<Select size="small" style={{ width: 200 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Visita Técnica:</b>&nbsp;
                                                {getFieldDecorator('visita_tecnica', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].visita_tecnica : null
                                                })(<Select size="small" style={{ width: 200 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p><b>Plazo de entrega:</b>&nbsp;
                                                {getFieldDecorator('plazo_entrega', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].plazo_entrega : null
                                                })(<InputNumber size='small' min={0} max={200} style={{ width: 55 }} disabled={opportDetail.length > 0 ? opportDetail[0].vendedor_id !== null ? false : formEnd2 : formEnd2} />)}
                                                &nbsp;&nbsp;días<span style={{fontWeight: 'bold'}}>*</span><br/>
                                                <p>*El límite de días a entregar es de 200</p>
                                            </p>
                                            <Form.Item style={{ marginLeft: '20%', paddingTop: '15px' }}>
                                                <Button type="secondary" onClick={this.handleCancelAnexo}>
                                                    Cancelar
                                                </Button>
                                                <Divider type="vertical" />
                                                <Button icon={"true"} loading={load} htmlType="submit"
                                                        style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    :
                                    <Col lg={4}>
                                        <Form onSubmit={this.handleSubmitAnexos} style={{fontSize: 12}}>
                                            <p><b>Calificación:</b> <Rate style={{fontSize: 12}} count={3} disabled value={opportDetail[0].valor_calificacion} /></p>
                                            <p style={{marginBottom: 5}}><b>Ejecutivo:</b> {this.handleNameSales(opportDetail[0].vendedor_id)}</p>
                                            <p style={{marginBottom: 5}}><b>Situación:</b>&nbsp;
                                                {getFieldDecorator('estado_situacion', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length > 0 ? opportDetail[0].estado_situacion : "A"
                                                })(<Select size="small" defaultValue="A" style={{ width: 200 }}>
                                                        {this.handleSelectStatusSit()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Garantía Ofertada:</b>&nbsp;
                                                {getFieldDecorator('garantia_oferta', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].garantia_oferta : null
                                                })(<Select size="small" style={{ width: 200 }}>
                                                        {this.handleSelectOfertWarranty()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Administrativos:</b>&nbsp;
                                                {getFieldDecorator('anexos_admin', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_admin : null
                                                })(<Select size="small" style={{ width: 180 }}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Económicos:</b>&nbsp;
                                                {getFieldDecorator('anexos_econom', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_econom : null
                                                })(<Select size="small" style={{ width: 200 }}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Anexos Técnicos:</b>&nbsp;
                                                {getFieldDecorator('anexos_tecn', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].anexos_tecn : null
                                                })(<Select size="small" style={{ width: 200 }}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p style={{marginBottom: 5}}><b>Visita Técnica:</b>&nbsp;
                                                {getFieldDecorator('visita_tecnica', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].visita_tecnica : null
                                                })(<Select size="small" style={{ width: 200 }}>
                                                        {this.handleSelectAnexos()}
                                                    </Select>
                                                )}
                                            </p>
                                            <p><b>Plazo de entrega:</b>&nbsp;
                                                {getFieldDecorator('plazo_entrega', {
                                                    validateTrigger: ["onChange", "onBlur"],
                                                    initialValue: opportDetail.length ? opportDetail[0].plazo_entrega : null
                                                })(<InputNumber size='small' min={0} max={200} style={{ width: 55 }} />)}
                                                &nbsp;&nbsp;días<span style={{fontWeight: 'bold'}}>*</span><br/>
                                                <p>*El límite de días a entregar es de 200</p>
                                            </p>
                                            <Form.Item style={{ marginLeft: '20%', paddingTop: '15px' }}>
                                                <Button type="secondary" onClick={this.handleCancelAnexo}>
                                                    Cancelar
                                                </Button>
                                                <Divider type="vertical" />
                                                <Button icon={"true"} loading={load} htmlType="submit"
                                                        style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>)}
                        </UserContext.Consumer>
                        :
                        opportDetail.length > 0 ?
                            <Col lg={4}>
                                <p><b>Calificación:</b> <Rate style={{fontSize: 12}} count={3} disabled value={opportDetail[0].valor_calificacion} /></p>
                                <p><b>Ejecutivo:</b> {this.handleNameSales(opportDetail[0].vendedor_id)}</p>
                                <p><b>Situación:</b> {this.handleRenderStatusSit(opportDetail[0].estado_situacion)}</p>
                                <p><b>Garantía Ofertada:</b> {this.handleRenderOfertWarranty(opportDetail[0].garantia_oferta)}</p>
                                <p><b>Anexos Administrativos:</b> {this.handleRenderAnexos(opportDetail[0].anexos_admin)}</p>
                                <p><b>Anexos Económicos:</b> {this.handleRenderAnexos(opportDetail[0].anexos_econom)}</p>
                                <p><b>Anexos Técnicos:</b> {this.handleRenderAnexos(opportDetail[0].anexos_tecn)}</p>
                                <p><b>Visita Técnica:</b> {this.handleRenderAnexos(opportDetail[0].visita_tecnica)}</p>
                                <p><b>Plazo de entrega:</b> {opportDetail[0].plazo_entrega > 0 ? `${opportDetail[0].plazo_entrega} días` : ''}</p>
                            </Col>
                            :
                            <Col lg={4}>
                                <p><b>Calificación:</b> <Rate style={{fontSize: 12}} count={3} disabled value={0} /></p>
                                <p><b>Ejecutivo:</b> </p>
                                <p><b>Situación:</b> </p>
                                <p><b>Garantía Ofertada:</b> </p>
                                <p><b>Anexos Administrativos:</b> </p>
                                <p><b>Anexos Económicos:</b> </p>
                                <p><b>Anexos Técnicos:</b> </p>
                                <p><b>Visita Técnica:</b> </p>
                                <p><b>Plazo de entrega:</b> </p>
                            </Col>
                    }
                </Row>
            </div>
        );
    }

    detailComprador = () => {
        let comprador;
        const { getFieldDecorator } = this.props.form;
        const { opportDetail, load } = this.state;

        comprador = this.props.detail.map(data1 => {
            return data1.detail.map(data2 => {
                return (
                    <Modal
                        footer={null}
                        // title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Detalle de Licitación Nº {this.props.code}</div>}
                        title=
                            {
                                <div>
                                    <Rantd>
                                        <Cantd span={12}>
                                            <div style={{textAlign: "left"}}>
                                                Detalle de Licitación Nº: {this.props.code}
                                            </div>
                                        </Cantd>
                                        <Cantd span={12}>
                                            <div style={{textAlign: "right", marginRight: "2rem",}}>
                                                Fecha de Cierre: {moment(data2.fechacierre).format("DD/MM/YYYY h:mm:ss a")}
                                            </div>
                                            <div style={{textAlign: "right", marginRight: "2rem",}}>
                                                Plazo para el Cierre: <Countdown futureDate={data2.fechacierre}/>
                                            </div>
                                        </Cantd>
                                    </Rantd>
                                </div>
                            }
                        visible={this.props.visible}
                        width={'1200px'}
                        onCancel={() => {this.state.handleModal(false); this.handleEditFormAnexo(false)}}
                    >
                        {this.props.detail.length === 0 ?
                            <Empty style={{margin: "10px 8px", fontSize: "18px"}} description="Por favor, escoger una licitación de su interés en la tabla. Cierre el modal" />
                            :
                            <div>
                                <UserContext.Consumer>
                                    {(user) => (
                                        user.type === 'B' ?
                                            <div>
                                                <Button onClick={() => {
                                                            this.handleBtnReportInd('ReportSectiona00189ea5ad5b7dceae0');
                                                            this.state.handleModal(false);
                                                        }}
                                                    type="warning"
                                                    shape="circle"
                                                    icon="line-chart"
                                                    size={'large'}
                                                    hidden={this.state.editFormAnexo}
                                                    style={{
                                                        // marginRight: "10rem",
                                                        backgroundColor: 'white',
                                                        color: '#70bbfd',
                                                        borderColor: 'white',
                                                        float: 'right'
                                                    }}
                                                />
                                                <Button onClick={() => this.handleEditFormAnexo(true)}
                                                        type="warning"
                                                        shape="circle"
                                                        icon="edit"
                                                        size={'large'}
                                                        hidden={this.state.editFormAnexo}
                                                        style={{
                                                            // marginRight: "10rem",
                                                            backgroundColor: 'white',
                                                            color: '#70bbfd',
                                                            borderColor: 'white',
                                                            float: 'right'
                                                        }}
                                                />
                                            </div>
                                            :
                                            // <div>
                                            //     <Button
                                            //         onClick={() => {
                                            //             this.handleBtnReportInd('ReportSection4734f07e284e2c1cb523');
                                            //             this.state.handleModal(false);
                                            //         }}
                                            //         type="warning"
                                            //         shape="circle"
                                            //         icon="line-chart"
                                            //         size={'large'}
                                            //         hidden={this.state.editFormAnexo}
                                            //         style={{
                                            //             // marginRight: "10rem",
                                            //             backgroundColor: 'white',
                                            //             color: '#70bbfd',
                                            //             borderColor: 'white',
                                            //             float: 'right'
                                            //         }}
                                            //     />
                                            opportDetail.length > 0 ?
                                                (user.id === opportDetail[0].vendedor_id ?
                                                    <Button onClick={() => this.handleEditFormAnexo(true)}
                                                            type="warning"
                                                            shape="circle"
                                                            icon="edit"
                                                            size={'large'}
                                                            hidden={this.state.editFormAnexo}
                                                            style={{
                                                                backgroundColor: 'white',
                                                                color: '#70bbfd',
                                                                borderColor: 'white',
                                                                float: 'right'
                                                            }}
                                                    />
                                                    : '')
                                                : ''
                                            // </div>
                                    )}
                                </UserContext.Consumer>
                                {this.informationLic(data2.comprador, data2.region, data2.fechapublicacion,
                                    data2.fechacierre, data2.fechaadjudicacion, data2.tipolic, data2.fuentefinanciamiento,
                                    data2.duracioncontrato, data2.nombreresponsablecontrato, data2.fonoresponsablecontrato,
                                    data2.emailresponsablecontrato, data2.nombreresponsablepago, data2.emailresponsablepago,
                                    data2.montoestimado, data2.moneda)}
                                <Tabs type="card" style={{marginTop: 10}} size="small" activeKey={this.state.activeTab} onChange={this.changeTab}>
                                    <TabPane tab="Items Solicitados" key="1">
                                        {this.detailItems()}
                                    </TabPane>
                                    <TabPane tab="Anexos Administrativos" key="2" disabled={opportDetail.length > 0 ? opportDetail[0].anexos_admin == 1 ? false : true : true}>
                                        <UserContext.Consumer>
                                            {(user) => (
                                                opportDetail.length > 0 ?
                                                    user.type === 'B' || user.id === opportDetail[0].vendedor_id ?
                                                        <Button onClick={() => this.handleEditFormAdmin(true)}
                                                                type="warning"
                                                                shape="circle"
                                                                icon="edit"
                                                                size={'large'}
                                                                hidden={this.state.editFormAdmin}
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    color: '#70bbfd',
                                                                    borderColor: 'white',
                                                                    float: 'right',
                                                                    marginRight: 400
                                                                }}
                                                        /> : ''
                                                    : ''
                                            )}
                                        </UserContext.Consumer>
                                        {this.state.editFormAdmin ?
                                            <Form onSubmit={this.handleSubmitAdmin} style={{padding: "0 200px 0 86px"}}>
                                                <p style={{marginBottom: 5}}><b>Estatus:</b>&nbsp;
                                                    {getFieldDecorator('situacion_admin', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].situacion_admin ? opportDetail[0].situacion_admin : []
                                                    })(<Select size="small" style={{ width: 200 }}>
                                                            {this.handleSelectSituacionAnexos()}
                                                        </Select>
                                                    )}
                                                </p>
                                                <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                    {getFieldDecorator('nota_admin', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].nota_admin ? opportDetail[0].nota_admin : null
                                                    })(<TextArea rows={3} />)}
                                                </p>
                                                <Form.Item style={{ marginLeft: '75%' }}>
                                                    <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.handleEditFormAdmin(false) }}>
                                                        Cancelar
                                                    </Button>
                                                    <Divider type="vertical" />
                                                    <Button icon={"true"} loading={load} htmlType="submit"
                                                            style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                        Guardar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                            :
                                            opportDetail.length > 0 ?
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> {opportDetail[0].situacion_admin ? this.handleRenderSituacionAnexos(opportDetail[0].situacion_admin) : "No hay Información"}</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        {opportDetail[0].nota_admin ? opportDetail[0].nota_admin : "Sin notas"}
                                                    </p>
                                                </div>
                                                :
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> No hay Información</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        Sin notas
                                                    </p>
                                                </div>
                                        }
                                    </TabPane>
                                    <TabPane tab="Anexos Económicos" key="3" disabled={opportDetail.length > 0 ? opportDetail[0].anexos_econom == 1 ? false : true : true}>
                                        <UserContext.Consumer>
                                            {(user) => (
                                                opportDetail.length > 0 ?
                                                    user.type === 'B' || user.id === opportDetail[0].vendedor_id ?
                                                        <Button onClick={() => this.handleEditFormEconom(true)}
                                                                type="warning"
                                                                shape="circle"
                                                                icon="edit"
                                                                size={'large'}
                                                                hidden={this.state.editFormEconom}
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    color: '#70bbfd',
                                                                    borderColor: 'white',
                                                                    float: 'right',
                                                                    marginRight: 400
                                                                }}
                                                        /> : ''
                                                    : ''
                                            )}
                                        </UserContext.Consumer>
                                        {this.state.editFormEconom ?
                                            <Form onSubmit={this.handleSubmitEconom} style={{padding: "0 200px 0 86px"}}>
                                                <p style={{marginBottom: 5}}><b>Estatus:</b>&nbsp;
                                                    {getFieldDecorator('situacion_econom', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].situacion_econom ? opportDetail[0].situacion_econom : []
                                                    })(<Select size="small" style={{ width: 200 }}>
                                                            {this.handleSelectSituacionAnexos()}
                                                        </Select>
                                                    )}
                                                </p>
                                                <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                    {getFieldDecorator('nota_econom', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].nota_econom ? opportDetail[0].nota_econom : null
                                                    })(<TextArea rows={3} />)}
                                                </p>
                                                <Form.Item style={{ marginLeft: '75%' }}>
                                                    <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.handleEditFormEconom(false) }}>
                                                        Cancelar
                                                    </Button>
                                                    <Divider type="vertical" />
                                                    <Button icon={"true"} loading={load} htmlType="submit"
                                                            style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                        Guardar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                            :
                                            opportDetail.length > 0 ?
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> {opportDetail[0].situacion_econom ? this.handleRenderSituacionAnexos(opportDetail[0].situacion_econom) : "No hay Información"}</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        {opportDetail[0].nota_econom ? opportDetail[0].nota_econom : "Sin notas"}
                                                    </p>
                                                </div>
                                                :
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> No hay Información</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        Sin notas
                                                    </p>
                                                </div>
                                        }
                                    </TabPane>
                                    <TabPane tab="Anexos Técnicos" key="4" disabled={opportDetail.length > 0 ? opportDetail[0].anexos_tecn == 1 ? false : true : true}>
                                        <UserContext.Consumer>
                                            {(user) => (
                                                opportDetail.length > 0 ?
                                                    user.type === 'B' || user.id === opportDetail[0].vendedor_id ?
                                                        <Button onClick={() => this.handleEditFormTecn(true)}
                                                                type="warning"
                                                                shape="circle"
                                                                icon="edit"
                                                                size={'large'}
                                                                hidden={this.state.editFormTecn}
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    color: '#70bbfd',
                                                                    borderColor: 'white',
                                                                    float: 'right',
                                                                    marginRight: 400
                                                                }}
                                                        /> : ''
                                                    : ''
                                            )}
                                        </UserContext.Consumer>
                                        {this.state.editFormTecn ?
                                            <Form onSubmit={this.handleSubmitTecn} style={{padding: "0 200px 0 86px"}}>
                                                <p style={{marginBottom: 5}}><b>Estatus:</b>&nbsp;
                                                    {getFieldDecorator('situacion_tecn', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].situacion_tecn ? opportDetail[0].situacion_tecn : []
                                                    })(<Select size="small" style={{ width: 200 }}>
                                                            {this.handleSelectSituacionAnexos()}
                                                        </Select>
                                                    )}
                                                </p>
                                                <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                    {getFieldDecorator('nota_tecn', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].nota_tecn ? opportDetail[0].nota_tecn : null
                                                    })(<TextArea rows={3} />)}
                                                </p>
                                                <Form.Item style={{ marginLeft: '75%' }}>
                                                    <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.handleEditFormTecn(false) }}>
                                                        Cancelar
                                                    </Button>
                                                    <Divider type="vertical" />
                                                    <Button icon={"true"} loading={load} htmlType="submit"
                                                            style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                        Guardar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                            :
                                            opportDetail.length > 0 ?
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> {opportDetail[0].situacion_tecn ? this.handleRenderSituacionAnexos(opportDetail[0].situacion_tecn) : "No hay Información"}</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        {opportDetail[0].nota_tecn ? opportDetail[0].nota_tecn : "Sin notas"}
                                                    </p>
                                                </div>
                                                :
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Estatus:</b> No hay Información</p>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        Sin notas
                                                    </p>
                                                </div>
                                        }
                                    </TabPane>
                                    <TabPane tab="Visita Técnica" key="5" disabled={opportDetail.length > 0 ? opportDetail[0].visita_tecnica == 1 ? false : true : true}>
                                        <UserContext.Consumer>
                                            {(user) => (
                                                opportDetail.length > 0 ?
                                                    user.type === 'B' || user.id === opportDetail[0].vendedor_id ?
                                                        <Button onClick={() => this.handleEditFormVisita(true)}
                                                                type="warning"
                                                                shape="circle"
                                                                icon="edit"
                                                                size={'large'}
                                                                hidden={this.state.editFormVisita}
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    color: '#70bbfd',
                                                                    borderColor: 'white',
                                                                    float: 'right',
                                                                    marginRight: 400
                                                                }}
                                                        /> : ''
                                                    : ''
                                            )}
                                        </UserContext.Consumer>
                                        {this.state.editFormVisita ?
                                            <Form onSubmit={this.handleSubmitVisita} style={{padding: "0 200px 0 86px"}}>
                                                <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                    {getFieldDecorator('nota_visita', {
                                                        validateTrigger: ["onChange", "onBlur"],
                                                        initialValue: opportDetail[0].nota_visita ? opportDetail[0].nota_visita : null
                                                    })(<TextArea rows={3} />)}
                                                </p>
                                                <Form.Item style={{ marginLeft: '75%' }}>
                                                    <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.handleEditFormVisita(false) }}>
                                                        Cancelar
                                                    </Button>
                                                    <Divider type="vertical" />
                                                    <Button icon={"true"} loading={load} htmlType="submit"
                                                            style={{ width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff" }}>
                                                        Guardar
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                            :
                                            opportDetail.length > 0 ?
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        {opportDetail[0].nota_visita ? opportDetail[0].nota_visita : "Sin notas"}
                                                    </p>
                                                </div>
                                                :
                                                <div style={{padding: "0 200px 0 86px"}}>
                                                    <p style={{marginBottom: 5}}><b>Notas:</b><br />
                                                        Sin notas
                                                    </p>
                                                </div>
                                        }
                                    </TabPane>
                                </Tabs>
                            </div>
                        }
                    </Modal>
                )
            });
        });

        return comprador;
    }

    detailItems = () => {
        let items;

        items = this.props.detail.map(data1 => {
            return (<Table dataSource={data1.items} scroll={{ y: 165 }} rowClassName="fontSize__table" size="small"
                           pagination={false} footer={() => `${data1.items.length} items en total`}>
                        <Column title="Producto" dataIndex="producto" key="producto" />
                        <Column title="Descripcion" dataIndex="descripcion" key="descripcion" width="40%" />
                        <Column title="Cantidad" dataIndex="cantidad" key="cantidad" width="10%" />
                        <Column title="Medida" dataIndex="unidadmedida" key="unidadmedida" width="10%" />
                        <Column title="URL" dataIndex="url" key="url" width="5%" render={(text, record) => (
                            <a href={record.url} target="_blank">
                                <Icon type="link" style={{ fontSize: '18px', color:'#3879b1'}} />
                            </a>
                        )} />
                    </Table>)
        });

        return items;
    }

    showTotal = (total, range) => {
        return `${total} items`
    }

    changeTab = activeKey => {
        this.setState({
            activeTab: activeKey
        });
    };

    handleSubmitAnexos = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                OpportunityManagerService.ins_updOpportunity(data, this.props.code)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', '¡Listo!', 'Se guardaron correctamente los cambios');
                            this.handleEditFormAnexo(false);
                            this.changeTab("1");
                            this.props.form.resetFields();
                            this.setState({load: false});
                            OpportunityManagerService.opportunityDetail(this.props.code)
                                .then(resp => {
                                    this.setState({
                                        opportDetail: resp.data,
                                        starValue: 0
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({opportDetail: []});
                                });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 406) {
                            this.setState({
                                load: false,
                            });
                            notificationWithIcon('error', 'Error', error.response.data);
                        } else {
                            this.setState({
                                load: false
                            });
                            notificationWithIcon('error', 'Error', 'Hubo un error al enviar los datos');
                        }
                    })
            }
        });
        // setTimeout(() => this.setState({ load: false }), 12000);
    };

    handleSubmitAdmin = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                OpportunityManagerService.anexos_admin(data, this.props.code)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', '¡Listo!', 'Se guardaron correctamente los cambios');
                            this.handleEditFormAdmin(false);
                            this.props.form.resetFields();
                            OpportunityManagerService.opportunityDetail(this.props.code)
                                .then(resp => {
                                    this.setState({
                                        opportDetail: resp.data,
                                        starValue: 0
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({opportDetail: []});
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            load: false
                        });
                        notificationWithIcon('error', 'Error', 'Hubo un error al enviar los datos');
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    handleSubmitEconom = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                OpportunityManagerService.anexos_econom(data, this.props.code)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', '¡Listo!', 'Se guardaron correctamente los cambios');
                            this.handleEditFormEconom(false);
                            this.props.form.resetFields();
                            OpportunityManagerService.opportunityDetail(this.props.code)
                                .then(resp => {
                                    this.setState({
                                        opportDetail: resp.data,
                                        starValue: 0
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({opportDetail: []});
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            load: false
                        });
                        notificationWithIcon('error', 'Error', 'Hubo un error al enviar los datos');
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    handleSubmitTecn = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                OpportunityManagerService.anexos_tecn(data, this.props.code)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', '¡Listo!', 'Se guardaron correctamente los cambios');
                            this.handleEditFormTecn(false);
                            this.props.form.resetFields();
                            OpportunityManagerService.opportunityDetail(this.props.code)
                                .then(resp => {
                                    this.setState({
                                        opportDetail: resp.data,
                                        starValue: 0
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({opportDetail: []});
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            load: false
                        });
                        notificationWithIcon('error', 'Error', 'Hubo un error al enviar los datos');
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    handleSubmitVisita = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (!err) {
                this.setState({
                    load: true
                });
                OpportunityManagerService.visit_tecn(data, this.props.code)
                    .then(response => {
                        if (response.status === 200) {
                            notificationWithIcon('success', '¡Listo!', 'Se guardaron correctamente los cambios');
                            this.handleEditFormVisita(false);
                            this.props.form.resetFields();
                            OpportunityManagerService.opportunityDetail(this.props.code)
                                .then(resp => {
                                    this.setState({
                                        opportDetail: resp.data,
                                        starValue: 0
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({opportDetail: []});
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            load: false
                        });
                        notificationWithIcon('error', 'Error', 'Hubo un error al enviar los datos');
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    render() {

        return (
            <div>
                {this.detailComprador()}
            </div>
        )
    }

}

const WrappedOpportunityForm = Form.create()(OportunidadLic);

export default WrappedOpportunityForm;
