/*eslint-disable */
//API NUEVO
// export const REACT_APP_EMBED_GROUP_ID = '69055c02-6ad9-4e2d-9a7c-4b2d9d5ba8b3';
// export const REACT_APP_EMBED_REPORT_ID = '6300f419-1033-4d72-a55e-723f417a3f2b';
// export const REACT_APP_EMBED_DATASET_ID = '3de16f22-b4e4-4e00-8d77-2695b2a50b0b';

//Mercado BI Chile SpA Prod
export const REACT_APP_EMBED_GROUP_ID = '94efbdcb-c445-44c9-aea6-ced077cadd5f';
export const REACT_APP_EMBED_REPORT_ID = '7c74ddc4-d0c1-4171-9d40-023b7623bfcb';
export const REACT_APP_EMBED_DATASET_ID = 'b657969e-7795-4641-8670-bcb35e71829e';

//Mercado BI Chile SpA DEV
// export const REACT_APP_EMBED_GROUP_ID = '91be367b-a11c-4513-87c8-6315a87350ad';
// export const REACT_APP_EMBED_REPORT_ID = 'b833e506-4f2e-4c8f-803a-ddecf48f6992';
// export const REACT_APP_EMBED_DATASET_ID = 'ce762fa6-0d1a-4cfe-bb9e-8e32a9aef035';

