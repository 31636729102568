/*eslint-disable */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import UserContext from '../../../store/userContext';
import TopbarNotification from './TopbarNotification';
import Countdown from '../../Dashboard/components/Countdown';
import moment from 'moment';


class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };


  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
            <UserContext.Consumer>
                {(user) => (
                  user.type === 'B' && user.block_access ?
                    <div style={{left: "60px"}} className="topbar__left">
                      <Link className="topbar__logo" to="/dashboard/pages/webpay"/>
                    </div>
                    :
                  user.type === 'A' || user.type === 'B' || user.type === 'D' || user.type === 'E' || user.type === 'F' ?
                    <div className="topbar__left">
                    <TopbarSidebarButton
                      changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                      changeSidebarVisibility={changeSidebarVisibility}
                    />
                    <Link className="topbar__logo" to="/dashboard/pages/dashboards"/>
                    </div>
                  :
                    <div style={{left: "60px"}} className="topbar__left">
                      <Link className="topbar__logo" to="/dashboard/pages/dashboards"/>
                    </div>
                )}
            </UserContext.Consumer>
            
          <div className="topbar__right">
            <UserContext.Consumer>
              {(user) => (
                user.trial_user ? 
                  <span style={{padding : "0 20px", backgroundColor: "yellow"}}>
                    Le quedan: <br/><Countdown futureDate={moment(user.timestamp).add(14, 'days')}/>
                  </span>
                :
                ""
              )}
            </UserContext.Consumer>
            <UserContext.Consumer>
              {(user) => (
                user.block_access === false && !user.trial_user ?
                  <TopbarNotification {...this.state}/>
                :
                ''
              )}
            </UserContext.Consumer>
            <TopbarProfile/>
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
