/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Inbox from './components/Inbox';

const Mail = () => (
  <Container className="dashboard">
    <Row>
     <Inbox/>
    </Row>
  </Container>
);

// Mail.propTypes = {
//   t: PropTypes.func.isRequired,
// };

export default Mail;
