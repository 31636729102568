/* eslint-disable */
import React, { PureComponent } from 'react';
import BellIcon from 'mdi-react/BellIcon';
import MailBox from './MailBox';
import InboxTable from './InboxTable';
import NotificationsService from '../../../services/notifications/NotificationsService';
import { Tag } from 'antd';
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";


export default class Inbox extends PureComponent {
  constructor() {
    super();
    this.state = {
      mailbox: 0,
      label: 0,
      openMailboxes: false,
      emails: []
    };
  }

  componentDidMount() {
    spinnerService.show('mySpinner');
    NotificationsService.notificationService()
      .then(response => {
        spinnerService.hide('mySpinner');
        this.setState({
          emails: response.data
        });
      })
      .catch(error => {
        console.log(error);
        spinnerService.hide('mySpinner');
        this.setState({
          emails: []
        });
      })
  }

  onMailBox = (mailbox, e) => {
    e.preventDefault();
    this.setState({
      mailbox: true,
    });
  };

  onLabel = (label, e) => {
    e.preventDefault();
    this.setState({
      label: false,
    });
  };

  filterNotifications = (type) => {
    if (type === 1) {
      return <span>
        <Tag>Licitación</Tag>
      </span>
    }
    else if (type === 2) {
      return <span>
        <Tag color={'yellow'} style={{ color: 'gray' }}>
          Orden de Compra
        </Tag>
      </span>
    }
    else if (type === 3) {
      return <span>
        <Tag color={'orange'}>
          Adjudicación
        </Tag>
      </span>
    }
    else if (type === 4) {
      return <span>
        <Tag color={'#00c1cf'} style={{ color: 'gray' }}>
          Compra Ágil
        </Tag>
      </span>
    }
  };

  onOpenMailboxes = () => {
    this.setState(prevState => ({ openMailboxes: !prevState.openMailboxes }));
  };

  renderEmails = (id) => {
    let notifDetail;
    notifDetail = this.state.emails.map(data => {
      let typeNot;
      typeNot = this.filterNotifications(data.notification__type);
      if (data.id === id) {
        return {
          name: typeNot,
          time: data.notification__noti_date,
          body:
            <div>
              {data.notification__msg}
            </div>
        }
      }
    });
    return notifDetail.slice(0, 1)[0];
  };

  renderTotalNotif = () => {
    const membersToRender = this.state.emails.map(model => model.viewed);
    const numRows = membersToRender.filter(function(s) { return s == false; }).length

    return numRows;
  }

  renderNotifications = () => {
    const numRowsLic1 = this.state.emails.filter((s) => s.viewed == false && s.notification__type == 1).length;
    const numRowsLic2 = this.state.emails.filter((s) => s.notification__type == 1).length;
    const numRowsAdj1 = this.state.emails.filter((s) => s.viewed == false && s.notification__type == 3).length;
    const numRowsAdj2 = this.state.emails.filter((s) => s.notification__type == 3).length;
    const numRowsOC1 = this.state.emails.filter((s) => s.viewed == false && s.notification__type == 2).length;
    const numRowsOC2 = this.state.emails.filter((s) => s.notification__type == 2).length;
    const numRowsCA1 = this.state.emails.filter((s) => s.viewed == false && s.notification__type == 4).length;
    const numRowsCA2 = this.state.emails.filter((s) => s.notification__type == 4).length;
    return (<div style={{fontSize: "11px"}}>
      <button
        type="button"
        key={1}
        className="inbox__list-button inbox__label"
      >
        <span className={'inbox__label-color inbox__label-color--blue'} />Licitación ({numRowsLic1 > 0 ? <b>{numRowsLic1}</b> : numRowsLic1}&nbsp;/ {numRowsLic2})
      </button>
      <button
        type="button"
        key={2}
        className="inbox__list-button inbox__label"
      >
        <span className={'inbox__label-color inbox__label-color--yellow'} />Orden de Compra ({numRowsOC1 > 0 ? <b>{numRowsOC1}</b> : numRowsOC1}&nbsp;/ {numRowsOC2})
      </button>
      <button
        type="button"
        key={3}
        className="inbox__list-button inbox__label"
      >
        <span className={'inbox__label-color inbox__label-color--green'} />Adjudicación ({numRowsAdj1 > 0 ? <b>{numRowsAdj1}</b> : numRowsAdj1}&nbsp;/ {numRowsAdj2})
      </button>
      <button
        type="button"
        key={4}
        className="inbox__list-button inbox__label"
      >
        <span className={'inbox__label-color inbox__label-color--greenEsp'} />Compra Ágil ({numRowsCA1 > 0 ? <b>{numRowsCA1}</b> : numRowsCA1}&nbsp;/ {numRowsCA2})
      </button>
    </div>);
  };

  renderListNotif = () => {
    this.componentDidMount();
    this.renderNotifications();
    this.renderTotalNotif();
  }


  render() {
    const {
      openMailboxes, emails
    } = this.state;

    return (
      <div
        className={`inbox${openMailboxes ? ' inbox--show-mailboxes' : ''}`}
        onClick={openMailboxes ? this.onOpenMailboxes : null}
        role="presentation"
      >
        <div className="inbox__mailbox-list">
          <button type="button" className="inbox__list-button">
            <MailBox title="Notificaciones" amount={this.renderTotalNotif()} >
              <BellIcon />
            </MailBox>
          </button>
          <p className="inbox__labels">Clasificación</p>
          {this.renderNotifications()}
        </div>
        <div className="inbox__container">
          <div>
            <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>
            <InboxTable emails={emails} numNotif={this.renderListNotif} />
          </div>
        </div>
      </div>
    );
  }
}
