/*eslint-disable */
import * as actionTypes from './actionTypes';
import axios from 'axios';
import AuthServices from '../../services/auth/AuthService.js';
import 'antd/dist/antd.css';
import React from 'react';
import history from '../history';
import { spinnerService } from "@chevtek/react-spinners";
import notificationWithIcon from '../../containers/UI/Notification/components/ColorStates'
//import { browserHistory } from 'react-router'


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user,
    }
};


export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {

    if (localStorage.getItem('token') !== null) {
        AuthServices.deleteSession(localStorage.getItem('id_login'))
            .then(() => {
                localStorage.removeItem('id_login');
                localStorage.removeItem('token_session');
            })
            .catch(er => {
                localStorage.removeItem('urlSave');
                console.log(er);
            });
            // message.success('Sesión finalizada'); 
        localStorage.removeItem('urlSave');
        localStorage.removeItem('uuss');
        notificationWithIcon('info', 'Sesión finalizada', 'La sesión finalizó correctamente');
    }

    axios.defaults.headers.common['Authorization'] = null;
    clearTimeout(timerBearer);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('expiration');
    localStorage.removeItem('token_session');
    localStorage.removeItem('id_login');
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
    history.push('/');
};



export const checkAuthTimeOut = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 3000)
    }
};


export const resetPassword = email => {
    return dispatch => {
        AuthServices.resetPassword(email)
            .then(response => {
                notificationWithIcon('success', 'Correo Enviado', 'Se envió un mensaje a su correo electrónico. Siga las instrucciones del mensaje para restablecer su contraseña');

            })
            .catch(error => {
                if (error.response.status === 500 || error.response.status === 400) {
                    notificationWithIcon('error', '¡Error!', 'No se pudo completar la peticion');
                }
                else {
                    notificationWithIcon('success', 'Correo Enviado', 'Se envió un mensaje a su correo electrónico. Siga las instrucciones del mensaje para restablecer su contraseña');
                }

            })
    }
};

export const confirmPassword = (pass1, pass2) => {
    return dispatch => {
        var url = document.location.href;
        var splitUrl = url.split('/');
        AuthServices.confirmPassword(pass1, pass2, splitUrl[6], splitUrl[7])
            .then(response => {
                notificationWithIcon('success', 'Completado', "Se Cambio la contraseña");
                history.push('/');
            })
            .catch(error => {

                notificationWithIcon('error', 'Error', 'No se pudo completar la peticion');
            })
    }
};


export const authLogin = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        // aqui iba getPbiCredentials()
        AuthServices.userLogin(email, password)
            .then(response => {
                const token = response.data.key;
                const expirationDate = new Date(new Date().getTime() + 3600 * 3000);
                axios.defaults.headers.common['Authorization'] = 'Token ' + token;
                localStorage.setItem('token', token);
                localStorage.setItem('expirationDate', expirationDate);
                
                AuthServices.getUserFromSession()
                    .then(response => {
                        const user = response.data;
                        if (user.type === 'B' && user.block_access === true) {
                            clearTimeout(timerBearer);
                            localStorage.removeItem('bearerToken');
                            localStorage.removeItem('expiration');
                            localStorage.setItem('user', user);
                            dispatch(authSuccess(token, user));
                            dispatch(checkAuthTimeOut(300));
                            notificationWithIcon('success', 'Bienvenido', user.name + " " + user.lastname);
                            history.push('dashboard/pages/webpay/');
                        } else {
                            localStorage.setItem('user', user);
                            dispatch(authSuccess(token, user));
                            dispatch(checkAuthTimeOut(3600));
                            //message.success('Bienvenido '+ user.name);
                            notificationWithIcon('success', 'Bienvenido', user.name + " " + user.lastname);
                            if(localStorage.getItem("urlSave")){
                                history.push(localStorage.getItem("urlSave"));
                                localStorage.removeItem('urlSave');
                            } else {
                                localStorage.removeItem('urlSave');
                                history.push('dashboard/pages/dashboards/');
                            }
                        }
                    })
                    .catch( error => {
                        let msg = "Error";
                        if (error.response.status === 401 || error.response.status === 400) {
                            notificationWithIcon('error', '¡Error!', 'Credenciales incorrecta o usuario bloqueado');
                            //msg = "Error en las credenciales"
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('expirationDate');
                            localStorage.removeItem('bearerToken');
                            localStorage.removeItem('expiration');
                            localStorage.removeItem('token_session');
                            localStorage.removeItem('id_login');
                            localStorage.removeItem('urlSave');
                            clearTimeout(timerBearer);
                            notificationWithIcon('error', '¡Inicio de sesión múltiple!', 'Usted ya cuenta con una sesión abierta en otro navegador');
                        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                        dispatch(authFail(msg));
                    });
            })
            .catch(error => {
                let msg = "Error";
                if (error.response !== undefined) {

                    if (error.response.status === 401 || error.response.status === 400) {
                      notificationWithIcon('error', '¡Error!', error.response.data.detail);
                    }

                    else {
                        notificationWithIcon('error', '¡Error!', 'No se pudo completar la peticion');
                    }


                } else {
                    notificationWithIcon('error', '¡Error!', 'No se pudo completar la peticion');
                }

                dispatch(authFail(msg));
            })
    }
};

export const authRegister = (data) => {
    return dispatch => {
        return AuthServices.registerUser(data)
    }
};

export const timerBearer = () => {
    const expirationBearer = (localStorage.getItem('expiration') * 1000);
    const timerWarn = expirationBearer - 3000;

    // const hola = new Date(new Date().getTime() + localStorage.getItem('expiration') * 1000);

    if (expirationBearer > 0) {
        setTimeout(() => {
            spinnerService.show('mySpinner');
            notificationWithIcon('warning', '¡Aviso!', 'La página se actualizará en 3 segundos');
        }, timerWarn);
        
        setTimeout(() => {
            AuthServices.getPbiCredentials()
                .then(resp => {
                    spinnerService.hide('mySpinner');
                    const bearerToken = resp.data.bearer_token;
                    const expiration = resp.data.expiration;
                    localStorage.setItem('bearerToken', bearerToken);
                    localStorage.setItem('expiration', expiration);
                })
                .catch(error => {
                    if (error.code === "TokenExpired") {
                        window.location.reload();
                    }
                });
            localStorage.removeItem('expiration');
            localStorage.removeItem('bearerToken');
            window.location.reload();
        }, expirationBearer);
    } else {
        clearTimeout(timerBearer);
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const bearerToken = localStorage.getItem('bearerToken');
        const token_session = localStorage.getItem('token_session');
        const id_login = localStorage.getItem('id_login')
        const user = {};

        timerBearer();

        if (token === undefined || bearerToken === undefined || token_session === undefined) {
             AuthServices.deleteSession(id_login)
                .then(() => {
                    localStorage.removeItem('id_login');
                    localStorage.removeItem('token_session');
                })
                .catch(er => {
                    console.log(er);
                });
            // message.success('Sesión finalizada'); 
            dispatch(logout());
            clearTimeout(timerBearer);
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                AuthServices.deleteSession(id_login)
                    .then(() => {
                        localStorage.removeItem('id_login');
                        localStorage.removeItem('token_session');
                    })
                    .catch(er => {
                        console.log(er);
                    });
                dispatch(logout());
                clearTimeout(timerBearer);
            }
            else {
                axios.defaults.headers.common['Authorization'] = 'Token ' + token;
                // AuthServices.getLoggedInfo()
                //     .then(res => {
                //         localStorage.setItem("token_session", res.data[0].session_key);
                //         localStorage.setItem("id_login", res.data[0].id);

                //         // if (token_session !== res.data[0].session_key){
                //         //     notificationWithIcon('error', '¡Error!', 'Ya tiene una sesión abierta en otro dispositivo');
                //         //     dispatch(logout());
                //         // }
                //     })
                //     .catch(err => {
                //         console.log(err);
                //         dispatch(authFail(err));
                //         dispatch(logout());
                //     });
                AuthServices.getUserFromSession()
                    .then(response => {
                        const user = response.data;
                        localStorage.setItem('user', user);
                        dispatch(authSuccess(token, user));
                        dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
                        if (user.type === 'B' && user.block_access === true) {
                            clearTimeout(timerBearer);
                        } else {
                            AuthServices.getPbiCredentials()
                                .then(resp => {
                                    const bearerToken = resp.data.bearer_token;
                                    const expiration = resp.data.expiration;
                                    localStorage.setItem('bearerToken', bearerToken);
                                    localStorage.setItem('expiration', expiration);
                                    // dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime())/1000));
                                })
                                .catch(error => {
                                    // diferencia en test con error 500
                                    if (error.response.status === 403 || error.response.status === 500) {
                                        notificationWithIcon('error', '¡Error!', 'Su cuenta aún se encuentra en revisión. Contacte con su Administrador');
                                        dispatch(authFail(error));
                                        dispatch(logout());
                                    }
                                });
                        }
                    })
                    .catch(error => {
                        dispatch(authFail(error));
                    })
            }
        }
    }
};
