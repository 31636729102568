/*eslint-disable */
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Modal} from "antd";
import { Icon } from 'antd';


class UnblockAccessClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleUnblockAccessModal: this.props.handleUnblockAccessModal,
            reloadList: this.props.reloadList,
            load: false,
        };

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(() => {
            const values = {
                is_blocked: false
            };
            this.setState({
                load: true,
            });
            CustomerService.blockAccessClient(values, this.props.customer.id)
                .then(response => {
                    if (response.status === 200) {
                        notificationWithIcon('success', 'Cliente dado de Alta', 'Se envió un correo a todos los usuarios asociados para el inicio de sesión');
                        this.state.handleUnblockAccessModal(false);
                        this.state.reloadList();
                    }
                })
                .catch(error => {
                    this.setState({
                        load: false,
                    });
                    notificationWithIcon('error', 'Error', 'No se pudo dar de alta al cliente, inténtelo más tarde');
                })
        });
        setTimeout(() => this.setState({ load: false }), 6000);
    };

    render() {
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const users = this.props.customer.users !== undefined ? this.props.customer.users.length : undefined;

        return (
            <Modal
                footer={null}
                title={<div><Icon type="check-circle" style={{ fontSize: '35px', marginRight:'20px'}}  theme="twoTone" twoToneColor="#52c41a" />Desbloquear Cliente</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.customer)}
                onCancel={() => this.state.handleUnblockAccessModal(false)}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form.Item>
                    <div style={{ textAlign:'center' }}>
                        <h3>¿Está seguro de desbloquear al cliente <b>{this.props.customer.customer}</b>? </h3>
                        {users > 1 ?
                            <h4>(Desbloqueará a {users} usuarios)</h4>
                            :
                            <h4>(Desbloqueará a un usuario)</h4>
                        }
                    </div>
                </Form.Item>

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                    <Form.Item style={{marginLeft: '30%'}}>
                        <Button type="secondary" onClick={() => this.state.handleUnblockAccessModal(false)}>
                            Cancelar
                        </Button>
                        <Divider type="vertical"/>
                        <Button icon={"true"} loading={load} htmlType="submit"
                                style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                            Activar
                        </Button>
                    </Form.Item>

                </Form>


            </Modal>
        )
    }
}

const WrappedUnblockAccessClientForm = Form.create()(UnblockAccessClient);

export default WrappedUnblockAccessClientForm;