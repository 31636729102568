/*eslint-disable */
import React from 'react';
import {Button, Modal, Table, Icon} from "antd";
const { Column } = Table;
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import '../../../scss/detailNotifications.css';


class InfoCompraAgil extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            CADetail: [],
            codeDetail: null,
            visible: true,
            handleInfoCAModal: this.props.handleInfoCAModal,
            reloadList: this.props.reloadList,
            load: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.codeDetail !== prevProps.code) {
            this.setState({
                CADetail: this.props.detail,
                codeDetail: this.props.code
            });
        }
    }

    detailComprador = () => {
        let comprador;

        comprador = this.state.CADetail.map(data1 => {
            return data1.detail.map(data2 => {
                return (
                    <div style={{padding: "0 12px", fontSize: "12px"}}>
                        <Row>
                            <h4>{data2.descripcioncotizacion} COMPRADOR: {data2.nombreorganismo}</h4>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <p><b>Nro. de cotización:</b> {data2.numerocotizacion}</p>
                                <p><b>Fecha de Publicación:</b> {moment(data2.fechapublicacion).format("DD-MM-YYYY H:mm")}</p>
                                <p><b>Fecha de cierre:</b> {moment(data2.fechacierre).format("DD-MM-YYYY H:mm")}</p>
                                <p><b>Plazo de entrega:</b> {data2.plazoentrega}</p>
                            </Col>
                            <Col lg={6}>
                                <p><b>Contacto:</b> {data2.nombrecontacto}</p>
                                <p><b>Teléfono contacto:</b> {data2.telcontacto}</p>
                                <p><b>Correo contacto:</b> {data2.emailcontacto}</p>
                                <p><b>Monto estimado (CLP):</b> {data2.monto_total.toLocaleString("es-CL")}</p>
                            </Col>
                        </Row>
                    </div>				
                )
            });
        });

        return comprador;

    }

    detailItems = () => {
        let items;

        items = this.state.CADetail.map(data1 => {
            const org = data1.detail.map(data2 => {
                return data2.nombreorganismo;
            })
            return (<Table dataSource={data1.items} scroll={{ y: 200 }} rowClassName="fontSize__table" size="small" 
                           pagination={false} footer={() => `${data1.items.length} items en total`}
                           title={() => 'Items Solicitados'}>
                        <Column title="Producto" dataIndex="producto" key="producto" width="20%"/>
                        <Column title="Organismo Comprador" dataIndex="nombreorganismo" key="nombreorganismo" width="20%" render={() => org}/>
                        <Column title="Descripcion" dataIndex="producto_descripcion" key="producto_descripcion" width="40%" />
                        <Column title="Cantidad" dataIndex="cantidad" key="cantidad" width="10%" />
                        <Column title="Medida" dataIndex="medida" key="medida" width="10%" />
                    </Table>)
        });

        return items;

    }

    showTotal = (total, range) => {
        return `${total} items`
    }


    render() {

        return (
            <Modal
                title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Detalle de Compra Ágil Nº {this.props.code}</div>}
                visible={this.props.visible}
                width={'1200px'}
                onCancel={() => {this.state.handleInfoCAModal(false)}}
                footer={[<Button type="secondary" onClick={() => {this.state.handleInfoCAModal(false)}}>
                            Cerrar
                        </Button>]}
            >
                {this.state.CADetail.length == 0 || this.props.code !== this.state.codeDetail ? 
                    <div style={{textAlign: "center", margin: "10px 30px", paddingTop: "70px"}}>
                        <div style={{align: "center"}} className="panel__refresh"><LoadingIcon /></div>
                        Cargando información de Compra Ágil {this.props.code} ...
                    </div>
                : 
                <span>
                    {this.detailComprador()}
                    <br />
                    <Row>
                        {this.detailItems()}
                    </Row>
                </span>
                }
            </Modal>
        )
    }
}

export default InfoCompraAgil;
