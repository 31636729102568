/*eslint-disable */
import React, {PureComponent} from 'react';
import {Report} from 'powerbi-report-component';
import {Tooltip, Divider} from 'antd';
import {Button as ButtonIcons, Row} from 'reactstrap';
import ReportService from '../../../services/dashboard/ReportService';
import * as pbiEnv from '../pbi_env';
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";
import {Menu, Icon} from 'antd';
import notificationWithIcon from '../../../containers/UI/Notification/components/ColorStates';
import OportunidadLic from './OportunidadLic';
import NotificationsService from '../../../services/notifications/NotificationsService';
import OportunidadCa from "./OportunidadCa";
import UserContext from "../../../store/userContext";
import AuthServices from "../../../services/auth/AuthService";
import OpportunityManagerService from '../../../services/opportunity/OpportunityManagerService';

let uuss;
//Power BI report Embbeded class
class Dash_CurrentUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuP: [],
      current: "item_0",
      code_chance: null,
      myUser: [],
      op_data: [],
      licDetail: [],
      opportunityDetail: [],
      caDetail: [],
      pageNameOp: null
    };
    this.report = null;
  }

  handleModalLic = (value) => {
    this.setState({
      modalVisibleLic: value,
    });
  };

  handleModalCA = (value) => {
    this.setState({
      modalVisibleCA: value,
    })
  };

  componentDidMount() {
    ReportService.reportPages()
      .then(response => {
        this.setState({
          menuP: response.data
        });
      })
      .catch(error => {
        console.error("Paginas de reporte: ", error);
      });

    AuthServices.getUserFromSession()
      .then(response => {
        this.setState({
          myUser: response.data
        })
      })
      .catch(error => {
        this.setState({
          myUser: []
        })
      });
  };

  handleReportLoad = (report) => {
    this.report = report;
  };

  handleReportRender = (report) => {
    this.report = report;
  };

  printReport = () => {
    if (this.report) this.report.print();
  };

  resetFilters = () => {
    if (this.report) {
      this.report.resetPersistentFilters();
      notificationWithIcon('success', '¡Listo!', 'Se han reiniciado los filtros en la totalidad del reporte');
    }
  };

  selectedData = (event) => {
    const data = event;
    if (data && data.dataPoints && data.dataPoints.length > 0) {
      const firstDataPoint = data.dataPoints[0];
      const targetData = firstDataPoint.identity[0]
      // console.log(targetData.equals);
      // console.log(targetData.target.table)
      if (targetData.target.table === "Licitacion" || targetData.target.table === "Compra_agil"){
        // console.log(targetData.target.table);
        this.setState({
          code_chance: targetData.equals,
          pageNameOp: data.page.name
        });
      }
      // console.log(this.state.code_chance, this.state.pageNameOp);
    }
    // } else {
    //   this.setState({
    //     code_chance: null,
    //     pageNameOp: null
    //   });
    // }
  };

  buttonClickReport = (data) => {
    // console.log(data, this.state.code_chance, this.state.pageNameOp);
    if (this.state.code_chance !== null) {
      if (data.id === "c668a4e0254384a5c489" && this.state.pageNameOp === "ReportSection8eb0ac5cbd34c6ae0880") {
        this.handleModalCA(false);
        this.report.on("buttonClicked", this.handleModalLic);
        NotificationsService.detailLicitaciones(this.state.code_chance)
          .then(response => {
            this.setState({ licDetail: response.data });
            OpportunityManagerService.opportunityDetail(this.state.code_chance)
              .then(resp => {
                this.setState({ opportunityDetail: resp.data });
              })
              .catch(error => {
                console.log(error);
                this.setState({ opportunityDetail: [] });
              });
          })
          .catch(error => {
            console.log(error);
            this.setState({ licDetail: [] });
          });
      } else {
        this.report.off('buttonClicked', this.handleModalLic);
      }

      if (data.id === "a517979899481eaca743" && this.state.pageNameOp === "ReportSection3f289ee79a3ab23e0631") {
        this.handleModalLic(false);
        this.report.on("buttonClicked", this.handleModalCA);
        NotificationsService.detailCompraAgil(this.state.code_chance)
          .then(response => {
                this.setState({caDetail: response.data})
            OpportunityManagerService.getOpportunity(this.state.code_chance)
                .then(response => {
                  this.setState({ op_data: response.data })
                })
                .catch(error => {
                  this.setState({ op_data: [] })
                });
          })
          .catch(error => {
            console.log(error);
                this.setState({caDetail: []})
          });
      } else {
        this.report.off('buttonClicked', this.handleModalCA);
      }
    } else {
      this.setState({licDetail: []});
      this.setState({caDetail: []});
    }
    // console.log(`Button ${data.id} of type ${data.type} Clicked!`);
  }

  handleListReload = () => {
    OpportunityManagerService.getOpportunity(this.state.code_chance)
      .then(response => {
        this.setState({ op_data: response.data, })
      })
      .catch(error => {
        this.setState({ op_data: [], })
      });
  };

  reportRender = () => {
    const reportStyle = {
      height: window.innerHeight - 115,
      width: window.innerWidth
    };
    const extraSettings = {
      filterPaneEnabled: false,
      persistentFiltersEnabled: true,
      hideErrors: true,
      navContentPaneEnabled: false,
      personalBookmarksEnabled: true,
      visualRenderedEvents: true,
      panes: {
        bookmarks: {
          visible: true
        }
      },
      bars: {
        statusBar: {
          visible: true
        }
      }
    };

    if (localStorage.getItem('bearerToken')) {
      if(localStorage.getItem('uuss') === '55'){
        uuss = 'ReportSection1dd4a9c504c54c2ba601';
      } else {
        uuss = 'ReportSection8094d070e3aa2dce3a0e';
      }
      // console.log(uuss, localStorage.getItem('uuss'));
      return (<Report
        tokenType="Aad"
        accessToken={localStorage.getItem('bearerToken')}
        embedUrl={"https://app.powerbi.com/reportEmbed?reportId=" + pbiEnv.REACT_APP_EMBED_REPORT_ID + "&actionBarEnabled=false"}
        embedId={pbiEnv.REACT_APP_EMBED_REPORT_ID}
        reportMode="View"
        datasetId={pbiEnv.REACT_APP_EMBED_DATASET_ID}
        groupId={pbiEnv.REACT_APP_EMBED_GROUP_ID}
        extraSettings={extraSettings}
        permissions="All" // View, For "Edit" mode permissions should be "All"
        style={reportStyle}
        pageName={uuss} // Inicio: ReportSection8094d070e3aa2dce3a0e
        // pageName='ReportSection8094d070e3aa2dce3a0e'
        onLoad={this.handleReportLoad}
        onRender={this.handleReportRender}
        onSelectData={this.selectedData}
        onButtonClicked={this.buttonClickReport}
      />);
    } else {
      spinnerService.show('mySpinner');
      setTimeout(() => {
        spinnerService.show('mySpinner');
        window.location.reload();
      }, 4000);
    }
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
  };


  render() {
    const handleMenu = (item) => {
      if (this.report) {
        return this.report.setPage(item)
          .then(function (n) {
            // notificationWithIcon('info', 'Cargando...', 'Espere un momento');
          })
          .catch(error => {
            console.error("ERROR de carga: ", error);
          })
      }
    };



    // Sigin with Github --> user:feryel93 pass:k2HB/y_y for changes
    // SVG Icons
    // Icons url: https://www.iconfont.cn/manage/index?spm=a313x.7781069.1998910419.13&manage_type=myprojects&projectId=2617681&keyword=&project_type=&page=
    const IconFont = Icon.createFromIconfontCN({
      scriptUrl: '//at.alicdn.com/t/c/font_2617681_9qhc14kjdra.js',
    });
   
    return(
      <Row style={{height: window.innerHeight-115+"px"}}>
        <UserContext.Consumer>
          {(user) => (
            <div>
              {
                this.state.modalVisibleCA ?
                  <OportunidadCa visible={true} handleModal={this.handleModalCA} reloadList={this.handleListReload} code={this.state.code_chance} user_type={user.type}
                                 detail={this.state.caDetail} op_data={this.state.op_data} user_id={user.id} report={this.report}/>
                  :
                  <OportunidadCa visible={false} handleModal={this.handleModalCA} reloadList={this.handleListReload} code={this.state.code_chance} user_type={user.type}
                                 detail={this.state.caDetail} op_data={this.state.op_data} user_id={user.id} report={this.report}/>
              }
            </div>
          )}
        </UserContext.Consumer>
        {
          this.state.modalVisibleLic ?
            <OportunidadLic visible={true} handleModal={this.handleModalLic} code={this.state.code_chance} detail={this.state.licDetail} oppDetail={this.state.opportunityDetail} report={this.report}/>
            :
            <OportunidadLic visible={false} handleModal={this.handleModalLic} code={this.state.code_chance} detail={this.state.licDetail} oppDetail={this.state.opportunityDetail} report={this.report}/>
        }

        <UserContext.Consumer>
          {user => (
            user.customer === 55 ? // Change this Id when pass to test
            <Menu onClick={this.handleClick} selectedKeys={this.state.current} style={{position: "fixed", top: "-3px", zIndex: "101", left: "255px", marginRight: "0px"}} mode="horizontal">
              <Menu.Item className="customclass" key="0" onClick={() => handleMenu("ReportSection1dd4a9c504c54c2ba601")}>
                <span className="submenu-title-wrapper">
                  <Tooltip placement="bottom" title="Indicadores">
                      <IconFont theme="twoTone" style={{fontSize: "23px", marginRight: "0px"}} type="icon-Indicadores"/>
                  </Tooltip>
                </span>
              </Menu.Item>

              <Menu.Item className="customclass" key="1" onClick={() => handleMenu("ReportSection4fcc1d140f339b5d11d3")}>
                <span className="submenu-title-wrapper">
                  <Tooltip placement="bottom" title="Compra Ágil">
                      <IconFont theme="twoTone" style={{fontSize: "23px", marginRight: "0px"}} type="icon-Compra-Agil"/>
                  </Tooltip>
                </span>
              </Menu.Item>
              <Menu.Item className="customclass" key="2" onClick={() => handleMenu("ReportSection2a46a9c39e7e53802292")}>
                <span className="submenu-title-wrapper">
                  <Tooltip placement="bottom" title="Análisis de Prospectos">
                      <IconFont theme="twoTone" style={{fontSize: "23px", marginRight: "0px"}} type="icon-Pie-chart"/>
                  </Tooltip>
                </span>
              </Menu.Item>

              <Menu.Item className="customclass" key="3" onClick={() => handleMenu("ReportSection1f7d02300d4c811e0860")}>
                <span className="submenu-title-wrapper">
                  <Tooltip placement="bottom" title="Información General">
                      <IconFont theme="twoTone" style={{fontSize: "23px", marginRight: "0px"}} type="icon-Informacion"/>
                  </Tooltip>
                </span>
              </Menu.Item>
            </Menu>
          :
          <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} style={{position: "fixed", top: "-3px", zIndex: "101", left: "255px", marginRight: "0px"}} mode="horizontal">
            {this.state.menuP.map((di) => {
              return (
              <Menu.Item className="customclass" key={di.index} onClick={() => handleMenu(di.name)}>
                <span className="submenu-title-wrapper">
                  <Tooltip placement="bottom" title={di.display_name}>
                    <IconFont theme="twoTone" style={{fontSize: "23px", marginRight: "0px"}} type={di.icon}/>
                  </Tooltip>
                </span>
              </Menu.Item>)
            })}
            </Menu>
          )}
        </UserContext.Consumer>

        <Menu selectedKeys={[this.state.current]}
          style={{ position: "fixed", top: "-3px", zIndex: "101", left: "820px", marginRight: "0px" }}
          mode="horizontal">
          <Divider type="vertical" style={{ width: "2px", height: "2em" }} />
          <Menu.Item className="customclass" key="setting:1" onClick={this.printReport}>
            <span className="submenu-title-wrapper">
              <Tooltip placement="bottom" title="Imprimir">
                <IconFont style={{ fontSize: "22px", marginRight: "0px" }} type="icon-Imprimir" />
              </Tooltip>
            </span>
          </Menu.Item>
          <Menu.Item className="customclass" key="setting:2" onClick={this.resetFilters}>
            <span className="submenu-title-wrapper">
              <Tooltip placement="bottom" title="Reiniciar filtros">
                <IconFont style={{ fontSize: "22px", marginRight: "0px" }} type="icon-reset" />
              </Tooltip>
            </span>
          </Menu.Item>
        </Menu>

        <Spinner name="mySpinner">
          <div className="panel__refresh"><LoadingIcon /></div>
        </Spinner>
        <UserContext.Consumer>
          {user => (
            user.customer !== 0 ? localStorage.setItem('uuss', user.customer) : '',
            this.reportRender()
          )}
        </UserContext.Consumer>
      </Row>
    )
  }
}

export default Dash_CurrentUsers;