/*eslint-disable */
import axios from 'axios';


const TransactionsServices = {

    // GET METHODS
    webpayCreate: function (data) {
        return axios.get('/api/webpaytr/createpay', {
            params: {
                no_fact: data.no_fact,
                amount: data.amount
            }
        });
    },

    zohoToken: async function () {
        return await axios.get('/api/webpaytr/getzohotoken')
    },

    webpayCommit(token) {
        return axios.post('/api/webpaytr/commitpay', {
            token: token
        });
    }
};

export default TransactionsServices;

// {
//             buy_order: data.buy_order, 
//             session_id: data.session_id, 
//             amount: data.amount, 
//             return_url: data.return_url
//         }