/*eslint-disable */
import axios from 'axios';
import React from 'react';

const LastDatesService = {
    
    // GET METHODS

    getLastDate: async function(){
        return await axios.get('/api/report_pages/lastupdate')
    },
};

export default LastDatesService;