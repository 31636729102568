/*eslint-disable */
import React from 'react';
import ProspectsService from '../../../services/prospects/ProspectsService';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import { Icon } from 'antd';


class DeleteProspects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleDeleteModal: this.props.handleDeleteModal,
            reloadList: this.props.reloadList,
            load: false,
        };

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            this.setState({
                load: true
            });
            ProspectsService.deleteProspect(this.props.prospects.id)
                .then(response => {
                    if (response.status === 204) {
                        notificationWithIcon('success', 'Prospecto Eliminado', 'El prospecto se eliminó correctamente');
                        this.state.handleDeleteModal(false);
                        this.state.reloadList();
                    }
                })
                .catch(error => {
                    this.setState({
                        load: false
                    });
                    notificationWithIcon('error', 'Error', 'No se pudo eliminar el prospecto');
                })
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    render() {
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        return (
            <Modal
                footer={null}
                title={<div><Icon type="warning" style={{ fontSize: '35px', marginRight:'20px'}}  theme="twoTone" twoToneColor="#ff9818" />Eliminar Prospecto</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.prospects)}
                onCancel={() => this.state.handleDeleteModal(false)}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form.Item>
                    <div style={{ textAlign:'center' }}>
                        <h3>¿Está seguro que desea <b>Eliminar</b> este prospecto? </h3>
                        <h4>(
                            {this.props.prospects.name == null || this.props.prospects.name == "" ? <span>sin nombre</span> : this.props.prospects.name}
                            &nbsp;
                            {this.props.prospects.lastname == null || this.props.prospects.lastname == "" ? <span>sin apellido</span> : this.props.prospects.lastname}
                        )</h4>
                    </div>
                </Form.Item>

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item style={{marginLeft: '30%'}}>
                            <Button type="secondary" onClick={() => this.state.handleDeleteModal(false)}>
                                Cancelar
                            </Button>
                            <Divider type="vertical"/>
                            <Button icon={"true"} loading={load} htmlType="submit"
                                    style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                Eliminar
                            </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const WrappedDeleteForm = Form.create()(DeleteProspects);

export default WrappedDeleteForm;