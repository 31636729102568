/*eslint-disable */
import React, { Component } from "react";

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTime: Date.now()
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                currentTime: Date.now()
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { futureDate } = this.props;
        const targetTime = new Date(futureDate).getTime();
        const timeBetween = targetTime - this.state.currentTime;
        const seconds = Math.floor((timeBetween / 1000) % 60);
        const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
        const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

        return (
            <>
                <span>
                    {days > 1 ? days+' días ' : days === 1 ? days+' día ' : ''}
                    {hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : '00:'}
                    {minutes > 0 ? `${minutes < 10 ? `0${minutes}` : minutes}:` : '00:'}
                    {seconds > 0 ? `${seconds < 10 ? `0${seconds}` : seconds}` : '00'}
                    {hours > 1 ? " horas" : hours === 1 ? " hora" : ""}
                    {minutes > 1 && hours === 0 ? " minutos" : minutes === 1 && hours === 0 ? " minuto" : ""}
                </span>
            </>
        );
    }
}

export default Countdown;

