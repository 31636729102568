/*eslint-disable */
import {Table, Input, Tag, Button, Icon, Divider, Tooltip} from 'antd';
import React from 'react';
import UserService from "../../../services/user/UserService";
import {Card, CardBody, Col, Button as ButtonIcons} from "reactstrap";
const { Column } = Table;
import PlusIcon from "mdi-react/PlusIcon";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import DeleteUser from "./DeleteUser";
import ActivateUser from "./ActivateUser";
import DeactivateUser from "./DeactivateUser";
import CustomerService from "../../../services/customer/CustomerService";
import UserContext from "../../../store/userContext";
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";
import ReportService from '../../../services/dashboard/ReportService';


class ListUsers extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchText: '',
            data:[],
            selectedObject:[],
            userPage: [],
            test:'',
            customers:[],
            valid: false
        };
    }

    handleEditModal = (value) => {
        this.setState({
          editModalVisible: value,
        })
    };

    handleAddModal = (value) => {
      this.setState({
          addModalVisible: value,
      })
    };

    handleDeleteModal = (value) => {
        this.setState({
            deleteModalVisible:value,
        })
    };

    handleActivateModal = (value) => {
        this.setState({
            activateModalVisible:value,
        })
    };

    handleDeactivateModal = (value) => {
        this.setState({
            deactivateModalVisible:value,
        })
    };

    handleListReload = () => {
        this.componentDidMount();
    };

    handleChange(event) {
        this.setState({test: event.target.value});
    }

    actionButton = (user, action) => {
      this.setState({
        selectedObject:user,
      });

      if (action === "edit"){
        ReportService.userPages(user.id)
          .then(response => {
              this.setState({
                  userPage: response.data
              });
          })
          .catch(error => {
              this.setState({
                  userPage: []
              });
              console.error("Lista de reporte del usuario: ", error);
          });
        this.handleEditModal(true);
      }
      else if(action === "delete"){
        this.handleDeleteModal(true);
      } else if(action === "activate"){
        this.handleActivateModal(true);
      } else if(action === "deactivate"){
        this.handleDeactivateModal(true);
      }
    };

    componentDidMount(){
      spinnerService.show('mySpinner');
      UserService.getUser()
        .then(response =>{
            spinnerService.hide('mySpinner');
            this.setState({
                data: response.data,
            })
        })
        .catch( error =>{
            spinnerService.hide('mySpinner');
            this.setState({
                data: [],
            })
        });

      spinnerService.show('mySpinner');
      CustomerService.getCustomers()
        .then(response => {
            spinnerService.hide('mySpinner');
            this.setState({
                customers: response.data,
            })
        })
        .catch(error => {
            spinnerService.hide('mySpinner');
            this.setState({
                customers: [],
            })
        });
    }

    getColumnSearchProps = (dataIndex, title) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Buscar por ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />

          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 , marginRight: 8}}>
            Limpiar
          </Button>

            <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
    });

    getCustomersName = (id) => {
      let customerName = "";
      for(let key in this.state.customers){
        if(this.state.customers[key].id === id){
          customerName = this.state.customers[key].customer;
          break;
        }
      }
      return customerName.length > 20  ? `${customerName.slice(0, 20)}...` : customerName;
    };

    handleSearch = (selectedKeys, confirm) => {
      confirm();
      this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };

    handleCompanyBlock = (item) => {
      let test = "";
      for(let key in this.state.customers){
        if(this.state.customers[key].id === item){
          test = this.state.customers[key].is_blocked;
          break;
        }
      }
      return test;
    }

    filterType = (type) => {
      if (type === "A"){
        return <span>
          <Tag color='#ff9800'>
            Super Admin
          </Tag>
        </span>
      }
      else if (type ==="B"){
        return <span>
          <Tag>
              Master
          </Tag>
        </span>
      }
      else if (type === "C") {
        return <span>
          <Tag>
              Estándar
          </Tag>
        </span>
      }
      else if (type === "D") {
        return <span>
          <Tag color='geekblue'>
            Aliado
          </Tag>
        </span>
      } else if (type === "E") {
        return <span>
          <Tag color='blue'>
            Ejec. de Ventas
          </Tag>
        </span>
      } else if (type === "F") {
        return <span>
          <Tag color='gold'>
            Atn. al Cliente
          </Tag>
        </span>
      }
    };

    searchCompany = () => {
      let searchCom = [];
      searchCom = this.state.customers.map(function (data, i) {
          return {text: data.customer, value: data.id};
      });
      return searchCom;
    }


    render() {

      const showTotal = (total, range) => {
        return `${range[0]}-${range[1]} de ${total}`
      };

      return(
          <UserContext.Consumer>
                {(user) =>(
          <Col md={12}>

               {/* MODALS*/}

              { this.state.editModalVisible ?
                  <EditUser visible={true} reloadList={this.handleListReload} handleEditModal={this.handleEditModal} user={this.state.selectedObject} report_pages={this.state.userPage}/>
                    :
                  <EditUser visible={false} reloadList={this.handleListReload}  handleEditModal={this.handleEditModal} user={this.state.selectedObject} report_pages={this.state.userPage}/>
              }

              {
                this.state.addModalVisible ?
                  <AddUser visible={true} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>
                    :
                  <AddUser visible={false} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>

              }

              {
                  this.state.deleteModalVisible ?
                  <DeleteUser visible={true} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} user={this.state.selectedObject}/>
                    :
                  <DeleteUser visible={false} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} user={this.state.selectedObject}/>

              }

              {
                  this.state.activateModalVisible ?
                  <ActivateUser visible={true} reloadList={this.handleListReload} handleActivateModal={this.handleActivateModal} user={this.state.selectedObject}/>
                    :
                  <ActivateUser visible={false} reloadList={this.handleListReload} handleActivateModal={this.handleActivateModal} user={this.state.selectedObject}/>

              }

              {
                  this.state.deactivateModalVisible ?
                  <DeactivateUser visible={true} reloadList={this.handleListReload} handleDeactivateModal={this.handleDeactivateModal} user={this.state.selectedObject}/>
                    :
                  <DeactivateUser visible={false} reloadList={this.handleListReload} handleDeactivateModal={this.handleDeactivateModal} user={this.state.selectedObject}/>

              }


            <Card>
              <CardBody>
                <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>
                  <div className="card__title" style={{marginTop: "-20px"}}>
                  <h4 className="bold-text">Listado de usuarios</h4>
                    <ButtonIcons onClick={() => this.handleAddModal(true)} className="icon" color="success" style={{float:'right', marginBottom: '20px'}} ><p><PlusIcon /> Agregar Usuario</p></ButtonIcons>
                </div>
                <Table dataSource={this.state.data} pagination={{ defaultPageSize: 10, showSizeChanger: true, showTotal: showTotal, pageSizeOptions: ['10', '30', '50', '100']}}>

                  <Column {...this.getColumnSearchProps('name', 'Nombre')} title="Nombre" dataIndex="name" key="name" 
                          render={(text, rec) => (
                            text.length > 20 ? `${text.slice(0, 20)}...` : text
                          )}/>
                  <Column {...this.getColumnSearchProps('lastname', 'Apellido')} title="Apellido" dataIndex="lastname" key="lastname" 
                          render={(text, rec) => (
                            text.length > 20 ? `${text.slice(0, 20)}...` : text
                          )}/>
                  <Column {...this.getColumnSearchProps('email', 'Correo')} title="Correo" dataIndex="email" key="email" 
                          render={(text, rec) => (
                            text.length > 20 && user.type == 'A' ? `${text.slice(0, 20)}...` : text
                          )}/>

                  {user.type == 'A' || user.type == 'F' ?
                  <Column title="Empresa" dataIndex="customer" key="customer" render={(text, record) => (<span>{this.getCustomersName(record.customer)}</span>)}
                          filters={this.searchCompany()} onFilter={(value, record) => record.customer === value}
                  /> : ''}
                  <Column className="theadtbl__tables"
                      render={(text, record) =>(
                          this.filterType(record.type)
                      )}
                      title="Tipo"
                      dataIndex="type"
                      key="type" />

                  <Column className="theadtbl__tables"
                    render={(text, record) => (
                      <span>
                        {record.pbi_email === null && record.pbi_password === null || 
                        record.pbi_email === '' || record.pbi_password === '' ?
                          <Tooltip placement="top" title="Faltan credenciales de Power BI">
                            <Tag color="orange">
                              ¡Cuenta PBI!
                            </Tag>
                          </Tooltip>:''}
                        { record.is_blocked ?
                          <Tooltip placement="top" title="Usuario con 3 intentos de login. Se le envió un correo para reestablecer contraseña">
                            <Tag color={'volcano'}>
                              BLOQUEADO
                            </Tag>
                          </Tooltip>     
                        :
                        !record.block_access ?
                        <Tooltip placement="top" title="Usuario está OK!">
                          <Tag color={'green'}>
                            ACCESO
                          </Tag>
                        </Tooltip>
                        :
                         <Tooltip placement="top" title="Usuario o cliente bloqueado">
                          <Tag color={'red'}>
                            SIN ACCESSO
                          </Tag>
                        </Tooltip>
                        }
                        {user.type == 'A' || user.type == 'F' ?
                          record.is_active ?
                          <Tooltip placement="top" title="Cliente activo">
                            <Tag color={'#3498db'}>
                              ACTIVO
                            </Tag>
                          </Tooltip>
                          :
                         <Tooltip placement="top" title="Cliente desactivado">
                          <Tag color={'#999999'}>
                            INACTIVO
                          </Tag>
                        </Tooltip>
                        :""
                        }
                      </span>
                    )}
                  title="Estado"
                  dataIndex="is_blocked"
                  key="is_blocked" />

                  {user.type === 'A' ?
                    <Column className='actionbtn__table-user'
                      render={(text, record) => (
                        <span>
                          {
                            <Tooltip placement="top" title="Editar" >
                              <Button
                                onClick={() => this.actionButton(record, "edit")}
                                type="warning"
                                shape="circle"
                                icon="edit"
                                size={'large'}
                                style={{
                                  paddingBottom: '2.5%',
                                  backgroundColor: '#70bbfd',
                                  color: 'white',
                                  borderColor: '#70bbfd'
                                }}
                              />
                            </Tooltip>
                          }

                          <Divider type="vertical" />

                          {
                            user.id !== record.id ?
                              <Tooltip placement="top" title="Eliminar" >
                                <Button
                                  onClick={() => this.actionButton(record, "delete")}
                                  type="warning"
                                  shape="circle"
                                  icon="delete"
                                  size={'large'}
                                  style={{
                                    paddingBottom: '2.5%',
                                    backgroundColor: '#ff4861',
                                    color: 'white',
                                    borderColor: '#ff4861'
                                  }}
                                />
                              </Tooltip>
                              :
                              <Tooltip placement="top" title="Eliminar" >
                                <Button
                                  type="warning"
                                  shape="circle"
                                  icon="delete"
                                  disabled={true}
                                  size={'large'}
                                  style={{
                                    paddingBottom: '2.5%',
                                  }}
                                />
                              </Tooltip>
                          }

                          <Divider type="vertical" />

                          {record.is_active ?
                            record.block_access === true ?
                              <Tooltip placement="top" title="Activar Usuario" >
                                <Button
                                  onClick={() => this.actionButton(record, "activate")}
                                  type="success"
                                  shape="circle"
                                  icon="check"
                                  size={'large'}
                                  disabled={this.handleCompanyBlock(record.customer)}
                                  style={this.handleCompanyBlock(record.customer) === false ? {
                                    paddingBottom: '2.5%',
                                    backgroundColor: '#52c41a',
                                    color: 'white',
                                    borderColor: '#52c41a'
                                  } : { paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                              <Tooltip placement="top" title="Desactivar Usuario" >
                                <Button
                                  onClick={() => this.actionButton(record, "deactivate")}
                                  type="warning"
                                  shape="circle"
                                  icon="stop"
                                  size={'large'}
                                  disabled={user.id !== record.id ? false : true}
                                  style={user.id !== record.id ? {
                                    paddingBottom: '2.5%',
                                    backgroundColor: '#f18517',
                                    color: 'white',
                                    borderColor: '#f18517'
                                  } : { paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                              <Tooltip placement="top" title="El cliente ha sido desactivado. Debe activarlo en el modulo de Clientes" >
                                <Button
                                  type={record.block_access ? "success" : "warning"}
                                  shape="circle"
                                  icon={record.block_access ? "check" : "stop"}
                                  size={'large'}
                                  disabled={true}
                                  style={{ paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                          }
                        </span>)}
                      title="Acciones"
                      dataIndex="id"
                      key="id" />
                    :
                    user.type === 'F' ?
                    <Column className='actionbtn__table-user'
                      render={(text, record) => (
                        <span>
                          {record.type === 'A' ?
                            <Tooltip placement="top" title="Editar" >
                                <Button
                                  type="warning"
                                  shape="circle"
                                  icon="edit"
                                  size={'large'}
                                  disabled={true}
                                  style={{paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                            <Tooltip placement="top" title="Editar" >
                              <Button
                                onClick={() => this.actionButton(record, "edit")}
                                type="warning"
                                shape="circle"
                                icon="edit"
                                size={'large'}
                                style={{
                                  paddingBottom: '2.5%',
                                  backgroundColor: '#70bbfd',
                                  color: 'white',
                                  borderColor: '#70bbfd'
                                }}
                              />
                            </Tooltip>
                          }

                          <Divider type="vertical" />
                          {record.is_active ?
                            record.type === 'A' ?
                            <Tooltip placement="top" title="Desactivar Usuario" >
                                <Button
                                  onClick={() => this.actionButton(record, "deactivate")}
                                  type="warning"
                                  shape="circle"
                                  icon="stop"
                                  size={'large'}
                                  disabled={true}
                                  style={{paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                            record.block_access === true ?
                              <Tooltip placement="top" title="Activar Usuario" >
                                <Button
                                  onClick={() => this.actionButton(record, "activate")}
                                  type="success"
                                  shape="circle"
                                  icon="check"
                                  size={'large'}
                                  disabled={this.handleCompanyBlock(record.customer)}
                                  style={this.handleCompanyBlock(record.customer) === false ? {
                                    paddingBottom: '2.5%',
                                    backgroundColor: '#52c41a',
                                    color: 'white',
                                    borderColor: '#52c41a'
                                  } : { paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                              <Tooltip placement="top" title="Desactivar Usuario" >
                                <Button
                                  onClick={() => this.actionButton(record, "deactivate")}
                                  type="warning"
                                  shape="circle"
                                  icon="stop"
                                  size={'large'}
                                  disabled={user.id !== record.id ? false : true}
                                  style={user.id !== record.id ? {
                                    paddingBottom: '2.5%',
                                    backgroundColor: '#f18517',
                                    color: 'white',
                                    borderColor: '#f18517'
                                  } : { paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                              :
                              <Tooltip placement="top" title="El cliente ha sido desactivado. Debe activarlo en el modulo de Clientes" >
                                <Button
                                  type={record.block_access ? "success" : "warning"}
                                  shape="circle"
                                  icon={record.block_access ? "check" : "stop"}
                                  size={'large'}
                                  disabled={true}
                                  style={{ paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                          }
                        </span>)}
                      title="Acciones"
                      dataIndex="id"
                      key="id" />
                    :
                    <Column
                      render={(text, record) => (
                        <span>
                          {record.type === 'A' || record.type === 'D' || record.type === 'E' || record.type === 'F' ?
                            <Tooltip placement="top" title="Editar" >
                                <Button
                                  type="warning"
                                  shape="circle"
                                  icon="edit"
                                  size={'large'}
                                  disabled={true}
                                  style={{paddingBottom: '2.5%' }}
                                />
                              </Tooltip>
                            :
                            <Tooltip placement="top" title="Editar" >
                              <Button
                                onClick={() => this.actionButton(record, "edit")}
                                type="warning"
                                shape="circle"
                                icon="edit"
                                size={'large'}
                                style={{
                                  paddingBottom: '2.5%',
                                  backgroundColor: '#70bbfd',
                                  color: 'white',
                                  borderColor: '#70bbfd'
                                }}
                              />
                            </Tooltip>
                          }
                        </span>
                      )}
                      title="Acciones"
                      dataIndex="id"
                      key="id" />
                  }
                  </Table>
                </CardBody>
              </Card>
            </Col>
          )}
        </UserContext.Consumer>
      );
    }
}

export default ListUsers;
