/*eslint-disable */
import React from 'react';
import { Container, Row } from 'reactstrap';
import ListWebpay from './components/ListWebpay';


const WebpayPage = () => (

      <Container className="dashboard" >
        <Row>
          <ListWebpay />
        </Row>
      </Container>

);

export default WebpayPage;
