/*eslint-disable */
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import { Button, Divider, Form, Input, Modal, Icon } from "antd";
import AuthServices from '../../../services/auth/AuthService';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';


class Change_Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      visible: true,
      handleEditModal: this.props.handleEditModal,
      reloadList: this.props.reloadList,
      confirmDirty: false,
      isEnabled: false

    };

  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        AuthServices.changePassword(values)
          .then(response => {
            if (response.status === 200) {
              notificationWithIcon('success', 'Cambio de Contraseña', 'Contraseña modificada correctamente');
              this.props.form.resetFields();
              this.state.handleEditModal(false);
              //this.state.reloadList();
            }
          })
          .catch(error => {
            notificationWithIcon('error', 'Error', 'Hubo un error al modificar la contraseña, verifique si la escribió correctamente');
          });
        if (!err && (values.pass1 === values.pass2)) {
          this.props.changePassword(values.pass1, values.pass2);

          alert(this.state.changePassword);
        } else {
          notificationWithIcon('error', 'Error', 'La contraseña escrita no coincide. Inténtelo de nuevo');
          alert(this.state.changePassword);
        }
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('pass1')) {
      callback('La contraseña escrita no coincide. Inténtelo de nuevo');
      this.setState({ isEnabled: false })

    } else {
      callback();
      if (value && this.state.confirmDirty && 0 !== form.getFieldValue('pass0')) {
        this.setState({ isEnabled: true });
      }

    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && this.state.confirmDirty) {
      form.validateFields(['pass2'], { force: true }, () => { });
    }
    callback();

  };


  render() {

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        lg: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 }
      },
    };

    const passTrim = (e) => {
      let str = e.target.value;
      e.target.value = str.replace(/ /g, "");
    }

    return (
      <Modal
        footer={null}
        title={<div><Icon type="usergroup-add" style={{ fontSize: '35px', marginRight: '20px', color: '#3879b1' }} />Cambiar Contraseña</div>}
        visible={this.props.visible}
        onOk={() => this.handleOk(this.props.user)}
        onCancel={() => { this.props.form.resetFields(); this.state.handleEditModal(false) }}
        okText="Guardar"
        cancelText="Cancelar"
      >

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>


          <Form.Item label="Contraseña actual" >
            {getFieldDecorator('pass0', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, ingrese su contraseña actual',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password onChange={passTrim} />)}
          </Form.Item>


          <Form.Item label="Nueva contraseña" >
            {getFieldDecorator('pass1', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, ingrese una contraseña',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password onChange={passTrim} name="pass1" title="No coincide o no tiene el formato requerido" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%-/*?&])[A-Za-z\d@#$!_%-/*?&]{8,}$" />)}
          </Form.Item>

          <Form.Item label="Confirmar contraseña">
            {getFieldDecorator('pass2', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, ingrese nuevamente la contraseña',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onChange={passTrim} name="pass2" onBlur={this.handleConfirmBlur} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%-/*?&])[A-Za-z\d@#$!_%-/*?&]{8,}$" title="No coincide o no tiene el formato requerido" />)}
          </Form.Item>

          <div class="text-center">
            <p><h5>Use 8 o más caracteres de longitud, al menos 1 mayúscula, 1 número y 1 carácter especial (@#$!_%-/*?).</h5></p>
          </div>
          {/* <Form.Item> */}
          <br />
          <div className="text-center">
            <Button type="secondary" onClick={() => { this.props.form.resetFields(); this.state.handleEditModal(false) }}>
              Cancelar
            </Button>
            <Divider type="vertical" />
            <Button type="primary" htmlType="submit" style={{ width: '88px' }} disabled={!this.state.isEnabled}>
              Cambiar
            </Button>
          </div>
          {/* </Form.Item> */}

        </Form>

      </Modal>
    )
  }
}

const WrappedPasswordForm = Form.create()(Change_Password);

//export default WrappedPasswordForm;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (pass0, pass1) => dispatch(actions.changePassword(pass0, pass1))
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedPasswordForm));
