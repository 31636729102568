import React from 'react';
import { Container, Row } from 'reactstrap';
import ListUsers from './components/ListUsers';

const UserPage = () => (
  <Container className="dashboard">
    <Row>
      <ListUsers />
    </Row>
  </Container>
);

export default UserPage;
