/*eslint-disable */
import React from 'react';
import {Button, Modal, Table, Icon, Divider} from "antd";
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import '../../../scss/detailNotifications.css';


class InfoAdjudicacion extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            AdjDetail: [],
            codeDetail: null,
            visible: true,
            handleInfoAdjModal: this.props.handleInfoAdjModal,
            reloadList: this.props.reloadList,
            load: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.codeDetail !== prevProps.code) {
            this.setState({
                AdjDetail: this.props.detail,
                codeDetail: this.props.code
            });
        }
    }

    detailAdjudicacion = () => {
        let detalle;

        detalle = this.state.AdjDetail.map(data1 => {
                return (
                    <div style={{padding: "0 12px", fontSize: "12px"}}>
                        <Row>
                            <Col lg={6}>
                                <Divider plain style={{margin: "0"}}><h5>Organismo Demandante</h5></Divider>
                                <p><b>Razón Social:</b> {data1.nombreorganismo}</p>
                                <p><b>Unidad de Compra:</b> {data1.nombreunidad}</p>
                                <p><b>R.U.T:</b> {data1.rutunidad}</p>
                                <p><b>Dirección:</b> {data1.direccionunidad}</p>
                                <p><b>Comuna:</b> {data1.comunaunidad}</p>
                                <p><b>Ciudad que se genera la Adquisición:</b> {data1.regionunidad}</p>
                            </Col>
                            <Col lg={6}>
                                <Divider plain style={{margin: "0"}}><h5>Datos del Contacto</h5></Divider>
                                <p><b>Nombre Completo:</b> {data1.nombrecontacto}</p>
                                <p><b>Cargo:</b> {data1.cargocontacto}</p>
                                <p><b>Télefono:</b> {data1.fonocontacto}</p>
                                <p><b>Correo:</b> {data1.mailcontacto}</p>
                            </Col>
                        </Row>
                        <Divider plain style={{margin: "10px 0"}}><h5>Datos de la Adquisición</h5></Divider>
                        <Row>
                            <Col lg={6}>
                                <p><b>Número de Adquisición:</b> {data1.codigoadqusicion}</p>
                                <p><b>Nombre:</b> {data1.nombreadquisicion}</p>
                                <p><b>Tipo de Adquisición:</b> {data1.tipoadquisicion}</p>
                                <p><b>Tipo de Convocatoria:</b> {data1.tipoconvocatoria}</p>
                                <p><b>Fecha de Publicación:</b> {moment(data1.fechapublicacion).format("DD-MM-YYYY H:mm")}</p>
                            </Col>
                            <Col lg={6}>
                                <p><b>Fecha de Cierre:</b> {moment(data1.fechacierre).format("DD-MM-YYYY H:mm")}</p>
                                <p><b>Moneda:</b> {data1.moneda}</p>
                                <p><b>Monto Neto Adjudicado:</b> {data1.montonetoadj.toLocaleString("es-CL")}</p>
                                <p><b>Monto Neto Estimado del Contrato:</b> {data1.montoestimado.toLocaleString("es-CL")}</p>
                            </Col>
                        </Row>
                    </div>				
                )
            });

        return detalle;

    }


    render() {

        return (
            <Modal
                title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Acta Adjudicación Nº {this.props.code}</div>}
                visible={this.props.visible}
                width={'1200px'}
                onCancel={() => {this.state.handleInfoAdjModal(false)}}
                footer={[<Button type="secondary" onClick={() => {this.state.handleInfoAdjModal(false)}}>
                            Cerrar
                        </Button>]}
            >
                {this.state.AdjDetail.length == 0 || this.props.code !== this.state.codeDetail ? 
                    <div style={{textAlign: "center", margin: "10px 30px", paddingTop: "70px"}}>
                        <div style={{align: "center"}} className="panel__refresh"><LoadingIcon /></div>
                        Cargando información de Adjudicación {this.props.code} ...
                    </div>
                : 
                <span>
                    {this.detailAdjudicacion()}
                </span>
                }
            </Modal>
        )
    }
}

export default InfoAdjudicacion;
