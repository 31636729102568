/*eslint-disable */
import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PhoneOutlineIcon from 'mdi-react/PhoneOutlineIcon';
import AccountBoxOutlineIcon from 'mdi-react/AccountBoxOutlineIcon';
import BankOutlineIcon from 'mdi-react/BankOutlineIcon';
import CardTextOutlineIcon from 'mdi-react/CardTextOutlineIcon';
import AtIcon from 'mdi-react/AtIcon';
import { Link } from 'react-router-dom';
import * as actions from '../../../redux/actions/auth';
import { Form, Input, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LoadingIcon from "mdi-react/LoadingIcon";
import classNames from 'classnames';
import AuthServices from '../../../services/auth/AuthService';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";

class RegisterForm extends PureComponent {
    componentDidMount() {
        this.props.form.validateFields();
    }

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            load: false,
            showPassword1: false,
            showPassword2: false,
        };
        this.formRef = React.createRef();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.props.form);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    load: true,
                });
                
                if (values.pass1 === values.pass2) {
                    this.props.onRegister(values)
                        .then(response => {
                            if(response.status === 201) {
                                notificationWithIcon('success', '¡Registrado Exitosamente!', 'Ahora puede iniciar su prueba de 14 días');
                                this.resetForm();
                                this.setState({ load: false });
                            } else {
                                notificationWithIcon('error', 'Error', `Error en el registro: ${response.statusText}`);
                            }
                        })
                        .catch(error => {
                            if (error.response !== undefined) {
                                if (error.response.status === 401 || error.response.status === 409) {
                                    notificationWithIcon('error', '¡Error!', error.response.data.detail);
                                } 
                                else if(error.response.status === 400) {
                                    // Errores 400 - con los 500 es otro
                                    const errorMessages = Object.entries(error.response.data)
                                                        .map(([field, messages]) => `<li>${field}: ${messages.join(', ')}</li>`)
                                                        .join('');
                                    notificationWithIcon('error', '¡Error!', 'Ya existe un usuario o RUT registrado');
                                } else {
                                    notificationWithIcon('error', '¡Error!', 'No se pudo completar la petición');
                                }
                            } else {
                                notificationWithIcon('error', '¡Error!', 'No se pudo completar la petición');
                            }
                        })
                        .finally(() => {
                            this.setState({ load: false });
                        });
                } else {
                    notificationWithIcon('error', 'Error', 'Las contraseñas escritas no coinciden. Inténtelo de nuevo');
                    this.setState({ load: false });
                }
            }
        });
    };

    showPassword1 = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ showPassword1: !prevState.showPassword1 }));
    };

    showPassword2 = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ showPassword2: !prevState.showPassword2 }));
    };

    resetForm = () => {
        console.log(this.formRef.current);
        const form = this.formRef.current;
        if (form) {
            form.reset();
        }
        this.props.form.resetFields();
    };


    render() {
        const { showPassword1, showPassword2, load } = this.state;

        const expandClass = classNames({
            icon: true,
            expand: true,
            'expand--load': load,
        });

        const {
            getFieldDecorator, getFieldError, isFieldTouched, resetFields
        } = this.props.form;

        const userNameError = isFieldTouched('userName') && getFieldError('userName');
        const nameError = isFieldTouched('name') && getFieldError('name');
        const lastnameError = isFieldTouched('lastname') && getFieldError('lastname');
        const customerError = isFieldTouched('customer') && getFieldError('customer');
        const rucError = isFieldTouched('ruc') && getFieldError('ruc');
        const phoneError = isFieldTouched('phone') && getFieldError('phone');
        const pass1Error = isFieldTouched('pass1') && getFieldError('pass1');
        const pass2Error = isFieldTouched('pass2') && getFieldError('pass2');

        const passTrim = (e) => {
            let str = e.target.value;
            e.target.value = str.replace(/ /g, "");
        }


        return (

            <form className="form" onSubmit={this.handleSubmit} id='formRegister' ref={this.formRef}>
                <div className="form__row">
                    <div className="form__column">
                        <span className="form__form-group-label">Nombres</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <AccountBoxOutlineIcon />
                            </div>

                            <div validateStatus={nameError ? 'error' : ''} help={nameError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('name', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your Name!' }],
                                })(
                                    <input name="name" type="text" placeholder="Ingrese sus nombres" maxLength={30} required />
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="form__column">
                        <span className="form__form-group-label">Apellidos</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <AccountBoxOutlineIcon />
                            </div>

                            <div validateStatus={lastnameError ? 'error' : ''} help={lastnameError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('lastname', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your Lastname!' }],
                                })(
                                    <input name="lastname" type="text" placeholder="Ingrese sus apellidos" maxLength={30} required />
                                )}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="form__row">
                    <div className="form__column">
                        <span className="form__form-group-label">Empresa o Institución</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <BankOutlineIcon />
                            </div>

                            <div validateStatus={customerError ? 'error' : ''} help={customerError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('customer', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your Enterprise name!' }],
                                })(
                                    <input name="customer" type="text" placeholder="Nombre de la empresa" maxLength={80} required />
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="form__column">
                        <span className="form__form-group-label">R.U.T.</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <CardTextOutlineIcon />
                            </div>

                            <div validateStatus={rucError ? 'error' : ''} help={rucError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('ruc', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your RUT!' }],
                                })(
                                    <input name="ruc" type="text" placeholder="Ingrese R.U.T." maxLength={25} required />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column">
                        <span className="form__form-group-label">Correo</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <AtIcon />
                            </div>

                            <div validateStatus={userNameError ? 'error' : ''} help={userNameError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('email', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your email!' }],
                                })(
                                    <input name="email" type="email" placeholder="ejemplo@dominio.com" maxLength={50} required />
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="form__column">
                        <span className="form__form-group-label">Teléfono o Celular</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <PhoneOutlineIcon />
                            </div>

                            <div validateStatus={phoneError ? 'error' : ''} help={phoneError || ''} style={{ width: '100%' }} >
                                {getFieldDecorator('phone', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your Phone Number!' }],
                                })(
                                    <input name="phone" type="text" placeholder="Ingrese un número de contacto" maxLength={20} required />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column">
                        <span className="form__form-group-label">Contraseña</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <KeyVariantIcon />
                            </div>
                            <div validateStatus={pass1Error ? 'error' : ''} help={pass1Error || ''} style={{ width: '100%' }}>
                                {getFieldDecorator('pass1', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please input your Password!' }],
                                })(
                                    <input
                                        onChange={passTrim}
                                        name="pass1"
                                        type={showPassword1 ? 'text' : 'password'}
                                        placeholder="Contraseña"
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%\-/*?&])[A-Za-z\d@#$!_%\-/*?&]{8,}$"
                                        required
                                    />
                                )}
                            </div>
                            <button
                                className={`form__form-group-button${showPassword1 ? ' active' : ''}`}
                                onClick={e => this.showPassword1(e)}
                                type="button"
                            ><EyeIcon />
                            </button>
                        </div>
                    </div>
                    <div className="form__column">
                        <span className="form__form-group-label">Repetir Contraseña</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                                <KeyVariantIcon />
                            </div>
                            <div validateStatus={pass2Error ? 'error' : ''} help={pass2Error || ''} style={{ width: '100%' }}>
                                {getFieldDecorator('pass2', {
                                    initialValue: null,
                                    rules: [{ required: true, message: 'Please repeat your Password!' }],
                                })(
                                    <input
                                        onChange={passTrim}
                                        name="pass2"
                                        type={showPassword2 ? 'text' : 'password'}
                                        placeholder="Contraseña"
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%\-/*?&])[A-Za-z\d@#$!_%\-/*?&]{8,}$"
                                        required
                                    />
                                )}
                            </div>
                            <button
                                className={`form__form-group-button${showPassword2 ? ' active' : ''}`}
                                onClick={e => this.showPassword2(e)}
                                type="button"
                            ><EyeIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <p><h6>Use 8 o más caracteres de longitud, al menos 1 mayúscula, 1 número y 1 carácter especial (@#$!_%-/*?).</h6></p>
                <div className="form__column">
                <div className="form__form-group-field" style={{ visibility: 'hidden' }}>

                </div>
                </div>
                {/* <div className="form__row"> */}
                    <button
                        className={"btn btn-primary account__btn account__btn--small " + expandClass}
                        type="primary"
                        htmltype="submit"
                    ><LoadingIcon />
                        Registrarse
                    </button>
                    {/* <Divider type="vertical" />
                    <button
                        className="btn btn-outline-primary account__btn account__btn--small"
                        type="button"
                        onClick={this.resetForm}
                    >Borrar todo</button>
                </div> */}
            </form>
        );
    }
}

const WrappedRegisterForm = Form.create()(RegisterForm);
const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (data) => dispatch(actions.authRegister(data))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedRegisterForm));