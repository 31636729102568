/*eslint-disable */
import React from 'react';
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import { Icon } from 'antd';
import ProspectsService from '../../../services/prospects/ProspectsService';
const { TextArea } = Input;
import UserContext from '../../../store/userContext';


class AddProspectsHist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleAddHistModal: this.props.handleAddHistModal,
            reloadList: this.props.reloadList,
            load: false,
            statusTypes: [
              {
                value: 'BC',
                type: 'Por Contactar'
              },
              {
                value: 'CT',
                type: 'Contactado'
              },
              {
                value: 'QD',
                type: 'Cotizado'
              },
              {
                value: 'FQ',
                type: 'Seguimiento a Cotización'
              },
              {
                value: 'IS',
                type: 'Ganado'
              },
              {
                value: 'MG',
                type: 'Perdido'
              },
            ],
            prospectHist: []
        };
    }

    componentDidMount() {
      ProspectsService.getProspects()
        .then(response =>{
            // spinnerService.hide('mySpinner');
            this.setState({
                prospectHist: response.data,
            })
        })
        .catch( error =>{
            // spinnerService.hide('mySpinner');
            this.setState({
                prospectHist: [],
            })
        });
    }

    handleSelectProspect = (prospId) => {
      let renderOptions;

      renderOptions = this.state.prospectHist.map(function (data, idx) {
        if (prospId === data.id){
          return <Option selected value={data.id}>{data.name + " " + data.lastname + " - " + data.business_name}</Option>;
        }
      });
      return renderOptions;
    };

    handleSelectStatusType = () => {
      let renderOptions;

      renderOptions = this.state.statusTypes.map((data, idz) => {
        return <Option value={data.value}>{data.type}</Option>;
      });

      return renderOptions;
    }

    handleChangeType = e =>{
      this.setState({selectValue:e});
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    load: true
                });
                ProspectsService.addProspectHist(values)
                    .then(response => {
                        if (response.status === 201) {
                            notificationWithIcon('success', 'Creación de Historial', 'Se creó la actividad correctamente');
                            this.props.form.resetFields();
                            this.state.handleAddHistModal(false);
                            this.state.reloadList();
                        }
                    })
                    .catch(error => {
                        this.setState({
                            load: false,
                        });
                        notificationWithIcon('error', 'Error', 'Hubo un error al crear la actividad');
                    })
            }
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        return (
          <UserContext.Consumer>
            {(user) =>(
            <Modal
                footer={null}
                title={<div><Icon type="user-add" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} />Agregar Actividad</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.user)}
                onCancel={() => {this.props.form.resetFields(); this.state.handleAddHistModal(false)}}
                okText="Guardar"
                cancelText="Cancelar"
            >

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                     {getFieldDecorator('prospect', {
                      initialValue: this.props.prospectshist.id
                      })(<Input hidden={true} value={this.props.prospectshist.id}/>)}

                    <Form.Item label="Estado" className="form">
                      <div className="form__form-group-field">
                        {getFieldDecorator('status', {
                          validateTrigger: ["onChange", "onBlur"],
                          rules: [
                              {
                                required: true,
                                message: 'Por favor, seleccione el estado de su prospecto',
                              }],
                          })(<Select onChange={this.handleChangeType}
                                placeholder={"Seleccione el estado de su prospecto"}>
                                    {this.handleSelectStatusType()}
                          </Select>
                        )}
                      </div>
                    </Form.Item>

                    <Form.Item label="Descripción">
                      {getFieldDecorator('description', {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, escriba una nota',
                          }
                        ]
                      })(<TextArea rows={4} />)}
                    </Form.Item>

                    {getFieldDecorator('users', {
                      initialValue: this.props.prospectshist.users
                      })(<Input hidden={true} value={this.props.prospectshist.users}/>)}

                    <Form.Item style={{marginLeft: '30%'}}>
                        <Button type="secondary" onClick={() => {this.props.form.resetFields(); this.state.handleAddHistModal(false)}}>
                            Cancelar
                        </Button>
                        <Divider type="vertical"/>
                        <Button icon={"true"} loading={load} htmlType="submit" 
                                style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                            Agregar
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
            )}
          </UserContext.Consumer>
        )
    }
}

const WrappedRegistrationForm = Form.create()(AddProspectsHist);

export default WrappedRegistrationForm;
