/*eslint-disable */
import React from 'react';
import ProspectsService from '../../../services/prospects/ProspectsService';
import UserService from "../../../services/user/UserService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import history from "../../../redux/history";
import { Icon } from 'antd';

// Only for Super Admin
class DeleteProspectsHist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleDeleteHistModal: this.props.handleDeleteHistModal,
            reloadList: this.props.reloadList,
            load: false,
            details: [],
            statusTypes: [
              {
                value: 'BC',
                type: 'Por Contactar'
              },
              {
                value: 'CT',
                type: 'Contactado'
              },
              {
                value: 'QD',
                type: 'Cotizado'
              },
              {
                value: 'FQ',
                type: 'Seguimiento a Cotización'
              },
              {
                value: 'IS',
                type: 'Ganado'
              },
              {
                value: 'MG',
                type: 'Perdido'
              },
            ],
        };

    }

    componentDidMount() {
        ProspectsService.getProspects()
            .then(response => {
                this.setState({
                    details: response.data,
                })
            })
            .catch(error => {
                this.setState({
                    details: [],
                })
            });
    }

    handleDetailsProsp = (id, stType) => {
        let forStatus, detailOpt;

        forStatus = this.state.statusTypes.map((data, ix) => {
            if(stType === data.value){
                return data.type;
            }
        });
        
        detailOpt = this.state.details.map(function (data, idx) {
            if(id === data.id){
                return data.name + " " + data.lastname + " - " + forStatus.join("");
            }
        });

        return detailOpt;
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            this.setState({
                load: true
            });
            ProspectsService.deleteProspectHist(this.props.prospectshist.id)
                .then(response => {
                    if (response.status === 204) {
                        notificationWithIcon('success', 'Actividad Eliminada', 'La actividad se eliminó correctamente');
                        this.state.handleDeleteHistModal(false);
                        this.state.reloadList();
                    }
                })
                .catch(error => {
                    this.setState({
                        load: false
                    });
                    notificationWithIcon('error', 'Error', 'No se pudo eliminar esta actividad');
                })
        });
        setTimeout(() => this.setState({ load: false }), 2000);
    };

    render() {
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        return (
            <Modal
                footer={null}
                title={<div><Icon type="warning" style={{ fontSize: '35px', marginRight:'20px'}}  theme="twoTone" twoToneColor="#ff9818" />Eliminar Actividad</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.prospectshist)}
                onCancel={() => this.state.handleDeleteHistModal(false)}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form.Item>
                    <div style={{ textAlign:'center' }}>
                        <h3>¿Está seguro que desea <b>Eliminar</b> esta actividad? </h3>
                        <h4>({this.handleDetailsProsp(this.props.prospectshist.prospect, this.props.prospectshist.status)})</h4>
                    </div>
                </Form.Item>

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item style={{marginLeft: '30%'}}>
                            <Button type="secondary" onClick={() => this.state.handleDeleteHistModal(false)}>
                                Cancelar
                            </Button>
                            <Divider type="vertical"/>
                            <Button icon={"true"} loading={load} htmlType="submit"
                                    style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                Eliminar
                            </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const WrappedDeleteForm = Form.create()(DeleteProspectsHist);

export default WrappedDeleteForm;