/*eslint-disable */
import axios from 'axios';

const NotificationsService = {

    // GET METHODS
    notificationService: async () => {
        return await axios.get('/api/notifications/')
    },

    notifViewed: async (id) => {
        return await axios.put('/api/notifications/viewed/', {
            id: id
        });
    },

    notifBetween: async (date) => {
        return await axios.get('/api/notifications/bydate/',{
            params:{
                date
            }
        });
    },

    detailLicitaciones: async (code) => {
        return await axios.get('/api/notificationsDetail/lic/', {
            params:{
                code
            }
        });
    },

    detailCompraAgil: async (code) => {
        return await axios.get('/api/notificationsDetail/ca/', {
            params:{
                code
            }
        });
    },

    detailOrdenCompra: async (code) => {
        return await axios.get('/api/notificationsDetail/oc/', {
            params:{
                code
            }
        });
    },

    detailAdjudicacion: async (code) => {
        return await axios.get('/api/notificationsDetail/adj/', {
            params:{
                code
            }
        });
    },
};

export default NotificationsService;