/*eslint-disable */
import {Table, Input, Button, Icon, Divider, Tooltip, Tag} from 'antd';
import React from 'react';
import ProspectsService from '../../../services/prospects/ProspectsService';
import { Card, CardBody, Col} from "reactstrap";
const { Column } = Table;
import AddProspects from './AddProspects';
import EditProspect from  './EditProspect';
import DeleteProspects from './DeleteProspects';
import FormalizeProspect from "./FormalizeProspect";
import AddProspectsHist from './AddProspectsHist';
import DeleteProspectsHist from './DeleteProspectsHist';
import ConsultProspects from './ConsultingProspects';
import PlusIcon from 'mdi-react/PlusIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import {Button as ButtonIcons} from 'reactstrap';
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";
import UserContext from "../../../store/userContext";
import UserService from '../../../services/user/UserService';
import moment, { max } from 'moment';
import 'moment/locale/es';


class ListProspects extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchText: '',
            data:[],
            visible: false,
            selectedObject:[],
            selectedHistObject:[],
            test:'',
            userName: [],
            statusProsp: [],
            statusTypes: [
              {
                value: 'BC',
                type: 'Por Contactar'
              },
              {
                value: 'CT',
                type: 'Contactado'
              },
              {
                value: 'QD',
                type: 'Cotizado'
              },
              {
                value: 'FQ',
                type: 'Seguimiento a Cotización'
              },
              {
                value: 'IS',
                type: 'Ganado'
              },
              {
                value: 'MG',
                type: 'Perdido'
              },
            ],
            fechas: []
        };
    }

    filterStatus = (status) => {
      if (status === "BC"){
          return <span>
              <Tag>
                  Por Contactar
              </Tag>
          </span>
      }
      else if (status ==="CT"){
          return <span>
              <Tag color={'yellow'} style={{color: 'gray'}}>
                  Contactado
              </Tag>
          </span>
      }
      else if (status === "QD") {
          return <span>
              <Tag color={'orange'}>
                  Cotizado
              </Tag>
          </span>
      }
      else if (status === "FQ") {
          return <span>
              <Tag color={'green'} style={{color: 'gray'}}>
                  Seg. a Cotización
              </Tag>
          </span>
      }
      else if (status === "IS") {
          return <span>
              <Tag color={'green'}>
                  Ganado
              </Tag>
          </span>
      } else if (status === "MG") {
          return <span>
              <Tag color={'volcano'}>
                  Perdido
              </Tag>
          </span>
      }
    };

    handleConsultModal = (value) => {
      this.setState({
        consultModalVisible: value
      })
    };

    handleEditModal = (value) => {
        this.setState({
          editModalVisible: value,
        })
    };

    handleAddModal = (value) => {
      this.setState({
          addModalVisible: value,
      })
    };

    handleAddHistModal = (value) => {
      this.setState({
          addHistModalVisible: value,
      })
    };

    handleDeleteModal = (value) => {
        this.setState({
            deleteModalVisible:value,
        })
    };

    handleDeleteHistModal = (value) => {
        this.setState({
            deleteHistModalVisible: value,
        })
    };

    handleFormalizeModal = (value) => {
        this.setState({
            formalizeModalVisible:value,
        })
    };

    handleListReload = () => {
        this.componentDidMount();
    };

    handleChange(event) {
        this.setState({test: event.target.value});
    }

    actionButton = (prospect, action) => {
        this.setState({
          selectedObject:prospect,
        });

        if (action === "edit"){
          this.handleEditModal(true);
        }
        else if(action === "delete"){
          this.handleDeleteModal(true);
        }
        else if (action === "formalize"){
          this.handleFormalizeModal(true);
        }
    };

    actionHistButton = (prospectHist, action) => {
        this.setState({
          selectedObject: prospectHist,
          selectedHistObject: prospectHist,
        });

        if (action === "add"){
          this.handleAddHistModal(true);
        }
        else if(action === "delete"){
          this.handleDeleteHistModal(true);
        }
    };


    componentDidMount() {
        spinnerService.show('mySpinner');
        ProspectsService.getProspects()
          .then(response =>{
              spinnerService.hide('mySpinner');
              this.setState({
                  data: response.data,
              })
          })
          .catch( error =>{
              spinnerService.hide('mySpinner');
              this.setState({
                  data: [],
              })
          });

        UserService.getUser()
          .then(response =>{
            spinnerService.hide('mySpinner');
            this.setState({
                userName: response.data,
            })
          })
          .catch( error =>{
            spinnerService.hide('mySpinner');
            this.setState({
                userName: [],
            })
          });
        
        ProspectsService.getProspectHist()
          .then(response =>{
            spinnerService.hide('mySpinner');
            this.setState({
                statusProsp: response.data,
                fechas: response.data
            })
          })
          .catch( error =>{
            spinnerService.hide('mySpinner');
            this.setState({
                statusProsp: [],
            })
          });
    }

  getColumnSearchProps = (dataIndex, title) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, marginRight: 8 }}>
          Limpiar
        </Button>

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>

      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex] === null ? '' : record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleNameAli = idUser => {
    let renderNames = "";
    for(let key in this.state.userName){
      if(this.state.userName[key].id === idUser){
        renderNames = this.state.userName[key].name + " " + this.state.userName[key].lastname;
        break;
      }
    }

    return renderNames.length > 20 ? `${renderNames.slice(0, 20)}...` : renderNames;
  };
  handleFechas = id => {
    let fechData = [];

    this.state.fechas.map((data, i) => {
      if (id == data.prospect && data.status == 'IS') {
        fechData.push(moment(data.created_at));
      }
    });

    return moment.max(fechData);
  };

  handleStatusProsp = idProsp => {
    let searchStatus = null, maxDate, thisDate, dateHere = [];

    this.state.statusProsp.map((st, id) => {
      if (st.prospect === parseInt(idProsp)){
        dateHere.push(moment(st.created_at));
        maxDate = moment.max(this.handleFechas(st.prospect));
        thisDate = moment.max(dateHere);

        if(st.status === 'MG' && thisDate._i == maxDate._i) {
          return searchStatus = 0;
        } else if(st.status === 'IS' && thisDate._i == maxDate._i){
          return searchStatus = st.prospect;
        }
      }      
    });

    return searchStatus;
  };

  convertDates = (date) => {
    let dateRend;
    dateRend = moment(date).format('DD/MM/YYYY');

    return dateRend;
  };

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} de ${total}`
  };

  expandedRowRender = (userId, prospId, userType, responseData) => {
    let columnsIn;

    const searchStatus = this.state.statusTypes.map(function (data, i) {
      return {text: data.type, value: data.value};
    });
    
    let filter = responseData.filter((item) => {
      return userId === item.users && prospId === item.prospect;
    });

    if (userType === 'A') {
      columnsIn = [
        {title: 'Estado', dataIndex: 'status', key: 'status', filters: searchStatus, render: (text, record) =>(
                  this.filterStatus(record.status)
              )},
        {title: 'Nota', dataIndex: 'description', key: 'description', ...this.getColumnSearchProps('description', 'Nota')},
        {title: 'Fecha de Creación', dataIndex: 'created_at', key: 'created_at', render:(text, record) => (<span>{this.convertDates(record.created_at)}</span>)},
        {title: 'Acciones', dataIndex: 'id', key: 'id', render: (text, record) => (
                <span>
                  <Tooltip placement="top" title="Eliminar" >
                  <Button
                    onClick={() => this.actionHistButton(record, 'delete')}
                    type="warning"
                    shape="circle"
                    icon="delete"
                    size={'large'}
                    style={{
                        paddingBottom:'2.5%',
                        backgroundColor:'#ff4861',
                        color:'white',
                        borderColor:'#ff4861'
                    }}
                  />
                  </Tooltip>
                </span>
              )}
      ];
    } else {
      columnsIn = [
        {title: 'Estado', dataIndex: 'status', key: 'status',filters: searchStatus, render: (text, record) =>(
            this.filterStatus(record.status)
        )},
        {title: 'Nota', dataIndex: 'description', key: 'description', ...this.getColumnSearchProps('description', 'Nota')},
        {title: 'Fecha de Creación', dataIndex: 'created_at', key: 'created_at', render:(text, record) => (<span>{this.convertDates(record.created_at)}</span>)}
      ];
    }

    return (
      <Table columns={columnsIn} dataSource={filter} pagination={{
            defaultPageSize: 10,
            showTotal: this.showTotal,
      }}/>);
  };


  render() {
    const searchUserName = this.state.userName.filter(user => user.type === 'D' || user.type === 'E' || user.type === 'A').map(data => {
      return {text: data.name + " " + data.lastname, value: data.id};
    });

    return(
      <UserContext.Consumer>
        {(user) =>(
        <Col md={12}>
            {
                this.state.editModalVisible ?
                <EditProspect visible={true} reloadList={this.handleListReload} handleEditModal={this.handleEditModal} prospects={this.state.selectedObject}/>
                  :
                <EditProspect visible={false} reloadList={this.handleListReload}  handleEditModal={this.handleEditModal} prospects={this.state.selectedObject}/>
            }

            {
              this.state.consultModalVisible ?
              <ConsultProspects visible={true} reloadList={this.handleListReload} handleConsultModal={this.handleConsultModal}/>
              :
              <ConsultProspects visible={false} reloadList={this.handleListReload} handleConsultModal={this.handleConsultModal}/>
            }

            {
              this.state.addModalVisible ?
                <AddProspects visible={true} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>
                  :
                <AddProspects visible={false} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>

            }
            {
              this.state.addHistModalVisible ?
                <AddProspectsHist visible={true} reloadList={this.handleListReload} handleAddHistModal={this.handleAddHistModal} prospectshist={this.state.selectedObject}/>
                  :
                <AddProspectsHist visible={false} reloadList={this.handleListReload} handleAddHistModal={this.handleAddHistModal} prospectshist={this.state.selectedObject}/>
            }

            {
              this.state.deleteModalVisible ?
              <DeleteProspects visible={true} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} prospects={this.state.selectedObject}/>
                :
              <DeleteProspects visible={false} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} prospects={this.state.selectedObject}/>

            }

            { user.type === 'A' ?
              this.state.deleteHistModalVisible ?
                <DeleteProspectsHist visible={true} reloadList={this.handleListReload} handleDeleteHistModal={this.handleDeleteHistModal} prospectshist={this.state.selectedHistObject}/>
                  :
                <DeleteProspectsHist visible={false} reloadList={this.handleListReload} handleDeleteHistModal={this.handleDeleteHistModal} prospectshist={this.state.selectedHistObject}/>
              :
              ''
            }

            {
              this.state.formalizeModalVisible ?
              <FormalizeProspect visible={true} reloadList={this.handleListReload} handleFormalizeModal={this.handleFormalizeModal} prospects={this.state.selectedObject}/>
                :
              <FormalizeProspect visible={false} reloadList={this.handleListReload} handleFormalizeModal={this.handleFormalizeModal} prospects={this.state.selectedObject}/>
            }

          <Card>
            <CardBody>
              <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>
                <div className="card__title" style={{marginTop: "-20px"}}>
                <h4 className="bold-text">Listado de Prospecto</h4>
                <div style={{float:'right', marginBottom: '20px'}}>
                  <Tooltip placement="bottom" title="Opción para buscar clientes y prospectos en todo el sistema" >
                    <ButtonIcons onClick={() => this.handleConsultModal(true)} className="icon" color="outline-primary"><p><SearchIcon /> Buscar Existentes</p></ButtonIcons>
                  </Tooltip>
                  <ButtonIcons onClick={() => this.handleAddModal(true)} className="icon" color="success" ><p><PlusIcon /> Agregar Prospectos</p></ButtonIcons>
                </div>
              </div>

              <Table dataSource={this.state.data} expandedRowRender={(record) => this.expandedRowRender(record.users, record.id, user.type, this.state.statusProsp)}
                     pagination={{ defaultPageSize: 10, showSizeChanger: true, showTotal: this.showTotal, pageSizeOptions: ['10', '30', '50', '100']}}>

                <Column {...this.getColumnSearchProps('name', 'Nombre')} title="Nombre" dataIndex="name" key="name" 
                        render={(text, rec) => (
                          rec.name ==  null || rec.name == '' ? <span>(sin nombre)</span> : rec.name.length > 20 ? `${text.slice(0, 20)}...` : text
                        )}/>
                <Column {...this.getColumnSearchProps('lastname', 'Apellido')} title="Apellido" dataIndex="lastname" key="lastname" 
                        render={(text, rec) => (
                          rec.lastname == null || rec.lastname == '' ? <span>(sin apellido)</span> : rec.lastname.length > 20 ? `${text.slice(0, 20)}...` : text
                        )}/>
                <Column {...this.getColumnSearchProps('business_name', 'Empresa')} title="Empresa" dataIndex="business_name" key="business_name"
                        render={(text, rec) => (
                          text.length > 20 ? `${text.slice(0, 20)}...` : text
                        )}/>
                {/* <Column {...this.getColumnSearchProps('ruc', 'RUT')} title="RUT" dataIndex="ruc" key="ruc" />
                <Column {...this.getColumnSearchProps('email', 'Correo')} title="Correo" dataIndex="email" key="email" /> */}
                {user.type === 'A' || user.type === 'F' ?
                    <Column filters={searchUserName} onFilter={(value, record) => record.users === value} title="Aliado" dataIndex="users" key="users"
                        render={(text, record) => (
                            <span>
                                {this.handleNameAli(record.users)}
                            </span>
                        )}
                    />
                    : ''}
                <Column title="Fecha de Creación" dataIndex="created_at" key="created_at" render={(text, record) => (<span>{this.convertDates(record.created_at)}</span>)} />
                {user.type === 'A' ?
                    <Column className='actionbtn__table-prosp'
                    render={(text, record) => (
                      <span>
                        <Tooltip placement="top" title="Editar" >
                        <Button
                            onClick={() => this.actionButton(record, 'edit')}
                            type="warning"
                            shape="circle"
                            icon="edit"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                        />
                        </Tooltip>

                        <Divider type="vertical" />
                        {
                          <Tooltip placement="top" title="Eliminar" >
                          <Button
                                onClick={() => this.actionButton(record, 'delete')}
                                type="warning"
                                shape="circle"
                                icon="delete"
                                size={'large'}
                                style={{
                                    paddingBottom:'2.5%',
                                    backgroundColor:'#ff4861',
                                    color:'white',
                                    borderColor:'#ff4861'
                                }}
                            />
                            </Tooltip>
                        }
                        <Divider type="vertical" />
                        {user.type !== 'D' ?
                          this.handleStatusProsp(record.id) === record.id ?
                            <Tooltip placement="top" title="Formalizar" >
                            <Button
                              onClick={() => this.actionButton(record, 'formalize')}
                              type="success"
                              shape="circle"
                              icon="schedule"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#52c41a',
                                  color:'white',
                                  borderColor:'#52c41a' 
                              }}
                            />
                            </Tooltip> :
                            <Tooltip placement="top" title="Formalizar" >
                            <Button
                              type="success"
                              shape="circle"
                              icon="schedule"
                              disabled={true}
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                              }}
                            />
                            </Tooltip>
                            : ''
                        }
                        <Divider type="vertical" />
                        {
                          <Tooltip placement="top" title="Agregar Actividad" >
                          <Button
                            onClick={() => this.actionHistButton(record, 'add')}
                            className="success" type="success" shape="circle" 
                            icon="plus" size="large"
                            style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#c28718',
                                  color:'white',
                                  borderColor:'#c28718' 
                              }}
                          />
                          </Tooltip>
                        }
                      </span>
                    )}
                    title="Acciones"
                    dataIndex="id"
                    key="id"
                  />
                    :
                  <Column
                    render={(text, record) => (
                      <span>
                        <Tooltip placement="top" title="Editar" >
                          <Button
                            onClick={() => this.actionButton(record, 'edit')}
                            type="warning"
                            shape="circle"
                            icon="edit"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                          />
                        </Tooltip>
                        <Divider type="vertical" />
                        {
                          <Tooltip placement="top" title="Agregar Actividad" >
                          <Button
                            onClick={() => this.actionHistButton(record, 'add')}
                            className="success" type="success" shape="circle" 
                            icon="plus" size="large"
                            style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#c28718',
                                  color:'white',
                                  borderColor:'#c28718' 
                              }}
                          />
                          </Tooltip>
                        }
                      </span>
                    )}
                    title="Acciones"
                    dataIndex="id"
                    key="id"
                  />}
              </Table>
            </CardBody>
          </Card>
        </Col>
      )}
      </UserContext.Consumer>
    ); 
  }
}

export default ListProspects;
