/*eslint-disable */
import { Modal, Icon, Button, Form } from 'antd';
import React from 'react';
import { Card, CardBody } from "reactstrap";
import TransactionsServices from '../../../services/webpay_plus/TransactionsServices';
import UserContext from "../../../store/userContext";
import { Spinner, spinnerService } from "@chevtek/react-spinners";
import notificationWithIcon from '../../UI/Notification/components/ColorStates';
import LoadingIcon from "mdi-react/LoadingIcon";
import logo from '../../../shared/img/logo/webpay.png'
import moment from 'moment';
import * as act from "../../../redux/actions/auth";
import history from '../../../redux/history';
import Panel from '../../../shared/components/Panel';


class ListWebpay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      data: [],
      selectedObject: [],
      test: '',
      customers: [],
      valid: false,
      load: false,
      commit: [],
      disB: false,
      allData: {},
      amount: 0
    };
  }

  handleChange(event) {
    this.setState({ test: event.target.value });
  }

  componentDidMount() {
    spinnerService.show('mySpinner');
    TransactionsServices.zohoToken()
      .then(response => {
        spinnerService.hide('mySpinner');
        this.setState({
          allData: response.data.data[0],
          amount: response.data.data[0].Grand_Total
        });
      })
      .catch(error => {
        spinnerService.hide('mySpinner');
        this.setState({
          allData: error.response.status,
          amount: 0,
          disB: true
        });
      });

    this.handleUrlParameter();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      //     if (!err) {
      this.setState({
        load: true
      });
      spinnerService.show('mySpinner');
      TransactionsServices.webpayCreate(values)
        .then(response => {
          localStorage.setItem('urlTr', response.data.url);
          localStorage.setItem('tokenTr', response.data.token);
          spinnerService.hide('mySpinner');
          window.location.reload();
        })
        .catch(er => {
          console.log(er);
          spinnerService.hide('mySpinner');
          notificationWithIcon('error', 'Error', er);
        });
      //     }
    });
    setTimeout(() => this.setState({ load: false }), 1000);
  };

  handleUrlParameter = () => {
    const tokenWS = new URLSearchParams(location.search).get("token_ws");
    const token_tbk = new URLSearchParams(location.search).get("TBK_TOKEN");
    const orden = new URLSearchParams(location.search).get("TBK_ORDEN_COMPRA");
    const session = new URLSearchParams(location.search).get("TBK_ID_SESION");

    if (tokenWS !== null) {
      spinnerService.show('mySpinner');
      TransactionsServices.webpayCommit(tokenWS)
        .then(response => {
          const dataPay = response.data;
          if (dataPay.response_code === 0) {
            this.setState({
              disB: true
            });
            spinnerService.hide('mySpinner');
            let secondsToGo = 8;
            const modal = Modal.success({
              title: 'Pago Realizado con Éxito',
              content: `Por favor, esperar el correo de activación en un tiempo máximo a 48 horas. Se cerrará sesión en ${secondsToGo} segundos`,
              okButtonProps: { disabled: true },
              maskClosable: false
            });
            const timer = setInterval(() => {
              secondsToGo -= 1;
              modal.update({
                content: `Por favor, esperar el correo de activación en un tiempo máximo a 48 horas. Se cerrará sesión en ${secondsToGo} segundos`,
                okButtonProps: { disabled: true },
                maskClosable: false
              });
            }, 1000);
            setTimeout(() => {
              clearInterval(timer);
              modal.destroy();
              act.logout();
              history.push('/');
            }, secondsToGo * 1000);
          } else {
            this.setState({
              disB: false
            });
            notificationWithIcon('error', 'Pago Rechazado', 'Su pago no se pudo realizar, inténte con otro método de pago o contáctenos a ventas@mercadobi.com');
            spinnerService.hide('mySpinner');
            history.push("/dashboard/pages/webpay");
          }
        })
        .catch(error => {
          this.setState({
            load: false,
          });
          notificationWithIcon('error', 'Error', error);
          spinnerService.hide('mySpinner');
        })
    } else if (token_tbk !== null && orden !== null && session !== null) {
      notificationWithIcon('warning', 'Pago Anulado', 'Usted anuló la transacción en la plataforma de pago. Por favor, genere nuevamente su ticket');
      history.push("/dashboard/pages/webpay");
    }
    else if (orden !== null && session !== null & token_tbk === null) {
      notificationWithIcon('error', 'Tiempo Máximo Alcanzado', 'Su pago no pudo ser procesado por exceder el tiempo en realizar la transacción. Inténte nuevamente');
      history.push("/dashboard/pages/webpay");
    }
  }

  handleDeleteAll = () => {
    setTimeout(() => {
      localStorage.removeItem('urlTr');
      localStorage.removeItem('tokenTr');
    }, 4000);
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { load, disB, amount, allData } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        md: { span: 12 },
        lg: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 12 },
        md: { span: 12 },
        lg: { span: 6 }
      },
    };

    return (
      <UserContext.Consumer>
        {(user) => (
          <Card>
            <CardBody>
              <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>
              <div className="text-center" style={{ marginTop: "-35px", paddingBottom: "20px" }}>
                <img src={logo} alt="logo" style={{ width: '200px' }} />
              </div>
              {amount > 0 && user.block_access ?
              <Panel lg={12} xl={12} sm={12} md={12} title={"Estimado cliente"} color="danger" icon="cross-circle"
                subhead={<div>Su acceso a MercadoBI ha sido suspendido por falta de pago. <br />
                  Puede cancelar el saldo pendiente por medio de nuestra pasarela de pago, generando su ticket con el monto presentado. </div>}>
                {localStorage.getItem('tokenTr') === null ?
                  <Form {...formItemLayout} onSubmit={this.handleSubmit} id="formCust">
                    <Form.Item label={<span style={{ fontSize: "18px" }}>Nombre</span>}>
                      <span style={{ fontSize: "18px" }} className="ant-form-text">{user.name + ' ' + user.lastname}</span>
                    </Form.Item>
                    <Form.Item label={<span style={{ fontSize: "18px" }}>No. de Factura</span>}>
                      {getFieldDecorator('no_fact', { initialValue: 'O-' + user.id + '_' + moment().format("L[T]LT") })
                        (<span style={{ fontSize: "18px" }} className="ant-form-text">{'O-' + user.id + '_' + moment().format("L[T]LT")}</span>)}
                    </Form.Item>
                    <Form.Item label={<span style={{ fontSize: "18px" }}>Monto a Pagar</span>}>
                      {getFieldDecorator('amount', { initialValue: amount })(<span style={{ fontSize: "18px" }} className="ant-form-text">{amount.toLocaleString("es-CL") + " " + allData.$currency_symbol}</span>)}
                    </Form.Item>
                    <div className="text-center">
                        <Button type="primary" htmlType="submit" disabled={disB}>
                          Generar Ticket de Pago
                        </Button>
                      <h6><br />Tome en cuenta que la restauración del servicio puede tomar unas 48 horas en completarse.</h6>
                      <h6>Si tiene algún problema con el pago u otro inconveniente, por favor contáctenos a <a href="mailto:ventas@mercadobi.com">ventas@mercadobi.com</a></h6>
                    </div>
                  </Form>
                  :
                  <div>
                    <h3 className="text-success text-center">¡Ticket generado! Por favor haga clic en el botón <b>Pagar</b></h3>
                    <Form {...formItemLayout} id="formCust">
                      <Form.Item label={<span style={{ fontSize: "18px" }}>Nombre</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{user.name + ' ' + user.lastname}</span>
                      </Form.Item>
                      <Form.Item label={<span style={{ fontSize: "18px" }}>No. de Factura</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{'O-' + user.id + '_' + moment().format("L[T]LT")}</span>
                      </Form.Item>
                      <Form.Item label={<span style={{ fontSize: "18px" }}>Monto a Pagar</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{amount.toLocaleString("es-CL") + " " + allData.$currency_symbol}</span>
                      </Form.Item>
                    </Form>
                    <form method="post" action={localStorage.getItem('urlTr')}>
                      <input type="hidden" name="token_ws" value={localStorage.getItem('tokenTr')} />
                      <div className="text-center">
                        <Button type="primary" htmlType="submit" onClick={this.handleDeleteAll()}>
                          Pagar
                        </Button>
                        <h6><br />Tome en cuenta que la restauración del servicio puede tomar unas 48 horas en completarse.</h6>
                        <h6>Si tiene algún problema con el pago u otro inconveniente, por favor contáctenos a <a href="mailto:ventas@mercadobi.com">ventas@mercadobi.com</a></h6>
                      </div>
                    </form>
                  </div>
                }
              </Panel>
              :
              amount > 0 && user.block_access === false ?
               <Panel lg={12} xl={12} sm={12} md={12}color="warning"
                subhead={<div className="text-dark"><Icon type="warning" style={{verticalAlign: "0.1em"}} /> Estimado Cliente <br/>
                  Tiene un saldo pendiente con nosotros. <br />
                  Le agradecemos mantener al día el pago de su suscripción.</div>}>
              {localStorage.getItem('tokenTr') === null ?
                  <Form {...formItemLayout} onSubmit={this.handleSubmit} id="formCust">
                    <Form.Item label={<span style={{ fontSize: "18px" }}>Nombre</span>}>
                      <span style={{ fontSize: "18px" }} className="ant-form-text">{user.name + ' ' + user.lastname}</span>
                    </Form.Item>
                    <Form.Item label={<span style={{ fontSize: "18px" }}>No. de Factura</span>}>
                      {getFieldDecorator('no_fact', { initialValue: 'O-' + user.id + '_' + moment().format("L[T]LT") })
                        (<span style={{ fontSize: "18px" }} className="ant-form-text">{'O-' + user.id + '_' + moment().format("L[T]LT")}</span>)}
                    </Form.Item>
                    <Form.Item label={<span style={{ fontSize: "18px" }}>Monto a Pagar</span>}>
                      {getFieldDecorator('amount', { initialValue: amount })(<span style={{ fontSize: "18px" }} className="ant-form-text">{amount.toLocaleString("es-CL") + " " + allData.$currency_symbol}</span>)}
                    </Form.Item>
                    <div className="text-center">
                        <Button type="primary" htmlType="submit" disabled={disB}>
                          Generar Ticket de Pago
                        </Button>
                      <h6><br />Tome en cuenta que la restauración del servicio puede tomar unas 48 horas en completarse.</h6>
                      <h6>Si tiene algún problema con el pago u otro inconveniente, por favor contáctenos a <a href="mailto:ventas@mercadobi.com">ventas@mercadobi.com</a></h6>
                    </div>
                  </Form>
                  :
                  <div>
                    <h3 className="text-success text-center">¡Ticket generado! Por favor haga clic en el botón <b>Pagar</b></h3>
                    <Form {...formItemLayout} id="formCust">
                      <Form.Item label={<span style={{ fontSize: "18px" }}>Nombre</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{user.name + ' ' + user.lastname}</span>
                      </Form.Item>
                      <Form.Item label={<span style={{ fontSize: "18px" }}>No. de Factura</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{'O-' + user.id + '_' + moment().format("L[T]LT")}</span>
                      </Form.Item>
                      <Form.Item label={<span style={{ fontSize: "18px" }}>Monto a Pagar</span>}>
                        <span style={{ fontSize: "18px" }} className="ant-form-text">{amount.toLocaleString("es-CL") + " " + allData.$currency_symbol}</span>
                      </Form.Item>
                    </Form>
                    <form method="post" action={localStorage.getItem('urlTr')}>
                      <input type="hidden" name="token_ws" value={localStorage.getItem('tokenTr')} />
                      <div className="text-center">
                        <Button type="primary" htmlType="submit" onClick={this.handleDeleteAll()}>
                          Pagar
                        </Button>
                        <h6><br />Tome en cuenta que la restauración del servicio puede tomar unas 48 horas en completarse.</h6>
                        <h6>Si tiene algún problema con el pago u otro inconveniente, por favor contáctenos a <a href="mailto:ventas@mercadobi.com">ventas@mercadobi.com</a></h6>
                      </div>
                    </form>
                  </div>
                }
              </Panel>
              :
               allData == 500 && user.block_access ?
              <Panel lg={12} xl={12} sm={12} md={12} title={"Estimado cliente"} color="danger" icon="cross-circle"
                subhead="Tiene un saldo pendiente con nosotros.">
                    <h3 className="text-danger text-center">Para volver a utilizar su cuenta, debe enviarnos un e-mail a <a href="mailto:ventas@mercadobi.com">ventas@mercadobi.com</a> </h3>
                    <h3 className="text-danger text-center"> o llamarnos al <b>(2) 2938 1288</b></h3>
                    <div className="text-center">
                      <h4><br />Tome en cuenta que la restauración del servicio puede tomar unas 48 horas en completarse.</h4>
                    </div>
              </Panel>
              :
               amount === 0 ?
              <Panel lg={12} xl={12} sm={12} md={12} color="success"
                subhead={<div className="text-dark"><Icon type="check-circle" style={{verticalAlign: "0.1em"}} /> Estimado Cliente <br/>
                  Usted se encuentra Paz y Salvo. <br />
                  ¡Que tenga buen día! </div>}>
              </Panel>
              :
               ''
              }
            </CardBody>
          </Card>
        )}
      </UserContext.Consumer>
    );
  }
}

const WrappedWebPayForm = Form.create()(ListWebpay);

export default WrappedWebPayForm;
