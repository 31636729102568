/*eslint-disable */
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Col, Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import * as actions from "../../../redux/actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Change_Password from '../../UserProfile/components/Change_Password';
import UserContext from "../../../store/userContext";
import { Menu, Icon } from 'antd';
const { SubMenu } = Menu;
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;


class TopbarProfile extends PureComponent {

  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      editModalVisible: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  handleAddModal = (value) => {
    this.setState({
      editModalVisible: value,
    });

  };

  handleChange(event) {
    this.setState({ test: event.target.value });
  }


  render() {
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <Menu style={{ position: "inherit", top: "-3px" }} mode="horizontal">
          <SubMenu className="customclass"
            title={
              <span className="submenu-title-wrapper topbar__avatar" onClick={this.toggle}>
                <img className="topbar__avatar-img" src={Ava} alt="avatar" />
                <p className="topbar__avatar-name">
                  <UserContext.Consumer>
                    {(user) => (
                      <div>
                        {user.name} {user.lastname}
                      </div>
                    )}
                  </UserContext.Consumer>
                </p>
                <DownIcon className="topbar__icon" />
              </span>
            }
          >
            <Menu.Item onClick={() => this.handleAddModal(true)} path={Change_Password}><Icon type="profile" style={{ fontSize: '23px', marginRight: '10px' }} />Cambiar Contraseña</Menu.Item>
            <Menu.Item onClick={this.props.logout} path="/"><Icon type="logout" style={{ fontSize: '23px', marginRight: '10px' }} />Cerrar Sesión</Menu.Item>
          </SubMenu>
        </Menu>
        {
          this.state.editModalVisible ?
            <Change_Password visible={true} handleEditModal={this.handleAddModal}></Change_Password>
            :
            <Change_Password visible={false} handleEditModal={this.handleAddModal} ></Change_Password>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  }
};


export default connect(null, mapDispatchToProps)(TopbarProfile);
