/*eslint-disable */

import React from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import Dash_CurrentUsers from './components/Dashboard';
// import { Report } from 'powerbi-report-component';


const Dashboard = () => (
  <Container className="dashboard">
    <Dash_CurrentUsers />
  </Container>
);

export  {Dashboard};