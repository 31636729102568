/*eslint-disable */
import React from 'react';
import {Button, Modal, Table, Icon, Divider, Descriptions} from "antd";
const { Column } = Table;
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import '../../../scss/detailNotifications.css';


class InfoOrdenCompra extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            OCDetail: [],
            codeDetail: null,
            visible: true,
            handleInfoOCModal: this.props.handleInfoOCModal,
            reloadList: this.props.reloadList,
            load: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.codeDetail !== prevProps.code) {
            this.setState({
                OCDetail: this.props.detail,
                codeDetail: this.props.code
            });
        }
    }

    detailComprador = () => {
        let comprador;

        comprador = this.state.OCDetail.map(data1 => {
            return data1.detail.map(data2 => {
                return (
                    <div style={{padding: "0 12px", fontSize: "12px"}}>
                        {/* <Row>
                            <h4>"{data2.nombre}"</h4><br/>
                        </Row>
                        <Row><p>Recuerde que el responsable del pago es {data2.nombreorganismo}</p></Row> */}
                        <Row>
                            <Col lg={6}>
                                <Divider plain style={{margin: "0"}}><h5>Información</h5></Divider>
                                {/* <p><b>Número:</b> {data2.codigo}</p> */}
                                <p><b>Estado:</b> {data2.estado}</p>
                                <p><b>Fecha de envío:</b> {moment(data2.fechaenvio).format("DD-MM-YYYY")}</p>
                                <p><b>Nombre:</b> {data2.nombre}</p>

                                <Divider plain style={{margin: "10px 0"}}><h5>Datos del Comprador</h5></Divider>
                                <p><b>Unidad de compra:</b> {data2.nombreunidad}</p>
                                <p><b>Razón social:</b> {data2.nombreorganismo}</p>
                                <p><b>R.U.T.:</b> {data2.rutunidad}</p>
                                <p><b>Dirección:</b> {data2.direccionunidad}</p>
                            </Col>
                            <Col lg={6}>
                                <Divider plain style={{margin: "0"}}><h5>Datos de Pago y Facturación</h5></Divider>
                                <p><b>Plazo de Pago:</b> {data2.plazopago}</p>
                                <p><b>Moneda:</b> {data2.desctipomoneda}</p>
                                <p><b>Razón social:</b> {data2.nombreorganismo}</p>
                                <p><b>R.U.T.:</b> {data2.rutunidad}</p>
                                <p><b>Dirección de facturación:</b> {data2.direccionunidad}</p>
                                <p><b>Comuna:</b> {data2.comunaunidad}</p>
                                <p><b>Dirección de Envío de Factura:</b> {data2.direccionunidad}</p>
                            </Col>
                        </Row>
                    </div>				
                )
            });
        });

        return comprador;

    }

    detailItems = () => {
        let items;

        items = this.state.OCDetail.map(data1 => {
            const summary = data1.detail.map(x => {
                return (<div style={{marginLeft: "845px"}}>
                    <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} style={{background: "#fff", fontSize: "10px"}} size="small">
                        <Descriptions.Item label="Total Neto">$ {x.totalneto.toLocaleString("es-CL")}</Descriptions.Item>
                        <Descriptions.Item label="Descuento">$ {x.descuentos.toLocaleString("es-CL")}</Descriptions.Item>
                        <Descriptions.Item label="Cargos">$ {x.cargos.toLocaleString("es-CL")}</Descriptions.Item>
                        {x.porcentajeiva > 0 ?
                        <Descriptions.Item label={"IVA " + x.porcentajeiva + " %"}>$ {x.impuestos.toLocaleString("es-CL")}</Descriptions.Item> 
                        : 
                        <Descriptions.Item label={"Exento " + x.porcentajeiva + " %"}>$ {x.impuestos.toLocaleString("es-CL")}</Descriptions.Item>}
                        <Descriptions.Item className="fontSize__description-view" label="Total OC">$ {x.totaloc.toLocaleString("es-CL")}</Descriptions.Item>
                    </Descriptions>
                </div>)
            });
            return (<Table dataSource={data1.items} scroll={{ y: 100 }} rowClassName="fontSize__table" size="small" 
                           pagination={false} footer={() => summary}
                           title={() => 'Items Solicitados'}>
                        <Column title="Código ONU" dataIndex="codigoproducto" key="codigoproducto" width="7%"/>
                        <Column title="Producto / Servicio" dataIndex="nombreproducto" key="nombreproducto" width="10%" />
                        <Column title="Cant." dataIndex="cantidad" key="cantidad" width="5%" />
                        <Column title="Medida" dataIndex="unidadmedida" key="unidadmedida" width="6%" />
                        <Column title="Esp. Comprador" dataIndex="especificacioncomprador" key="especificacioncomprador" width="15%"/>
                        <Column title="Esp. Proveedor" dataIndex="especificacionproveedor" key="especificacionproveedor" width="15%"/>
                        <Column title="Precio Unit." dataIndex="preciounitario" key="preciounitario" width="10%"
                                render={(text, record) => (record.preciounitario.toLocaleString("es-CL"))}/>
                        <Column title="Desc." dataIndex="preciodescuentos" key="preciodescuentos" width="10%"
                                render={(text, record) => (record.preciodescuentos.toLocaleString("es-CL"))}/>
                        <Column title="Cargos" dataIndex="preciocargos" key="preciocargos" width="10%"
                                render={(text, record) => (record.preciocargos.toLocaleString("es-CL"))}/>
                        <Column title="Total Unit." dataIndex="preciototal" key="preciototal" width="10%"
                                render={(text, record) => (record.preciototal.toLocaleString("es-CL"))}/>
                        <Column title="Valor Total" dataIndex="preciototal" key="preciototal" width="10%"
                                render={(text, record) => (record.preciototal.toLocaleString("es-CL"))}/>
                    </Table>)
        });

        return items;

    }

    showTotal = (total, range) => {
        return `${total} items`
    }


    render() {

        return (
            <Modal
                title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Detalle de la Orden de Compra Nº {this.props.code}</div>}
                visible={this.props.visible}
                width={'1200px'}
                onCancel={() => {this.state.handleInfoOCModal(false)}}
                footer={[<Button type="secondary" onClick={() => {this.state.handleInfoOCModal(false)}}>
                            Cerrar
                        </Button>]}
            >
                {this.state.OCDetail.length == 0 || this.props.code !== this.state.codeDetail ? 
                    <div style={{textAlign: "center", margin: "10px 30px", paddingTop: "70px"}}>
                        <div style={{align: "center"}} className="panel__refresh"><LoadingIcon /></div>
                        Cargando información de Orden de Compra {this.props.code} ...
                    </div>
                : 
                <span>
                    {this.detailComprador()}
                    <br />
                    <Row>
                        {this.detailItems()}
                    </Row>
                </span>
                }
            </Modal>
        )
    }
}

export default InfoOrdenCompra;
