/*eslint-disable */
import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './redux/reducers/auth'

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhances(
    applyMiddleware(thunk)
));

render(
    <Provider store={store}>
        <App />
    </Provider>,
  document.getElementById('root'),
);
