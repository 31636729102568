/*eslint-disable */
import React from 'react';
// import { Link } from 'react-router-dom';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
// import LogInForm from './components/LogInForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import logo from "../../shared/img/logo/logoShort.png";
import {withRouter} from "react-router-dom";

const ResetPassword = () => (
  <div className="account">
    <img src={logo} alt="Mercado BI" style={{ width: '12%', margin: '35px', position: 'absolute', right: '3%' }} />
    <div className="account__wrapper">
      <div className="account__card">
        <h4 style={{ textAlign: 'center', marginBottom: '30px' }}>Recuperar Contraseña</h4>
        <ResetPasswordForm onSubmit />
        {/* <div className="text-center">
          <p>Powered by</p>
          <a href="https://www.gbitcorp.com/">
            <img src={logo} alt="logo" style={{ width: '40%' }} />
          </a>
        </div> */}
      </div>
    </div>
  </div>
);



export default withRouter(ResetPassword);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
