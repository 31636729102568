import React from 'react';
import { Container, Row } from 'reactstrap';
import ListClients from './components/ListClients';

const ClientsPage = () => (
  <Container className="dashboard">
    <Row>
      <ListClients />
    </Row>
  </Container>
);

export default ClientsPage;
