/*eslint-disable */
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Modal} from "antd";
import { Icon } from 'antd';


class ActivateClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleActivateModal: this.props.handleActivateModal,
            reloadList: this.props.reloadList,
            load: false,
        };

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(() => {
            const values = {
                is_active: true
            };
            this.setState({
                load: true,
            });
            CustomerService.activateClient(values, this.props.customer.id)
                .then(response => {
                    if (response.status === 200) {
                        notificationWithIcon('success', 'Cliente Activado', `Se activaron todos los usuarios asociados a ${this.props.customer.customer}`);
                        this.state.handleActivateModal(false);
                        this.state.reloadList();
                    }
                })
                .catch(error => {
                    this.setState({
                        load: false,
                    });
                    notificationWithIcon('error', 'Error', 'No se pudo activar al cliente, inténtelo más tarde');
                })
        });
        setTimeout(() => this.setState({ load: false }), 3000);
    };

    render() {
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const users = this.props.customer.users !== undefined ? this.props.customer.users.length : undefined;

        return (
            <Modal
                footer={null}
                title={<div><Icon type="link" style={{ fontSize: '35px', marginRight:'20px', color:'#3498db'}}/>Activar Cliente</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.customer)}
                onCancel={() => this.state.handleActivateModal(false)}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form.Item>
                    <div style={{ textAlign:'center' }}>
                        <h3>¿Está seguro de activar al cliente <b>{this.props.customer.customer}</b>? </h3>
                        {users > 1 ?
                            <h4>(Activará a {users} usuarios)</h4>
                            :
                        users === 1 ?
                            <h4>(Activará a un usuario)</h4>
                            :
                            <h4>(No tiene usuarios asociados)</h4>
                        }
                    </div>
                </Form.Item>

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item style={{marginLeft: '30%'}}>
                        <Button type="secondary" onClick={() => this.state.handleActivateModal(false)}>
                            Cancelar
                        </Button>
                        <Divider type="vertical"/>
                        <Button icon={"true"} loading={load} htmlType="submit"
                                style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                            Activar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const WrappedActivateClientForm = Form.create()(ActivateClient);

export default WrappedActivateClientForm;