/*eslint-disable */
import {Table, Input, Button, Icon, Divider, Tooltip, Tag} from 'antd';
import React from 'react';
import CustomerService from "../../../services/customer/CustomerService";
import { Card, CardBody, Col} from "reactstrap";
const { Column } = Table;
import AddClient from './AddClient';
import EditClient from  './EditClient';
import DeleteClient from './DeleteClient';
import UnblockAccessClient from './UnblockAccessClient';
import BlockAccessClient from './BlockAccessClient';
import ActivateClient from './ActivateClient';
import DeactivateClient from './DeactivateClient';
import InfoClient from './InfoClient';
import PlusIcon from 'mdi-react/PlusIcon';
import {Button as ButtonIcons} from 'reactstrap';
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";
import UserContext from "../../../store/userContext";


class ListClients extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchText: '',
            data:[],
            visible: false,
            selectedObject:[],
            test:'',
        };
    }

    // handleEditModal = (value) => {
    //     this.setState({
    //       editModalVisible: value,
    //     })
    // };

    handleInfoModal = (value) => {
        this.setState({
          infoModalVisible: value,
        })
    };

    handleAddModal = (value) => {
      this.setState({
          addModalVisible: value,
      })
    };

    handleDeleteModal = (value) => {
        this.setState({
            deleteModalVisible:value,
        })
    };

    handleBlockAccessModal = (value) => {
        this.setState({
            blockAccessModalVisible:value,
        })
    };

    handleUnblockAccessModal = (value) => {
        this.setState({
            unblockAccessModalVisible:value,
        })
    };

    handleDeactivateModal = (value) => {
        this.setState({
            deactivateModalVisible:value,
        })
    };

    handleActivateModal = (value) => {
        this.setState({
            activateModalVisible:value,
        })
    };

    handleListReload = () => {
        this.componentDidMount();
    };

    handleChange(event) {
        this.setState({test: event.target.value});
    }

    actionButton = (client, action) => {
        this.setState({
          selectedObject:client,
        });

        // if (action === "edit"){
        //     this.handleEditModal(true);
        // }
        if (action === "info"){
            this.handleInfoModal(true);
        }
        else if(action === "delete"){
            this.handleDeleteModal(true);
        } else if(action === "block"){
          this.handleBlockAccessModal(true);
        } else if(action === "unblock"){
          this.handleUnblockAccessModal(true);
        } else if(action === "deactivate"){
          this.handleDeactivateModal(true);
        } else if(action === "activate"){
          this.handleActivateModal(true);
        }
      };


    componentDidMount() {
      spinnerService.show('mySpinner');
      CustomerService.getCustomers()
        .then(response =>{
          spinnerService.hide('mySpinner');
          this.setState({
              data: response.data,
          });
        })
        .catch( error =>{
          spinnerService.hide('mySpinner');
          this.setState({
              data: [],
          });
        });
    }

  getColumnSearchProps = (dataIndex, title) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, marginRight: 8 }}>
          Limpiar
        </Button>

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>

      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const showTotal = (total, range) => {
      return `${range[0]}-${range[1]} de ${total}`
    };

      return(
        <UserContext.Consumer>
                {(user) =>(
          <Col md={12}>
              {/* {
                this.state.editModalVisible ?
                <EditClient visible={true} reloadList={this.handleListReload} handleEditModal={this.handleEditModal} customer={this.state.selectedObject}/>
                  :
                <EditClient visible={false} reloadList={this.handleListReload}  handleEditModal={this.handleEditModal} customer={this.state.selectedObject}/>
              } */}

              {
                this.state.infoModalVisible ?
                  <InfoClient visible={true} reloadList={this.handleListReload} handleInfoModal={this.handleInfoModal} customer={this.state.selectedObject}/>
                    :
                  <InfoClient visible={false} reloadList={this.handleListReload}  handleInfoModal={this.handleInfoModal} customer={this.state.selectedObject}/>
              }

              {
                this.state.addModalVisible ?
                <AddClient visible={true} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>
                  :
                <AddClient visible={false} reloadList={this.handleListReload} handleAddModal={this.handleAddModal}/>
              }

              {
                this.state.deleteModalVisible ?
                <DeleteClient visible={true} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} customer={this.state.selectedObject}/>
                  :
                <DeleteClient visible={false} reloadList={this.handleListReload} handleDeleteModal={this.handleDeleteModal} customer={this.state.selectedObject}/>
              }

              {
                this.state.blockAccessModalVisible ?
                <BlockAccessClient visible={true} reloadList={this.handleListReload} handleBlockAccessModal={this.handleBlockAccessModal} customer={this.state.selectedObject}/>
                  :
                <BlockAccessClient visible={false} reloadList={this.handleListReload} handleBlockAccessModal={this.handleBlockAccessModal} customer={this.state.selectedObject}/>
              }

              {
                this.state.unblockAccessModalVisible ?
                <UnblockAccessClient visible={true} reloadList={this.handleListReload} handleUnblockAccessModal={this.handleUnblockAccessModal} customer={this.state.selectedObject}/>
                  :
                <UnblockAccessClient visible={false} reloadList={this.handleListReload} handleUnblockAccessModal={this.handleUnblockAccessModal} customer={this.state.selectedObject}/>
              }

              {
                this.state.deactivateModalVisible ?
                <DeactivateClient visible={true} reloadList={this.handleListReload} handleDeactivateModal={this.handleDeactivateModal} customer={this.state.selectedObject}/>
                  :
                <DeactivateClient visible={false} reloadList={this.handleListReload} handleDeactivateModal={this.handleDeactivateModal} customer={this.state.selectedObject}/>
              }

              {
                this.state.activateModalVisible ?
                <ActivateClient visible={true} reloadList={this.handleListReload} handleActivateModal={this.handleActivateModal} customer={this.state.selectedObject}/>
                  :
                <ActivateClient visible={false} reloadList={this.handleListReload} handleActivateModal={this.handleActivateModal} customer={this.state.selectedObject}/>
              }

            <Card>
              <CardBody>
                <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>
                  <div className="card__title" style={{marginTop: "-20px"}}>
                    <h4 className="bold-text">Listado de Clientes</h4>
                    {user.type == 'A' || user.type == 'F' ?
                    <ButtonIcons onClick={() => this.handleAddModal(true)} className="icon" color="success" style={{float:'right', marginBottom: '20px'}} ><p><PlusIcon /> Agregar Cliente</p></ButtonIcons>
                    : ''}
                  </div>
                  <Table dataSource={this.state.data} pagination={{ defaultPageSize: 10, showSizeChanger: true, showTotal: showTotal, pageSizeOptions: ['10', '30', '50', '100']}}>

                  <Column {...this.getColumnSearchProps('customer', 'Cliente')} title="Cliente" dataIndex="customer" key="customer" 
                          render={(text, rec) => (
                            text.length > 20 ? `${text.slice(0, 20)}...` : text
                          )}/>
                  {/* <Column {...this.getColumnSearchProps('ruc', 'RUT')} title="RUT" dataIndex="ruc" key="ruc" /> */}
                  <Column {...this.getColumnSearchProps('email', 'Correo')} title="Correo" dataIndex="email" key="email" 
                          render={(text, rec) => (
                            text.length > 20 ? `${text.slice(0, 20)}...` : text
                          )}/>
                  <Column {...this.getColumnSearchProps('ruc', 'RUT')} title="RUT" dataIndex="ruc" key="ruc" />
                  {/* <Column {...this.getColumnSearchProps('phone', 'Teléfono')} title="Teléfono" dataIndex="phone" key="phone" /> */}
                  {user.type === 'A' || user.type === 'F' ?
                  <Column title="No. de Usuarios"
                          render={(text, record) => (
                              <span>
                                  {record.users.length}
                              </span>
                    )}
                  /> : ""}
                  <Column className='theadtbl__tables'
                    render={(text, record) => (
                      <span>
                        { record.is_blocked && record.users.length > 0 ?
                          <Tooltip placement="top" title="Cliente bloqueado por falta de pago u otros asuntos">
                            <Tag color={'volcano'}>
                              BLOQUEADO
                            </Tag>
                          </Tooltip>     
                        // :
                        // !record.is_blocked && record.users.length > 0 ?
                        // <Tooltip placement="top" title="Cliente está OK!">
                        //   <Tag color={'green'}>
                        //     DESBLOQUEADO
                        //   </Tag>
                        // </Tooltip>
                        :
                        record.users.length == 0 ?
                          <Tooltip placement="top" title="Cliente sin usuarios relacionados">
                            <Tag color={'gold'}>
                              SIN USUARIOS
                            </Tag>
                          </Tooltip>
                        :
                        ""
                        }
                        {record.is_active ?
                        <Tooltip placement="top" title="Cliente usando la aplicación">
                          <Tag color={'#3498db'}>
                            ACTIVO
                          </Tag>
                        </Tooltip> : 
                        <Tooltip placement="top" title="Cliente inactivo">
                            <Tag color={'#999999'}>
                              INACTIVO
                            </Tag>
                          </Tooltip>}
                      </span>
                    )}
                  title="Estado"
                  dataIndex="is_blocked"
                  key="is_blocked" />
                  {user.type == 'A' ?
                  <Column
                    render={(text, record) => (
                      <span>
                        {/* <Tooltip placement="top" title="Editar" >
                        <Button
                            onClick={() => this.actionButton(record, 'edit')}
                            type="warning"
                            shape="circle"
                            icon="edit"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                        />
                        </Tooltip> */}
                        <Tooltip placement="top" title="Ver Información" >
                        <Button
                            onClick={() => this.actionButton(record, 'info')}
                            type="warning"
                            shape="circle"
                            icon="eye"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                        />
                        </Tooltip>

                        <Divider type="vertical" />

                        {
                          record.users.length === 0 ?
                            <Tooltip placement="top" title="Eliminar" >
                            <Button
                              onClick={() => this.actionButton(record, 'delete')}
                              type="warning"
                              shape="circle"
                              icon="delete"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#ff4861',
                                  color:'white',
                                  borderColor:'#ff4861'
                              }}
                            />
                            </Tooltip>
                            :
                            <Tooltip placement="top" title="Eliminar" >
                            <Button
                              type="warning"
                              shape="circle"
                              icon="delete"
                              disabled={true}
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                              }}
                          />
                          </Tooltip>
                        }

                        <Divider type="vertical" />

                        {
                          record.users.length === 0 ?
                          <Tooltip placement="top" title="Bloquear Cliente" >
                          <Button
                            onClick={() => this.actionButton(record, "block")}
                            type="warning"
                            shape="circle"
                            icon="stop"
                            size={'large'}
                            disabled={true}
                            style={{paddingBottom:'2.5%'}}
                          />
                          </Tooltip>
                          :
                          record.is_blocked === false ?
                          <Tooltip placement="top" title="Bloquear Cliente" >
                          <Button
                            onClick={() => this.actionButton(record, "block")}
                            type="warning"
                            shape="circle"
                            icon="stop"
                            size={'large'}
                            disabled={user.customer !== record.id ? false : true}
                            style={user.customer !== record.id ? {
                              paddingBottom:'2.5%',
                              backgroundColor:'#f18517',
                              color:'white',
                              borderColor:'#f18517'
                              } : {paddingBottom:'2.5%'}}
                          />
                          </Tooltip>
                          :
                          <Tooltip placement="top" title="Desbloquear Cliente" >
                          <Button
                                onClick={() => this.actionButton(record, "unblock")}
                                type="success"
                                shape="circle"
                                icon="check"
                                size={'large'}
                                style={{
                                    paddingBottom:'2.5%',
                                    backgroundColor:'#52c41a',
                                    color:'white',
                                    borderColor:'#52c41a'
                                }}
                            />
                          </Tooltip> 
                        }

                        <Divider type="vertical" />

                        {record.is_active ? 
                          <Tooltip placement='top' title="Desactivar cliente">
                            <Button 
                              onClick={() => this.actionButton(record, "deactivate")}
                              type="success"
                              shape="circle"
                              icon="disconnect"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#999999',
                                  color:'white',
                                  borderColor:'#999999'
                              }}
                            />
                          </Tooltip> :
                          <Tooltip placement='top' title="Activar cliente">
                            <Button 
                              onClick={() => this.actionButton(record, "activate")}
                              type="success"
                              shape="circle"
                              icon="link"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#3498db',
                                  color:'white',
                                  borderColor:'#3498db'
                              }}
                            />
                          </Tooltip>}
                      </span>
                    )}
                    title="Acciones"
                    dataIndex="id"
                    key="id"/>
                    :
                    user.type === 'F' ?
                    <Column
                    render={(text, record) => (
                      <span>
                        <Tooltip placement="top" title="Ver Información" >
                        <Button
                            onClick={() => this.actionButton(record, 'info')}
                            type="warning"
                            shape="circle"
                            icon="eye"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                        />
                        </Tooltip>

                        <Divider type="vertical" />

                        {
                          record.users.length === 0 ?
                          <Tooltip placement="top" title="Bloquear Cliente" >
                          <Button
                            onClick={() => this.actionButton(record, "block")}
                            type="warning"
                            shape="circle"
                            icon="stop"
                            size={'large'}
                            disabled={true}
                            style={{paddingBottom:'2.5%'}}
                          />
                          </Tooltip>
                          :
                          record.is_blocked === false ?
                          <Tooltip placement="top" title="Bloquear Cliente" >
                          <Button
                            onClick={() => this.actionButton(record, "block")}
                            type="warning"
                            shape="circle"
                            icon="stop"
                            size={'large'}
                            disabled={user.customer !== record.id ? false : true}
                            style={user.customer !== record.id ? {
                              paddingBottom:'2.5%',
                              backgroundColor:'#f18517',
                              color:'white',
                              borderColor:'#f18517'
                              } : {paddingBottom:'2.5%'}}
                          />
                          </Tooltip>
                          :
                          <Tooltip placement="top" title="Desbloquear Cliente" >
                          <Button
                                onClick={() => this.actionButton(record, "unblock")}
                                type="success"
                                shape="circle"
                                icon="check"
                                size={'large'}
                                style={{
                                    paddingBottom:'2.5%',
                                    backgroundColor:'#52c41a',
                                    color:'white',
                                    borderColor:'#52c41a'
                                }}
                            />
                          </Tooltip> 
                        }

                        <Divider type="vertical" />

                        {record.is_active ? 
                          <Tooltip placement='top' title="Desactivar cliente">
                            <Button 
                              onClick={() => this.actionButton(record, "deactivate")}
                              type="success"
                              shape="circle"
                              icon="disconnect"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#999999',
                                  color:'white',
                                  borderColor:'#999999'
                              }}
                            />
                          </Tooltip> :
                          <Tooltip placement='top' title="Activar cliente">
                            <Button 
                              onClick={() => this.actionButton(record, "activate")}
                              type="success"
                              shape="circle"
                              icon="link"
                              size={'large'}
                              style={{
                                  paddingBottom:'2.5%',
                                  backgroundColor:'#3498db',
                                  color:'white',
                                  borderColor:'#3498db'
                              }}
                            />
                          </Tooltip>}
                      </span>
                    )}
                    title="Acciones"
                    dataIndex="id"
                    key="id"/>
                    :
                    <Column
                    render={(text, record) => (
                      <span>
                        <Tooltip placement="top" title="Ver Información" >
                        <Button
                            onClick={() => this.actionButton(record, 'info')}
                            type="warning"
                            shape="circle"
                            icon="eye"
                            size={'large'}
                            style={{
                                paddingBottom:'2.5%',
                                backgroundColor:'#70bbfd',
                                color:'white',
                                borderColor:'#70bbfd'
                            }}
                        />
                        </Tooltip>
                      </span>
                    )}
                    title="Acciones"
                    dataIndex="id"
                    key="id"/>
                }
                  </Table>
              </CardBody>
            </Card>
          </Col>
         )}
        </UserContext.Consumer>
      );
  }
}

export default ListClients;
