/*eslint-disable */
import React from 'react';
import UserService from "../../../services/user/UserService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import { Icon } from 'antd';


class ActivateUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            handleActivateModal: this.props.handleActivateModal,
            reloadList: this.props.reloadList,
            load: false,
        };

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(() => {
            const values = {
                name: this.props.user.name,
                email: this.props.user.email,
                block_access: false
            };
            this.setState({
                load: true,
            });
            UserService.activate_user(values, this.props.user.id)
                .then(response => {
                    if (response.status === 200) {
                        notificationWithIcon('success', 'Usuario dado de Alta', 'Se envió un correo a '+this.props.user.email+' de inicio de sesión');
                        this.state.handleActivateModal(false);
                        this.state.reloadList();
                    }
                })
                .catch(error => {
                    this.setState({
                        load: false,
                    });
                    notificationWithIcon('error', 'Error', 'No se pudo dar de alta al usuario, inténtelo más tarde');
                })
        });
        setTimeout(() => this.setState({ load: false }), 3000);
    };

    render() {
        const {load} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        return (
            <Modal
                footer={null}
                title={<div><Icon type="check-circle" style={{ fontSize: '35px', marginRight:'20px'}}  theme="twoTone" twoToneColor="#52c41a" />Activar Usuario</div>}
                visible={this.props.visible}
                onOk={() => this.handleOk(this.props.user)}
                onCancel={() => this.state.handleActivateModal(false)}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form.Item>
                    <div style={{ textAlign:'center' }}>
                        <h3>¿Está seguro de activar al usuario <b>{this.props.user.email}</b>? </h3>
                        <h4>({this.props.user.name + " " + this.props.user.lastname })</h4>
                    </div>
                </Form.Item>

                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                    <Form.Item style={{marginLeft: '30%'}}>
                            <Button type="secondary" onClick={() => this.state.handleActivateModal(false)}>
                                Cancelar
                            </Button>
                            <Divider type="vertical"/>
                            <Button icon={"true"} loading={load} htmlType="submit"
                                    style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                Activar
                            </Button>
                    </Form.Item>

                </Form>


            </Modal>
        )
    }
}

const WrappedActivateForm = Form.create()(ActivateUser);

export default WrappedActivateForm;