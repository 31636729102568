/*eslint-disable */
import React, { PureComponent } from 'react';
// import { Field } from 'redux-form';
// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import {Form, Input} from "antd";
import * as actions from "../../../redux/actions/auth";
import {connect} from "react-redux";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import notificationWithIcon from '../../UI/Notification/components/ColorStates'

class ConfirmPasswordForm extends PureComponent {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err && (values.pass1 === values.pass2)) {
        this.props.onConfirmPassword(values.pass1, values.pass2);
      }
      else {
        notificationWithIcon('error','Error', 'La contraseña escrita no coincide. Inténtelo de nuevo');
      }
    });
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const buttonStyle = {
      marginLeft: '2%',
      marginRight: '2%',
    };

    const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;

    const pass1Error = isFieldTouched('pass1') && getFieldError('pass1');
    const pass2Error = isFieldTouched('pass2') && getFieldError('pass2');
    // const { showPassword } = this.state;

    const passTrim = (e) => {
      let str= e.target.value;
      e.target.value = str.replace(/ /g, "");
    }

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Nueva Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <div validateStatus={pass1Error ? 'error' : ''} help={pass1Error || ''} style={{width:'100%'}} >
                  {getFieldDecorator('pass1', {
                                rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <Input.Password onChange={passTrim} name="pass1" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%-/*?&])[A-Za-z\d@#$!_%-/*?&]{8,}$"  title="No coinciden o no tiene el formato requerido" required/>
                  )}
              </div>
          </div>

            <span className="form__form-group-label">Repetir Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>

            <div validateStatus={pass2Error ? 'error' : ''} help={pass2Error || ''} style={{width:'100%'}} >
                  {getFieldDecorator('pass2', {
                                rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <Input.Password onChange={passTrim} name="pass2" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!_%-/*?&])[A-Za-z\d@#$!_%-/*?&]{8,}$" title="No coinciden o no tiene el formato requerido" required/>
                  )}
              </div>
          </div>
        </div>
        <p><h6>Use 8 o más caracteres de longitud, al menos 1 mayúscula, 1 número y 1 carácter especial (@#$!_%-/*?).</h6></p>
        <div className="form__form-group">
          <div className="form__form-group-field" style={{ visibility: 'hidden' }}>

          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <Link style={buttonStyle} className="btn btn-secondary account__btn account__btn--small" to="/">Cancelar</Link>
            <button
              className="btn btn-primary account__btn account__btn--small"
              type="primary"
              htmltype="submit"
            >
              Cambiar
          </button>
          </div>
        </div>
      </form>
    );
  }
}

const WrappedConfirmPasswordForm = Form.create()(ConfirmPasswordForm);

const mapStateToProps = (state) =>{
    return {
        loading: state.loading,
        error: state.error
    }
};

const mapDispatchToProps = dispatch =>{
  return{
      onConfirmPassword: (pass1, pass2) => dispatch(actions.confirmPassword(pass1, pass2))
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedConfirmPasswordForm));
