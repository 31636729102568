/*eslint-disable */
import React, {Component} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import MainRouter from './Router';
import ScrollToTop from './ScrollToTop';
import * as actions from '../../redux/actions/auth';
import {connect} from 'react-redux';
import MyProvider from "../../store/Provider/userProvider";


class App extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            loaded: false,
        };
    }

    componentDidMount() {
        this.props.onTryAutoSignup();
        window.addEventListener('load', () => {
            this.setState({loading: false});
            setTimeout(() => this.setState({loaded: true}), 500);
        });
    }


    render() {
        const {loaded, loading} = this.state;

        let urlSave = window.location.pathname;
        let payUrl = urlSave.split('/');
        if(payUrl.length === 4 && payUrl[3] === "webpay"){
            localStorage.setItem('urlSave', urlSave);
        }

        return (

            <BrowserRouter>
                <ScrollToTop>
                    {!loaded
                    && (
                        <div className={`load${loading ? '' : ' loaded'}`}>
                            <div className="load__icon-wrap">
                                <svg className="load__icon">
                                    <path fill="#3879b1" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                                </svg>
                            </div>
                        </div>
                    )
                    }
                    <div>

                        <MyProvider {...this.props}>
                            <MainRouter/>
                        </MyProvider>

                    </div>
                </ScrollToTop>
            </BrowserRouter>

        );
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.token !== null,
        user: state.user,
        // selectedBanks: state.selectedBanks
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
