/*eslint-disable */
import axios from 'axios';
import React from 'react';
import { authFail } from "../../redux/actions/auth";

const UserService = {

    // GET METHODS
    getUser: function () {
        return axios.get('/api/users/')
    },

    getListUserOppMng: function () {
        return axios.get('/api/users/listUser')
    },

    deleteUser: function (id) {
        return axios.delete('/api/users/delUser/' + id)
    },

    addUser: function (data) {
        return axios.post('/api/users/addUser', {
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            customer: data.customer,
            type: data.type,
            pbi_email: data.pbi_email,
            pbi_password: data.pbi_password,
            allies: data.allies,
            report_pages: data.report_pages
        });
    },

    editUser: function (data, id) {
        return axios.put('/api/users/' + id + '/', {
            email: data.email,
            name: data.name,
            lastname: data.lastname,
            customer: data.customer,
            type: data.type,
            pbi_email: data.pbi_email,
            pbi_password: data.pbi_password,
            allies: data.allies,
            report_pages: data.report_pages
        });
    },

    m_addUser: function (data) {
        return axios.post('/api/users/addUser', {
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            customer: data.customer,
            type: data.type,
            allies: data.allies,
            report_pages: data.report_pages
        });
    },

    m_editUser: function (data, id) {
        return axios.put('/api/users/' + id + '/', {
            email: data.email,
            name: data.name,
            lastname: data.lastname,
            customer: data.customer,
            type: data.type,
            allies: data.allies,
            report_pages: data.report_pages
        });
    },

    activate_user: function (data, id) {
        return axios.put('/api/users/activateUser/' + id + '/', {
            name: data.name,
            email: data.email,
            block_access: data.block_access
        })
    }

};

export default UserService;