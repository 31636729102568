/*eslint-disable */
import React from 'react';
import {Button, Divider, Form, Input, Modal, Select} from "antd";
import UserService from "../../../services/user/UserService";
import notificationWithIcon from "../../UI/Notification/components/ColorStates";
import CustomerService from "../../../services/customer/CustomerService";
import { Icon, Tooltip } from 'antd';
const {Option} = Select;
import UserContext from '../../../store/userContext';
import AuthServices from '../../../services/auth/AuthService';
import ReportService from '../../../services/dashboard/ReportService';


class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            visible: true,
            handleEditModal: this.props.handleEditModal,
            reloadList: this.props.reloadList,
            load: false,
            myUser: [],
            menuP: [],
            selectValue: null,
            userAlly: [],
            userPage: [],
            selectedPages: [],
            idCust: null,
            userTypes: [
                {
                    value: 'A',
                    type: 'Super Admin'
                },
                {
                    value: 'F',
                    type: 'Atención al Cliente'
                },
                {
                    value: 'B',
                    type: 'Master'
                },
                {
                    value: 'C',
                    type: 'Estándar'
                },
                {
                    value: 'D',
                    type: 'Aliado'
                },
                {
                    value: 'E',
                    type: 'Ejecutivo de Ventas'
                }
            ],
            userMasterTypes: [
                {
                    value: 'B',
                    type: 'Master'
                },
                {
                    value: 'C',
                    type: 'Estándar'
                }
            ],
            userSupportTypes: [
                {
                    value: 'F',
                    type: 'Atención al Cliente'
                },
                {
                    value: 'B',
                    type: 'Master'
                },
                {
                    value: 'C',
                    type: 'Estándar'
                },
                {
                    value: 'D',
                    type: 'Aliado'
                },
                {
                    value: 'E',
                    type: 'Ejecutivo de Ventas'
                }
            ],
        };
    }

    componentDidMount() {
        CustomerService.getCustomers()
            .then(response => {
                this.setState({
                    customers: response.data,
                })
            })
            .catch(error => {
                this.setState({
                    customers: [],
                })
            });

        AuthServices.getUserFromSession()
            .then(response => {
                this.setState({
                    myUser: response.data
                })
            })
            .catch(error =>{
                this.setState({
                    myUser: []
                })
            });

        ReportService.allPages()
            .then(response => {
                this.setState({
                    menuP: response.data
                });
            })
            .catch(error => {
                console.error("Lista de reporte: ", error);
            });

        UserService.getUser()
            .then(response => {
                this.setState({
                    userAlly: response.data
                });
            })
            .catch(error => {
                this.setState({
                    userAlly: []
                });
                console.error("No aliado", error);
            });
    }

    handleSelectUserType = (userType) => {
        let renderOptions;

        if (userType === 'A') {
            renderOptions = this.state.userTypes.map(function (data, idx) {
                return <Option value={data.value}>{data.type}</Option>;

            });
        }
        else if (userType === 'B') {
            renderOptions = this.state.userMasterTypes.map((data, idx) => {
                return <Option value={data.value}>{data.type}</Option>;
            })
        } else if (userType === 'F') {
            renderOptions = this.state.userSupportTypes.map((data, idx) => {
                return <Option value={data.value}>{data.type}</Option>;
            })
        }
        return renderOptions
    };

    handleChangeType = e =>{
        this.setState({selectValue:e});
    };

    handleSelectSalesMan = (selectSaleMan) => {
        let renderOpts;

        if (selectSaleMan == 'E'){
            renderOpts = this.state.userAlly.filter(user => user.type === 'D' || user.type === 'A').map(function (data, idx) {
                return <Option value={data.id}>{data.name + " " + data.lastname}</Option>;
            });
        }

        return renderOpts;
    }

    filterLicenses = () => {
        const actMasterUsers = this.state.userAlly.filter(user => user.type === 'B').length;
        const actStandUsers = this.state.userAlly.filter(user => user.type == 'C').length;
        let masterUsers = this.state.customers.map(data => {
            return data.master_licenses;
        });
        let standUsers = this.state.customers.map(data => {
            return data.standard_licenses;
        });
        let master = masterUsers - actMasterUsers;
        let stand = standUsers - actStandUsers;

        return <div className='info__licenses'>Master <span>{actMasterUsers} / {masterUsers}</span>; Estándar <span>{actStandUsers} / {standUsers}</span></div>;
    }

    customerSelect = e => {
        this.setState({idCust:e});
    }

    filterLicensesAdmin = custId => {
        const licenses = this.state.customers.filter(cust => cust.id == custId);
        const actMasterUsers = this.state.userAlly.filter(user => user.type === 'B' && user.customer == custId).length;
        const actStandUsers = this.state.userAlly.filter(user => user.type == 'C' && user.customer == custId).length;
        let masterUsers = licenses.map(data => {
            return data.master_licenses;
        });
        let standUsers = licenses.map(data => {
            return data.standard_licenses;
        });
        
        let master = masterUsers - actMasterUsers;
        let stand = standUsers - actStandUsers;
        return <h6>Licencias: Master <span>{actMasterUsers} / {masterUsers}</span>; Estándar <span>{actStandUsers} / {standUsers}</span></h6>;
    }

    // handleChange = selectedPages => {
    //     console.log(selectedPages);
    //     if (selectedPages.length > 4) {
    //         let tem = selectedPages.slice(-1)[0];
    //         selectedPages.splice(3, selectedPages.length - 3, tem);
    //         this.setState({ selectedPages });
    //     }
    // }
    handleSelectedInfo = (selectType) => {
        switch (selectType) {
            case "A":
                return <div>Acceso a todos los módulos Comerciales y Administrativos</div>;
            case "B":
                return <div>Acceso a todos los módulos Comerciales, Modulo de Usuarios y Consulta de Pagos</div>;
            case "C":
                return <div>Acceso a 4 módulos comerciales y 2 módulos informativos (Indicadores e Información)</div>;
            case "D":
                return <div>Acceso a todos los módulos Comerciales y en los módulos Administrativos tiene acceso a sus registros</div>;
            case "E":
                return <div>Acceso a todos los Módulos Comerciales y en los Módulos Administrativos a sus Prospectos y Clientes</div>;
            case "F":
                return <div>Acceso a todos los módulos Comerciales y Administrativos  (No puede borrar)</div>;
            default:
                return <div>Seleccione primero el tipo de usuario</div>
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    load: true,
                    disabled: true
                });
                if(this.state.myUser.type === 'B'){
                    UserService.m_editUser(values, this.props.user.id)
                        .then(response => {
                            if (response.status === 200) {
                                notificationWithIcon('success', 'Usuario Editado', 'Se editó el usuario correctamente');
                                this.props.form.resetFields();
                                this.state.handleEditModal(false);
                                this.setState({selectValue: null, idCust: null});
                                this.state.reloadList();
                            }
                        })
                        .catch(error => {
                            if(error.response.status === 400){
                                this.setState({
                                    load: false,
                                    disabled: false
                                });
                                console.log(error.response);
                                let msg = error.response.data;
                                if(msg){
                                    if(msg.email !== undefined){
                                        notificationWithIcon('error', 'Error', 'Este usuario ya existe, verifique el email ingresado')
                                    } else {
                                        notificationWithIcon('error', 'Error', msg);
                                    }
                                }
                            } else {
                                this.setState({
                                    load: false,
                                    disabled: false
                                });
                                notificationWithIcon('error', 'Error', 'Hubo un error al editar el usuario');
                            }
                        })
                } else {
                    UserService.editUser(values, this.props.user.id)
                        .then(response => {
                            if (response.status === 200) {
                                notificationWithIcon('success', 'Usuario Editado', 'Se editó el usuario correctamente');
                                this.props.form.resetFields();
                                this.state.handleEditModal(false);
                                this.setState({selectValue: null, idCust: null});
                                this.state.reloadList();
                            }
                        })
                        .catch(error => {
                            if(error.response.status === 400){
                                this.setState({
                                    load: false,
                                    disabled: false
                                });
                                console.log(error.response);
                                let msg = error.response.data;
                                if(msg){
                                    if(msg.email !== undefined){
                                        notificationWithIcon('error', 'Error', 'Este usuario ya existe, verifique el email ingresado')
                                    } else {
                                        notificationWithIcon('error', 'Error', msg);
                                    }
                                }
                            } else {
                                this.setState({
                                    load: false,
                                    disabled: false
                                });
                                notificationWithIcon('error', 'Error', 'Hubo un error al editar el usuario');
                            }
                        })
                }
            }
        });
        setTimeout(() => this.setState({ load: false, disabled: false }), 3000);
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const {load, disabled, selectedPages, menuP, idCust} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const renObjData = this.state.customers.filter(d => d.is_active).map(function (data, idx) {
            return <Option value={data.id}>{data.customer}</Option>;
        });

        const selectedRol = this.state.selectValue == null ? this.props.user.type : this.state.selectValue;

        const passTrim = (e) => {
            let str= e.target.value;
            e.target.value = str.replace(/ /g, "");
        }

        return (
            <UserContext.Consumer>
                {(user) =>(
                    user.type === 'A' || user.type === 'F' ?
                    <Modal footer={null} title={<div><Icon type="edit" style={{ fontSize: '35px', marginRight:'20px', color:'#70bbfd'}}/>Editar Usuario</div>}
                        visible={this.props.visible} onOk={() => this.handleOk(this.props.user)}
                        onCancel={() => { this.state.handleEditModal(false); this.setState({selectValue: null, idCust: null}) }} okText="Guardar" cancelText="Cancelar"
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Nombre">
                                {getFieldDecorator('name', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.name,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un nombre',
                                        },
                                    ],
                                    values: this.props.user.name
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Apellido">
                                {getFieldDecorator('lastname', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.lastname,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un apellido',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Correo">
                                {getFieldDecorator('email', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.email,
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Ingrese un correo valido',
                                        },
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un correo',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Correo de PBI" hasFeedback validateStatus="warning">
                                {getFieldDecorator('pbi_email', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.pbi_email ? this.props.user.pbi_email : "",
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Ingrese un correo válido',
                                        }
                                    ],
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Contraseña de PBI" className="form" hasFeedback validateStatus="warning">
                                {getFieldDecorator('pbi_password', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.pbi_password ? this.props.user.pbi_password : "",
                                    
                                })(<Input.Password onChange={passTrim} />)}                        
                            </Form.Item>

                            <Form.Item label="Empresa">
                                {getFieldDecorator('customer', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, seleccione una empresa',
                                        },
                                    ],
                                    initialValue: this.props.user.customer ? this.props.user.customer : [],
                                })(<Select
                                    showSearch onChange={this.customerSelect}
                                    optionFilterProp="children"
                                    placeholder={"Seleccione una empresa"}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {renObjData}
                                </Select>)}
                                {this.filterLicensesAdmin(idCust != null ? idCust : this.props.user.customer)}
                            </Form.Item>

                            <Form.Item label="Tipo de Usuario" className="form">
                                <div className="form__form-group-field">
                                {getFieldDecorator('type', {
                                    validateTrigger: ["onChange","onBlur"],
                                    initialValue: this.props.user.type ? this.props.user.type : [],
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, seleccione un tipo de usuario',
                                        },
                                    ],
                                })(<Select onChange={this.handleChangeType}
                                            placeholder={"Seleccione el tipo de Usuario"}>
                                            {this.handleSelectUserType(user.type)}
                                    </Select>
                                    )}
                                    <Tooltip placement="top" title={this.handleSelectedInfo(this.state.selectValue ? this.state.selectValue : this.props.user.type)}>
                                            <Icon style={{fontSize: "20px", margin: "6px"}} type={"info-circle"}/>
                                    </Tooltip>
                                </div>
                            </Form.Item>

                            {selectedRol == 'C' ?
                                <Form.Item label="Módulos:" className='form'>
                                    {getFieldDecorator('report_pages', {
                                        validateTrigger: ["onChange", "onBlur"],
                                        initialValue: this.props.report_pages ? this.props.report_pages : [],
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor, seleccione al menos un módulo',
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value) {
                                                        if (value.length > 4) {
                                                            callback("Seleccionar hasta 4 módulos");
                                                        } else if (value.length <= 4) {
                                                            callback();
                                                        }
                                                    }
                                                return;
                                                }
                                            }
                                        ],
                                    })(<Select
                                        mode="multiple"
                                        value={selectedPages}
                                        // onChange={this.handleChange}
                                        showArrow placeholder={"Seleccione módulos"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {menuP.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </Form.Item> : ''
                            }

                            {selectedRol == 'E' ?
                                <Form.Item label="Supervisor Aliado:" className="form">
                                    <div className="form__form-group-field">
                                    {getFieldDecorator('allies', {
                                        validateTrigger: ["onChange", "onBlur"],
                                        initialValue: this.props.user.allies ? this.props.user.allies : [],
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor, seleccione el aliado',
                                            }],
                                    })(<Select
                                            placeholder={"Seleccione el aliado"}>
                                                {this.handleSelectSalesMan(this.state.selectValue ? this.state.selectValue : this.props.user.type)}
                                        </Select>
                                        )}
                                    </div>
                                </Form.Item> : ''}

                            <Form.Item style={{marginLeft: '30%'}}>
                                <Button type="secondary" onClick={() => {this.state.handleEditModal(false); this.props.form.resetFields(); this.setState({selectValue: null, idCust: null})}}>
                                    Cancelar
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={"true"} loading={load} disabled={disabled} htmlType="submit"
                                        style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                    Modificar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    :
                    user.type === 'B' ?
                    <Modal footer={null} title={<div><Icon type="edit" style={{ fontSize: '35px', marginRight:'20px', color:'#70bbfd'}}/>Editar Usuario</div>}
                            visible={this.props.visible} onOk={() => this.handleOk(this.props.user)}
                            onCancel={() => { this.state.handleEditModal(false); this.setState({selectValue: null}) }} okText="Guardar" cancelText="Cancelar"
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Licencias">{this.filterLicenses()}</Form.Item>

                            <Form.Item label="Nombre">
                                {getFieldDecorator('name', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.name,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un nombre',
                                        },
                                    ],
                                    values: this.props.user.name
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Apellido">
                                {getFieldDecorator('lastname', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.lastname,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un apellido',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Correo">
                                {getFieldDecorator('email', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.email
                                })(<Input disabled={true} />)}
                            </Form.Item>

                            <Form.Item label="Tipo de Usuario" className="form">
                                <div className="form__form-group-field">
                                {getFieldDecorator('type', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.type ? this.props.user.type : [],
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, seleccione un tipo de usuario',
                                        },
                                    ],
                                })(<Select onChange={this.handleChangeType} 
                                           placeholder={"Seleccione el tipo de Usuario"}
                                           disabled={user.id == this.props.user.id ? true : false}>
                                        {this.handleSelectUserType('B')}
                                    </Select>)}
                                    <Tooltip placement="top" title={this.handleSelectedInfo(this.state.selectValue ? this.state.selectValue : this.props.user.type)}>
                                        <Icon style={{fontSize: "20px", margin: "6px"}} type={"info-circle"}/>
                                    </Tooltip>
                                </div>
                            </Form.Item>

                            {selectedRol == 'C' ?
                                <Form.Item label="Módulos:" className='form'>
                                    {getFieldDecorator('report_pages', {
                                        validateTrigger: ["onChange", "onBlur"],
                                        initialValue: this.props.report_pages ? this.props.report_pages : [],
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor, seleccione al menos un módulo',
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value) {
                                                        if (value.length > 4) {
                                                            callback("Sólo puede escoger hasta 4 módulos");
                                                        } else if (value.length <= 4) {
                                                            callback();
                                                        }
                                                    }
                                                return;
                                                }
                                            }
                                        ],
                                    })(<Select
                                        mode="multiple"
                                        value={selectedPages}
                                        // onChange={this.handleChange}
                                        disabled={user.id == this.props.user.id ? true : false}
                                        showArrow placeholder={"Seleccione módulos"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {menuP.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>)}
                                </Form.Item> : ''
                            }

                            {getFieldDecorator('customer', {
                                validateTrigger: ["onChange", "onBlur"],
                                initialValue: this.props.user.customer,
                            })(<Input hidden={true}  value={this.props.user.customer} /> )}

                            <Form.Item style={{marginLeft: '30%'}}>
                                <Button type="secondary" onClick={() => {this.state.handleEditModal(false); this.props.form.resetFields(); this.setState({selectValue: null})}}>
                                    Cancelar
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={"true"} loading={load} disabled={disabled} htmlType="submit"
                                        style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                    Modificar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                :
                <Modal footer={null} title={<div><Icon type="edit" style={{ fontSize: '35px', marginRight:'20px', color:'#70bbfd'}}/>Editar Usuario</div>}
                            visible={this.props.visible} onOk={() => this.handleOk(this.props.user)}
                            onCancel={() => this.state.handleEditModal(false)} okText="Guardar" cancelText="Cancelar"
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Nombre">
                                {getFieldDecorator('name', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.name,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un nombre',
                                        },
                                    ],
                                    values: this.props.user.name
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Apellido">
                                {getFieldDecorator('lastname', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.lastname,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese un apellido',
                                        },
                                    ],
                                })(<Input/>)}
                            </Form.Item>

                            <Form.Item label="Correo">
                                {getFieldDecorator('email', {
                                    validateTrigger: ["onChange", "onBlur"],
                                    initialValue: this.props.user.email
                                })(<Input disabled={true} />)}
                            </Form.Item>
        
                            {getFieldDecorator('type', {
                                initialValue: this.props.user.type,
                            })(<Input hidden={true} value={this.props.user.type} />)}

                            {getFieldDecorator('allies', {
                                initialValue: this.props.user.allies,
                            })(<Input hidden={true} value={this.props.user.allies} /> )}

                            {getFieldDecorator('customer', {
                                initialValue: this.props.user.customer,
                            })(<Input hidden={true}  value={this.props.user.customer} /> )}

                            <Form.Item style={{marginLeft: '30%'}}>
                                <Button type="secondary" onClick={() => {this.state.handleEditModal(false); this.props.form.resetFields();}}>
                                    Cancelar
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={"true"} loading={load} disabled={disabled} htmlType="submit"
                                        style={{width: '100px', background: "#e5a328", borderColor: "#e5a328", color: "#fff"}}>
                                    Modificar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
            </UserContext.Consumer>
        )
    }
}

const WrappedEditUserForm = Form.create()(EditUser);

export default WrappedEditUserForm;
