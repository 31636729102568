/*eslint-disable */
import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import UserContext from '../../../store/userContext';


const Sidebar = ({
  changeMobileSidebarVisibility, sidebarShow, sidebarCollapse, user
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebarShow,
    'sidebar--collapse': sidebarCollapse,
  });


  return (
    <UserContext.Consumer>
      {(user) => (
        user.type === 'B' && user.block_access === true ?
        ''
        :
        user.type === 'A' || user.type === 'B' || user.type === 'D' || user.type === 'E' || user.type === 'F' ?
          <div className={sidebarClass}>
            <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
            <Scrollbar className="sidebar__scroll scroll">
              <div className="sidebar__wrapper sidebar__wrapper--desktop">
                <SidebarContent
                    onClick={() => {}}
                    user = {user}
                />
                {

                }
              </div>
              <div className="sidebar__wrapper sidebar__wrapper--mobile">
                <SidebarContent
                  onClick={changeMobileSidebarVisibility}
                />
              </div>
            </Scrollbar>
          </div>
        :
          ''
      )}
    </UserContext.Consumer>
  );
};

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  sidebarCollapse: PropTypes.bool.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
