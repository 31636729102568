/*eslint-disable */
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import history from '../../redux/history';
import PrivateRoute from '../components/PrivateRoute';
import ResetPassword from '../LogIn/resetpass';
import ConfirmPassword from '../LogIn/confirmpass';
import ListClients from '../Clients/index';
import UserPage from '../Users/index';
import {Dashboard} from '../Dashboard';
import UserContext from "../../store/userContext";
import Footer from "../components/Footer";
import ListProspects from '../Prospects/index';
import WebpayPage from '../WebPay/index';
import Mail from '../Mail/index';
import { Container } from 'reactstrap';
import logo from "../../shared/img/logo/logo_gbitcorp_ar.png";
import Register from '../LogIn/register';


const Pages = () => (
  <Switch>
      <UserContext.Consumer>
            {(user) =>(
                <div>
                    {
                        user.type === 'A' || user.type === 'F' ?
                            <div>
                                <Route path="/dashboard/pages/clients" component={ListClients} />
                                <Route path="/dashboard/pages/prospects" component={ListProspects} />
                                <Route path="/dashboard/pages/users" component={UserPage} />
                                <Route path="/dashboard/pages/notification" component={Mail} />
                            </div>
                            :
                             user.type === 'B' ?
                                <div>
                                    <Route path="/dashboard/pages/users" component={UserPage} />
                                    <Route path="/dashboard/pages/webpay" component={WebpayPage} />
                                    <Route path="/dashboard/pages/notification" component={Mail} />
                                </div> :
                                user.type === 'C' ?
                                    <div>
                                        <Route path="/dashboard/pages/notification" component={Mail} />
                                    </div>
                                    :
                                    user.type === 'D' ?
                                        <div>
                                            <Route path="/dashboard/pages/clients" component={ListClients} />
                                            <Route path="/dashboard/pages/prospects" component={ListProspects} />
                                            <Route path="/dashboard/pages/notification" component={Mail} />
                                            <Route path="/dashboard/pages/users" component={UserPage} />
                                        </div> 
                                    :
                                    user.type === 'E' ?   
                                        <div>
                                            <Route path="/dashboard/pages/clients" component={ListClients} />
                                            <Route path="/dashboard/pages/prospects" component={ListProspects} />
                                            <Route path="/dashboard/pages/notification" component={Mail} />
                                        </div>
                                    :
                                    <div></div>
                    }

                </div>
            )}</UserContext.Consumer>
  </Switch>
);

const wrappedRoutes = () => (
    <UserContext.Consumer>
        {(user) =>(
            <div>
                <Layout />
                {
                    user.type === 'B' && user.block_access ?
                        <div className="container__wrap" style={{paddingLeft: "0"}}>
                            <Route path="/dashboard/pages" component={Pages} />
                            <Route exact path="/dashboard/pages/dashboards" component={Dashboard} />
                            <Container>
                                <div>
                                    <hr/>
                                    <div className="row">
                                        <div className="landing__footer-text text-center col-lg-12 col-md-12">
                                            Powered by&nbsp;
                                            <a href="https://www.gbitcorp.com/" target="_blank">
                                                <img src={logo} alt="logo" style={{ width: '125px', height:'30px' }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    :
                    user.type === 'A' || user.type === 'B' || user.type === 'D' || user.type === 'E' || user.type === 'F' ?
                        <div className="container__wrap">
                            <Route path="/dashboard/pages" component={Pages} />
                            <Route exact path="/dashboard/pages/dashboards" component={Dashboard} />
                            <Footer/>
                        </div>
                    :
                        <div className="container__wrap" style={{paddingLeft: "0"}}>
                            <Route path="/dashboard/pages" component={Pages} />
                            <Route exact path="/dashboard/pages/dashboards" component={Dashboard} />
                            <Footer/>
                        </div>
                }
            </div>
        )}
    </UserContext.Consumer>
);

const MainRouter = () => (

    <MainWrapper>
        <main>
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={LogIn} />
                    <Route path="/register" component={Register} />
                    <Route exact path="/password/reset" component={ResetPassword} />
                    <Route path="/password/reset/confirm" component={ConfirmPassword} />
                    <PrivateRoute path="/dashboard" component={wrappedRoutes} />
                </Switch>
            </Router>
        </main>
    </MainWrapper>

);

export default MainRouter;
