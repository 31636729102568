/*eslint-disable */
import React from 'react';
import UserService from '../../../services/user/UserService';
import {Button, Modal} from "antd";
import { Icon } from 'antd';
import UserContext from "../../../store/userContext";


class InfoClient extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            visible: true,
            handleInfoModal: this.props.handleInfoModal,
            reloadList: this.props.reloadList,
            load: false,
            serviceTypes: [
            {
                value: 'N',
                type: 'Ninguno'
            },
            {
                value: 'L',
                type: 'Licencias'
            },
            {
                value: 'R',
                type: 'Reportería Mensual'
            },
            ],
            payFreqTypes: [
            {
                value: 'NN',
                type: 'Ninguno'
            },
            {
                value: 'MY',
                type: 'Mensual 0%'
            },
            {
                value: 'QY',
                type: 'Trimestral 10%'
            },
            {
                value: 'BA',
                type: 'Semestral 20%'
            },
            {
                value: 'AN',
                type: 'Anual 30%'
            }
            ],
            userName: []
        };
    }

    componentDidMount() {
        UserService.getUser()
            .then(response =>{
                this.setState({
                    userName: response.data,
                })
            })
            .catch( error =>{
                this.setState({
                    userName: [],
                })
            });
    }

    handleServiceType = (id) => {
        let renderService;
    
        renderService = this.state.serviceTypes.map(function (data, idx) {
            if (id == data.value) {
                return data.type;
            }
        });

        return renderService;
    };

    handleFrequencyPayType = (id) => {
        let renderFrequency;

        renderFrequency = this.state.payFreqTypes.map((data, idy) => {
            if (id == data.value) {
                return data.type;
            }
        });

        return renderFrequency;
    };

    handleAliado = (id) => {
        let renderAliados;

        renderAliados = this.state.userName.map(function (data, idx) {
            if (id == data.id) {
                return data.name + " " + data.lastname;
            }
        });

        return renderAliados;
    };


    render() {

        return (
            <UserContext.Consumer>
                {(user) =>(
                <Modal
                    title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Información de Cliente</div>}
                    visible={this.props.visible}
                    width={'450px'}
                    onCancel={() => {this.state.handleInfoModal(false)}}
                    footer={[<Button type="secondary" onClick={() => {this.state.handleInfoModal(false)}}>
                                Cerrar
                            </Button>]}
                >
                    <h4><p>Empresa: <b>{this.props.customer.customer}</b></p></h4>
                    <p>Nombre: <b>{this.props.customer.name}</b></p>
                    <p>Apellido: <b>{this.props.customer.lastname}</b></p>
                    <p>RUT: <b>{this.props.customer.ruc}</b></p>
                    <p>Correo: <b>{this.props.customer.email}</b></p>
                    <p>Teléfono: <b>{this.props.customer.phone}</b></p>
                    <p>Móvil: <b>{this.props.customer.mobile}</b></p>
                    <p>Dirección: <b>{this.props.customer.address}</b></p>
                    <p>Licencias Master: <b>{this.props.customer.master_licenses}</b></p>
                    <p>Licencias Estándar: <b>{this.props.customer.standard_licenses}</b></p>
                    <p>Tipo de Servicio: <b>{this.handleServiceType(this.props.customer.service_type)}</b></p>
                    <p>Frecuencia de Pago: <b>{this.handleFrequencyPayType(this.props.customer.payment_freq)}</b></p>
                    {user.type == 'A' || user.type == 'D' || user.type == 'F' ?
                    <p>Aliado: <b>{this.handleAliado(this.props.customer.allies)}</b></p> : ""}
                </Modal>
            )}
            </UserContext.Consumer>
        )
    }
}

export default InfoClient;
