/*eslint-disable */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import Sidebar from "./Sidebar";
import UserContext from '../../../store/userContext';


class SidebarContent extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    hideSidebar = () => {
        const {onClick} = this.props;
        onClick();
    };

    constructor(props) {
        super(props);
        this.state = {
            collapse: true
        };
    }


    render() {

        return (
            <UserContext.Consumer>
                {(user) => (
                    user.type === 'B' && user.block_access === true ? 
                    ''
                    :
                    user.type === 'A' || user.type === 'D' || user.type === 'F'?
                        <div className="sidebar__content">
                            <ul className="sidebar__block">
                                <SidebarLink title="Inicio" icon="home"
                                             route="/dashboard/pages/dashboards"
                                             onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Prospectos" icon="book"
                                            route="/dashboard/pages/prospects"
                                            onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Clientes" icon="users"
                                             route="/dashboard/pages/clients"
                                             onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Usuarios" icon="user"
                                             route="/dashboard/pages/users"
                                             onClick={this.hideSidebar}
                                />
                            </ul>
                        </div>
                        :
                        user.type === 'B' ?
                        <div className="sidebar__content">
                            <ul className="sidebar__block">
                                <SidebarLink title="Inicio" icon="home"
                                            route="/dashboard/pages/dashboards"
                                            onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Usuarios" icon="user"
                                            route="/dashboard/pages/users"
                                            onClick={this.hideSidebar}
                                />
                                 <SidebarLink title="Consulta de Saldo" icon="cart"
                                             route="/dashboard/pages/webpay"
                                             onClick={this.hideSidebar}
                                />
                            </ul>
                        </div>
                        :
                        user.type === 'E' ?
                        <div className="sidebar__content">
                            <ul className="sidebar__block">
                                <SidebarLink title="Inicio" icon="home"
                                             route="/dashboard/pages/dashboards"
                                             onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Prospectos" icon="book"
                                            route="/dashboard/pages/prospects"
                                            onClick={this.hideSidebar}
                                />
                                <SidebarLink title="Clientes" icon="users"
                                             route="/dashboard/pages/clients"
                                             onClick={this.hideSidebar}
                                />
                            </ul>
                        </div>
                        :
                            ''
                )}
            </UserContext.Consumer>
        );
    }
}

export default SidebarContent;
