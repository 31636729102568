/*eslint-disable */
import axios from 'axios';
import React from 'react';
import {authFail} from "../../redux/actions/auth";

const CustomerService = {

    // GET METHODS
    getCustomers: function () {
        return axios.get('/api/customerscl/')
    },

    deleteCustomer: function (id) {
          return axios.delete('/api/customerscl/'+id)
    },

    addCustomer: function (data) {
          return axios.post('/api/customerscl/', {
              customer:data.customer,
              name: data.name,
              lastname: data.lastname,
              ruc:data.ruc,
              address:data.address,
              phone:data.phone,
              mobile: data.mobile,
              email:data.email,
              service_type: data.service_type,
              master_licenses: data.master_licenses,
              standard_licenses: data.standard_licenses,
              payment_freq: data.payment_freq,
              allies: data.allies

          });
    },

    /*editCustomer: function (data, id) {
          return axios.put('/api/customerscl/'+id+'/' , {
              customer:data.customer,
              ruc:data.ruc,
              address:data.address,
              phone:data.phone,
              email: data.email,
          });
    },*/

    blockAccessClient: function (data, id) {
        return axios.put('/api/customerscl/blockAccessCustomer/'+id+'/', {
            is_blocked: data.is_blocked
        });
    },

    activateClient: function (data, id) {
        return axios.put('/api/customerscl/activateCustomer/' + id + '/', {
            is_active: data.is_active
        })
    }
};

export default CustomerService;