/*eslint-disable */
import {Table, Input, Button, Icon, Modal} from 'antd';
import React from 'react';
import ProspectsService from '../../../services/prospects/ProspectsService';
const { Column } = Table;
import {Spinner, spinnerService} from "@chevtek/react-spinners";
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import 'moment/locale/es';


class ConsultProspects extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            visible: true,
            handleConsultModal: this.props.handleConsultModal,
            reloadList: this.props.reloadList,
            load: false
        };
    }

    handleListReload = () => {
        this.componentDidMount();
    };

    componentDidMount() {
        spinnerService.show('mySpinner');
        ProspectsService.allProspects()
          .then(response =>{
              spinnerService.hide('mySpinner');
              this.setState({
                  data: response.data,
              })
          })
          .catch( error =>{
              spinnerService.hide('mySpinner');
              this.setState({
                  data: [],
              })
          });
    }

  getColumnSearchProps = (dataIndex, title) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar por ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, marginRight: 8 }}>
          Limpiar
        </Button>

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>

      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? 'red' : '#1890ff' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} de ${total}`
  };

  convertDates = (date) => {
    let dateRend;
    dateRend = moment(date).format('DD/MM/YYYY');

    return dateRend;
  };


  render() {

      return(
        <Modal
          footer={null}
          width={'900px'}
          title={<div><Icon type="search" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} />Prospectos y Clientes Existentes</div>}
          visible={this.props.visible}
          onCancel={() => {this.state.handleConsultModal(false)}}
          cancelText="Cerrar"
        >
          <Spinner name="mySpinner"><div className="panel__refresh"><LoadingIcon /></div></Spinner>

          <Table dataSource={this.state.data} size={"small"} pagination={{defaultPageSize: 5,showTotal: this.showTotal}}>
            <Column title="Tipo" dataIndex="list" key="list" />
            <Column {...this.getColumnSearchProps('name', 'Nombre')} title="Nombre" dataIndex="name" key="name" />
            <Column {...this.getColumnSearchProps('lastname', 'Apellido')} title="Apellido" dataIndex="lastname" key="lastname" />
            <Column {...this.getColumnSearchProps('business_name', 'Empresa')} title="Empresa" dataIndex="business_name" key="business_name"/>
            <Column {...this.getColumnSearchProps('ruc', 'RUT')} title="RUT" dataIndex="ruc" key="ruc" />
            <Column title="Fecha de Creación" dataIndex="created_at" key="created_at" render={(text, record) => (<span>{this.convertDates(record.created_at)}</span>)} />
          </Table>
        </Modal>
      );
  }
}

export default ConsultProspects;
