/*eslint-disable */
import React from 'react';
import {Button, Modal, Table, Icon} from "antd";
const { Column } = Table;
import LoadingIcon from "mdi-react/LoadingIcon";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import '../../../scss/detailNotifications.css';


class InfoLicitacion extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            licDetail: [],
            codeDetail: null,
            visible: true,
            handleInfoLicModal: this.props.handleInfoLicModal,
            reloadList: this.props.reloadList,
            load: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.codeDetail !== prevProps.code) {
            this.setState({
                licDetail: this.props.detail,
                codeDetail: this.props.code
            });
        }
    }

    detailComprador = () => {
        let comprador;

        comprador = this.state.licDetail.map(data1 => {
            return data1.detail.map(data2 => {
                return (
                    <div style={{padding: "0 12px", fontSize: "12px"}}>
                        <Row>
                            <Col lg={6}>
                                <p><b>Comprador:</b> {data2.comprador}</p>
                                <p><b>Region:</b> {data2.region}</p>
                                <p><b>Fecha de Publicación:</b> {moment(data2.fechapublicacion).format("DD-MM-YYYY H:mm")}</p>
                                <p><b>Fecha de cierre:</b> {moment(data2.fechacierre).format("DD-MM-YYYY H:mm")}</p>
                                <p><b>Fecha de Adjudicación (estimada):</b> {moment(data2.fechaadjudicacion).format("DD-MM-YYYY")}</p>
                                <p><b>Tipo Licitación:</b> {data2.tipolic}</p>
                            </Col>
                            <Col lg={6}>
                                <p><b>Responsable del Contrato:</b> {data2.nombreresponsablecontrato ? data2.nombreresponsablecontrato.toUpperCase() : "No hay información"}</p>
                                <p><b>Teléfono:</b> {data2.fonoresponsablecontrato ? data2.fonoresponsablecontrato : "No hay información"}</p>
                                <p><b>Correo:</b> {data2.emailresponsablecontrato ? data2.emailresponsablecontrato : "No hay información"}</p><br/>
                                <p><b>Responsable de Pago:</b> {data2.nombreresponsablepago ? data2.nombreresponsablepago.toUpperCase() : "No hay información"}</p>
                                <p><b>Correo:</b> {data2.emailresponsablepago ? data2.emailresponsablepago : "No hay información"}</p>
                                <p><b>Fuente de Financiamiento:</b> {data2.fuentefinanciamiento}</p>
                                <p><b>Monto Estimado:</b> {data2.montoestimado ? data2.montoestimado.toLocaleString("es-CL") : '0.00'} {data2.moneda}</p>
                                <p><b>Justificación Monto Estimado:</b> {data2.justificacionmontoestimado}</p>
                                <p><b>Duración del Contrato:</b> {data2.duracioncontrato ? data2.duracioncontrato : "No hay información"}</p>
                            </Col>
                        </Row>
                    </div>				
                )
            });
        });

        return comprador;

    }

    detailItems = () => {
        let items;

        items = this.state.licDetail.map(data1 => {
            return (<Table dataSource={data1.items} scroll={{ y: 165 }} rowClassName="fontSize__table" size="small" 
                           pagination={false} footer={() => `${data1.items.length} items en total`}
                           title={() => 'Items Solicitados'}>
                        <Column title="Producto" dataIndex="producto" key="producto" />
                        <Column title="Descripcion" dataIndex="descripcion" key="descripcion" width="40%" />
                        <Column title="Cantidad" dataIndex="cantidad" key="cantidad" width="10%" />
                        <Column title="Medida" dataIndex="unidadmedida" key="unidadmedida" width="10%" />
                        <Column title="Organismo Comprador" dataIndex="nombreorganismo" key="nombreorganismo" />
                        <Column title="URL" dataIndex="url" key="url" width="5%" render={(text, record) => (
                            <a href={record.url} target="_blank">
                                <Icon type="link" style={{ fontSize: '18px', color:'#3879b1'}} />
                            </a>
                        )} />
                    </Table>)
        });

        return items;

    }

    showTotal = (total, range) => {
        return `${total} items`
    }


    render() {

        return (
            <Modal
                title={<div><Icon type="eye" style={{ fontSize: '35px', marginRight:'20px', color:'#3879b1'}} /> Detalle de Licitación Nº {this.props.code}</div>}
                visible={this.props.visible}
                width={'1200px'}
                onCancel={() => {this.state.handleInfoLicModal(false)}}
                footer={[<Button type="secondary" onClick={() => {this.state.handleInfoLicModal(false)}}>
                            Cerrar
                        </Button>]}
            >
                {this.state.licDetail.length == 0 || this.props.code !== this.state.codeDetail ? 
                    <div style={{textAlign: "center", margin: "10px 30px", paddingTop: "70px"}}>
                        <div style={{align: "center"}} className="panel__refresh"><LoadingIcon /></div>
                        Cargando información de licitación {this.props.code} ...
                    </div>
                : 
                <span>{this.detailComprador()}
                <br />
                <Row>
                    {this.detailItems()}
                </Row>
                </span>
                }
            </Modal>
        )
    }
}

export default InfoLicitacion;
